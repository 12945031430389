import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { ICategory, ICategoryResponse,  IProduct } from 'src/app/interfaces/zrpl';
import { ZrplService } from 'src/app/shared/services/zrpl/zrpl.service';

@Component({
  selector: 'app-electronics',
  templateUrl: './electronics.component.html',
  styleUrls: ['./electronics.component.scss']
})
export class ElectronicsComponent implements OnInit {

  public isAuthenticated: boolean = this.authService.isUserAuthenticated();
  public searchInput: string = '';
  public searching: boolean = false;
  private subject: Subject<string> = new Subject();
  public categories: Array<ICategory> = [];
  public showCheckoutOffer: boolean = false;
  public loadingCheckoutOffer: boolean = false; //Need to use this for loading checkout offer & Need to get the design improved to have a placeholder
  public isCategoriesLoading: boolean = true;
  public itemList: Array<IProduct> = [];
  public showMore: boolean = false;
  public categoryBasedProducts: Array<{category: ICategory, products: Array<IProduct>, promise: Subscription, isLoading: boolean, setOfCards: Array<number>} | undefined>; 

  @ViewChild('categoryList') public categoryList; 

  constructor(
    public globalConstants: GlobalConstants,
    private authService: AuthenticationService,
    private zrplService: ZrplService,
    private changeDetector: ChangeDetectorRef,
    private router: Router
  ) {
    this.isAuthenticated = this.authService.isUserAuthenticated();
    this.authService.isUserLoggedIn.subscribe((isLoggedIn: boolean) => {
      this.isAuthenticated = isLoggedIn;
    });
    this.subject.pipe(
      debounceTime(500)
    ).subscribe(inp => {
      this.searchItem();
    });
  }

  private mapCategoriesWithProducts() {
    if(this.categories.length > 0) {
      this.categoryBasedProducts = this.categories.map((category: ICategory) => {
        let tempCategoryBasedProducts = {
          category: category,
          promise: this.getProductsByCategory(category).subscribe((response: any) => {
            tempCategoryBasedProducts.products = response?.data;
            tempCategoryBasedProducts.isLoading = false;
            tempCategoryBasedProducts.setOfCards = Array(Math.ceil(response?.data?.length / 4)).fill(0).map((x, i) => i);
            this.changeDetector.detectChanges();
          }),
          products: [],
          setOfCards: [],
          isLoading: true,
        }
        return tempCategoryBasedProducts;
      });
    }
  }

  private getCategories() {
    if(this.zrplService.featuredCategories.length > 0) {
      this.categories = this.zrplService.featuredCategories?.slice(0,20);
      this.isCategoriesLoading = false;
      this.mapCategoriesWithProducts();
    } else {
      this.zrplService.getCategories().subscribe({
        next: (response: ICategoryResponse) => {
          if(response?.data?.attributes?.children_data && Array.isArray(response?.data?.attributes?.children_data)) {
            this.categories = this.zrplService.sortByFeaturedCategories(response?.data?.attributes?.children_data)?.slice(0,20);
          }
          this.isCategoriesLoading = false;
          this.mapCategoriesWithProducts();
        },
        error: (error: any) => {
          this.isCategoriesLoading = false;
          //Need to handle failure case
        }
      })
    }
  }

  private getProductsByCategory(selectedCategory: ICategory = null) {
    return this.zrplService.getProductsByCategory(selectedCategory?.id);
  }

  public getCategoryListheight() {
    return this.categoryList?.nativeElement.scrollHeight+'px'
  }
  
  private doPromoCodesExists() {
    this.zrplService.getPromoCodes().subscribe({
      next: (response: any) => {
        if(Array.isArray(response?.promo_codes) && response?.promo_codes?.length > 0) {
          this.showCheckoutOffer = true;
        } else {
          this.showCheckoutOffer = false;
        }
      },
      error: () => {
        this.showCheckoutOffer = false;
      }
    })
  }

  ngOnInit(): void {
    this.doPromoCodesExists();
    this.getCategories();
  }

  public preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  public clearSearchText() {
    this.searchInput = "";
    this.itemList = [];
    this.searching = false;
  }

  public debouncedProductSearch(inp: any) {
    this.subject.next(inp);
  }

  private searchItem() {
    this.searching = true;
    this.zrplService.searchProducts(this.searchInput).subscribe({
      next: (response: any) => {
        this.itemList = response?.data?.length ? response?.data?.map((product: any) => this.zrplService.setProductDetails(product)) : [];
        this.searching = false;
      },
      error: () => {
        this.searching = false;
      }
    })
  }

  public onSearchItemClick(product) {
    debugger  
    this.router.navigate(['/electronics/details/'+product?.sku]);
  }

}
