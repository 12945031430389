import { Component, Input, OnInit } from '@angular/core';
import { ICategory } from 'src/app/interfaces/zrpl';
import { ZrplService } from 'src/app/shared/services/zrpl/zrpl.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  @Input() public category: ICategory;

  constructor(
    private zrplService: ZrplService
  ) { }

  public getCategoryImage(category_name: string) {
    return this.zrplService.getCategoryImageByName(category_name);
  }

  ngOnInit(): void {
  }

}
