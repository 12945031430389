import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GiftCardModalComponent } from 'src/app/shared/components/shared/giftcards/gift-card-modal/gift-card-modal.component';
import { environment } from 'src/environments/environment';
import { IndianCurrencyPipe } from '../pipes/indian-currency/indian-currency.pipe';
import { DeviceService } from './device.service';

@Injectable({
  providedIn: 'root'
})
export class GiftcardService {

	MODAL_TABS = {
		BUY_GIFTCARDS: "buy_giftcards",
		ABOUT: "about",
		STEPS_TO_REDEEM: "steps_to_redeem",
		TERMS_AND_CONDITIONS: "terms_and_conditions"
	}

	constructor(
		private http: HttpClient,
		private dialog: MatDialog,
		private deviceService: DeviceService,
		@Inject(DOCUMENT) private document: any,
	) { }


	getMinValue(giftcard) {
		if (giftcard.attributes.price_type == 'slab') {
		  return '₹'+ Math.min(...giftcard.attributes.denominations);
		}
		return '₹'+ new IndianCurrencyPipe().transform(giftcard.attributes.min_price);
	}
	
	getMaxValue(giftcard) {
		if (giftcard.attributes.price_type == 'slab') {
		  return '₹'+ Math.max(...giftcard.attributes.denominations);
		}
		return '₹'+ new IndianCurrencyPipe().transform(giftcard.attributes.max_price);
	}

  	getGiftCards(perPage: number, pageNum: number, isFeatured: boolean = false, isPopular: boolean = false) {
		let httpOptions = {};
		if(pageNum && perPage) {
			httpOptions["params"] = {
				page: pageNum,
				per_page: perPage,
			}
		}
		if(!httpOptions["params"]) {
			httpOptions["params"] = {};
		}
		// if(isFeatured) {
		// 	httpOptions["params"]["context"] = "featured";
		// }
		if(isPopular || isFeatured) {
			httpOptions["params"]["popular"] = true;
		}
		return this.http.get(environment.offerPathV2+'/gift_cards', httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	buyGiftCard(giftcard, showKnowMore: boolean = false) {
		this.dialog.open(GiftCardModalComponent, {
			panelClass: this.deviceService.isMobile ? ['animate__animated', 'animate__fadeInUp', 'giftcard-modal'] : 'giftcard-modal',
			data: {
				activeTab: showKnowMore ? this.MODAL_TABS.STEPS_TO_REDEEM : this.MODAL_TABS.BUY_GIFTCARDS,
				giftcard: giftcard
			}
		})
	}

	getGiftCardDetails(id) {
		let httpOptions = {}
		return this.http
		.get(environment.offerPathV2 + `/gift_cards/${id}`, httpOptions)
		.toPromise();
	}

	searchGiftCards(params) {
		let httpOptions = {
			params: params
		};		
		return this.http.get(environment.offerPathV2 + "/gift_cards", httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	addToCart(params) {
		return this.http.post(environment.apiUrlBsV1 + `/carts/add_items`,params).pipe(map(res => res || []),catchError(error => throwError(error)));
	}

	cartCheckout() {
		const data = {category: "GiftCard"};
		return this.http.post(environment.apiUrlBsV1 + `/carts/checkout`, data);
	}

	clearCartItems() {
		const data = {category: "GiftCard"};
		return this.http.post(environment.apiUrlBsV1 + `/carts/clear_cart`, data).toPromise();
	}

	changePaymentStatus(booking_id) {
		return this.http.post(environment.apiUrlPaV1 + 'payments/change_status' ,{booking_id: booking_id, status: "user_cancelled"}).toPromise();
	}

	createPayment(payload) {
		return this.http.post(environment.apiUrlPaV1 + 'payments', payload).toPromise();
	}

	getBookingById(id) {
		return this.http.get(environment.apiUrlBsV1 + `/bookings/${id}`).toPromise();
	}

	getBookings() {
		return this.http.get(environment.apiUrlBsV1 +'/bookings', {params: {type: "GiftCardBooking"}});
	}

}
