import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HideForFoundersCardGuard implements CanActivate, CanActivateChild, CanLoad {

  isFounderCard() {
    let subDomain: any = document.location.hostname.split("www.").join("").split(".", 1);
    subDomain = subDomain.join("").split("sandbox-").join("").split(".", 1).shift();
    if(subDomain.includes("founder")) {
      return true;
    } else {
      return false;
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.isFounderCard()) {
        return false;
      } else {
        return true;
      }
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.isFounderCard()) {
        return false;
      } else {
        return true;
      }
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.isFounderCard()) {
        return false;
      } else {
        return true;
      }
  }
}
