import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indianCurrency'
})
export class IndianCurrencyPipe implements PipeTransform {

  transform(value: number, defaultEmptyValue?: boolean): any {
    if (!isNaN(value)) {
        if (!value && defaultEmptyValue) {
            return value;
        }
        if (!value) {
            return '0';
        }
        const result = value.toString().split('.');

        let lastThree = result[0].substring(result[0].length - 3);
        const otherNumbers = result[0].substring(0, result[0].length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        let output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

        output = output.replace(/\s/g, '');

        if (result.length > 1) {
            output += '.' + result[1].substring(0, 2);
        }
        return output;//currencySymbol + output; since we use css for symbol
    }

}

}
