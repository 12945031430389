<div
  class="modal-bg"
  [ngStyle]="{ background: GlobalConstants.data.colors.couponModalBgColor }"
>
  <div class="d-flex flex-row">
    <div class="merchant-title cfff fnt-16 f600">
        {{selectedOffer?.merchantName}}
    </div>
    <img src="../../../../../assets/icons/close.svg" class="close-icon mt-auto mb-auto pointer" (click)="closeModal()" />
  </div>

  <div class="d-flex flex-row mt-4">
    <img [src]="selectedOffer?.merchantLogoUrl" class="rounded-circle merchant-logo" />
    <div class="fnt-14 cfff f500 ml-3 align-self-center">
        {{selectedOffer?.offerTitle}}
    </div>
  </div>

  <div
    *ngIf="couponLoading"
    class="flex align-center justify-center h100 loader-coupon mar-top-36 mar-bot-36"
  >
    <div class="page-loader"></div>
  </div>
  <div *ngIf="!couponLoading" class="custom-dashed-border top mt-4">
    <div
      class="modal-body coupon-code-pop p-0 text-center"
      *ngIf="voucherData && (voucherData.success == true)"
    >
      <ng-container>
        <div class="coupon-code-main text-center flex-wrap pad-20 mar-top-5">
        
          <div
            class="coupan-code-box m-auto relative whiteBg justify-content-between d-flex flex-row p-2 rounded"
            id="redemption_coupon_code"
            *ngIf="selectedOffer?.offerType != 'no_voucher'"
            appClickAnalytics 
            analyticsLabel="rewards_dashboard.coupon_code_copied"
          >
            <span class="fnt-13 voucher-code f400 m-auto w-75">
                <b *ngIf="!showCustomRedeemMessage">
                    {{ voucherData.offer_type && voucherData.offer_type === "link_voucher" ?  "No Voucher Code" : voucherData.voucher_code }}
                </b>
                <b *ngIf="showCustomRedeemMessage" [innerHtml]="customRedeemMessage[selectedOffer.id]"></b>
            </span>
            <div role="button" class="text-capitalize blue-text pointer w-25 text-start" (click)="copy(voucherData.voucher_code)" *ngIf="!showCopiedState">
              copy
            </div>
            <div class="text-capitalize green-text d-flex flex-row" *ngIf="showCopiedState">
              copied <span class="icon-check align-self-center mar-top-2"></span>
            </div>
          </div>
          <div
            class="coupan-code-box m-auto relative whiteBg justify-content-between d-flex flex-row p-2 rounded"
            id="redemption_coupon_code"
            *ngIf="selectedOffer?.offerType == 'no_voucher'"
          >
            <span class="fnt-13 f400 voucher-code m-auto">
              <b *ngIf="showCustomRedeemMessage" [innerHtml]="customRedeemMessage[selectedOffer.id]"></b>
              <b *ngIf="!showCustomRedeemMessage">No Voucher Required</b>
            </span>
          </div>
          <div class="validity-box cfff fnt-12 f500 mt-3">
            <small id="validity_coupon_code_return">Valid till:<span class="mar-left-5">{{selectedOffer?.endDate | date: "mediumDate"}}</span></small>
          </div>
        </div>

        <div class="coupon-code-footer pad-top-20 custom-dashed-border top">
          <div class="fnt-12 f300 cfff w-100 m-auto">Please refer to the Offer <div class="text-decoration-underline d-inline pointer" (click)="redirectToMerchantTerms()">Terms and Conditions</div> on how to redeem this offer</div>
          <div class="text-center go-to mar-top-20" *ngIf="!hideRedeemButton || selectedOffer.redemptionUrl">
            <img
              class="loader"
              width="31px"
              *ngIf="redeeming"
              src="./assets/img/icons/spin.gif"
            />
            <button
              *ngIf="!redeeming"
              class="btn btn-theme shop_now_url fnt-14 cfff custom-button primary w-100"
              (click)="goTo()"
              appClickAnalytics
              analyticsLabel="rewards_dashboard.reward_redeem_initiated"
            >
              Redeem
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      class="modal-body coupon-error text-center"
      *ngIf="voucherData && voucherData.success == false"
    >
      <svg
        class="mar-top-48"
        fill="red"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 512.001 512.001"
        style="enable-background: new 0 0 512.001 512.001"
        xml:space="preserve"
      >
        <g>
          <path
            d="M503.839,395.379l-195.7-338.962C297.257,37.569,277.766,26.315,256,26.315c-21.765,0-41.257,11.254-52.139,30.102    L8.162,395.378c-10.883,18.85-10.883,41.356,0,60.205c10.883,18.849,30.373,30.102,52.139,30.102h391.398    c21.765,0,41.256-11.254,52.14-30.101C514.722,436.734,514.722,414.228,503.839,395.379z M477.861,440.586    c-5.461,9.458-15.241,15.104-26.162,15.104H60.301c-10.922,0-20.702-5.646-26.162-15.104c-5.46-9.458-5.46-20.75,0-30.208    L229.84,71.416c5.46-9.458,15.24-15.104,26.161-15.104c10.92,0,20.701,5.646,26.161,15.104l195.7,338.962    C483.321,419.836,483.321,431.128,477.861,440.586z"
          />
        </g>
        <g>
          <rect x="241.001" y="176.01" width="29.996" height="149.982" />
        </g>
        <g>
          <path
            d="M256,355.99c-11.027,0-19.998,8.971-19.998,19.998s8.971,19.998,19.998,19.998c11.026,0,19.998-8.971,19.998-19.998    S267.027,355.99,256,355.99z"
          />
        </g>
      </svg>
      <div class="fnt-16 ln-28 cfff error-line">
        Something went wrong. Please try again later!
      </div>
    </div>
  </div>
</div>
