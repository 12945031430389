import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-benefits-menu',
  templateUrl: './benefits-menu.component.html',
  styleUrls: ['./benefits-menu.component.scss']
})
export class BenefitsMenuComponent implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<BenefitsMenuComponent>,
  ) { }

  ngOnInit(): void {
  }

}
