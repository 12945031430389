import { AnalyticsPayload } from "./analytics-utils";

export const createLumberjackPayload = (analyticsPayload: AnalyticsPayload, lumberjackProjectKey): LumberjackPayload => {
  const {project, eventName, eventProperties, commonProperties} = analyticsPayload;
  return ({
    context: { ...commonProperties, rzpMid: sessionStorage.getItem('rzp_merchant_id')  ?? '' },
    events: [{
      event: eventName,
      event_type: project,
      event_version: "v1",
      timestamp: Date.now(),
      properties: eventProperties
    }],
    key: lumberjackProjectKey,
    mode: "live"
  })
}

export interface LumberjackEvent {
  event: AnalyticsPayload['eventName'];
  event_type: string;
  event_version: "v1" | "v2";
  properties?: Record<string, any>;
  timestamp: number;
}

export interface LumberjackPayload {
  context: Record<string, any>;
  events: LumberjackEvent[];
  key: string;
  mode: "live" | "test"
}