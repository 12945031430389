import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { DeviceService } from 'src/app/common/services/device.service';
import { IProduct, IProductDetails } from 'src/app/interfaces/zrpl';
import { ZrplService } from 'src/app/shared/services/zrpl/zrpl.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  private sku: string;
  public isLoading: boolean = true;
  public productDetailsResponse: IProduct;
  public productDetails: IProductDetails;
  public mainImage: string;
  public shortDescription: string;
  public metaDescriptionOpen: boolean = false;
  public showExandCollapse: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private zrplService: ZrplService,
    private toastr: ToastrService,
    public globalConstants: GlobalConstants,
    public deviceService: DeviceService
  ) {
    if(this.route.snapshot.params.sku) {
      this.sku = this.route.snapshot.params.sku;
    }
  }

  ngOnInit(): void {
    if(!this.sku) return;
    this.getProductDetails();
  }

  ngAfterViewChecked() {
    if(this.productDetails && !this.showExandCollapse) {
      let descriptionElement = document.getElementById("productDesc");
      this.showExandCollapse = descriptionElement.clientHeight < descriptionElement.scrollHeight;
    }
  }

  private getProductDetails() {
    this.zrplService.getProductDetails(this.sku).subscribe({
      next: (response: any) => {
        this.productDetailsResponse = response?.data
        this.isLoading = false;
        if(this.productDetailsResponse) this.productDetails = this.zrplService.setProductDetails(this.productDetailsResponse);
      }, 
      error: () => {
        this.toastr.error("Something went wrong, Please try again later.") //Need to provide right feedback o failure.
        this.isLoading = false;
      }
    })
  }

  public goBack() {
    history.back();
  }

  public buyProduct() {
    this.zrplService.buyProduct(this.productDetails);
  }

}
