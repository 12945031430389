import { Injectable } from '@angular/core';
import { TelemetryService } from './telemetry.service';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from 'src/app/common/constants/global-variables';

@Injectable({
  providedIn: 'root'
})
export class TelemetryOrchestrationService {

  constructor(private readonly globalConstants: GlobalConstants,private readonly telemetryService: TelemetryService) {}

  public initialize() {
    if(this.globalConstants.isAnalyticsEnabled()) {
      this.telemetryService.initialize();
    } else {
      console.info('Analytics is disabled')
    }
  }

}
