import { Component, Input, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { IProduct, IProductDetails } from 'src/app/interfaces/zrpl';
import { ZrplService } from 'src/app/shared/services/zrpl/zrpl.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() product: IProduct;
  @Input() noOfCards: number;
  @Input() isLoading: boolean;
  @Input() loaderClasses: string;
  @Input() public isListingPage: boolean = false;

  public productDetails: IProductDetails; 

  constructor(
    public globalConstants: GlobalConstants,
    private zrplService: ZrplService
  ) { }

  ngOnInit(): void {
    if (this.product) this.productDetails = this.zrplService.setProductDetails(this.product);
  }

  public buyProduct(event) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.zrplService.buyProduct(this.productDetails);
  } 

}
