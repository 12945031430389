import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-loader',
  templateUrl: './order-loader.component.html',
  styleUrls: ['./order-loader.component.scss']
})
export class OrderLoaderComponent implements OnInit {

  @Input() count: number;
  public noOfCards: Array<number>;

  constructor() { }

  ngOnInit(): void {
    this.noOfCards = Array(this.count).fill(0).map((x,i)=>i)
  }

}
