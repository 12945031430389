import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { ICategory, ICategoryFilters, ICategoryResponse, IProduct } from 'src/app/interfaces/zrpl';
import { ZrplService } from 'src/app/shared/services/zrpl/zrpl.service';
import _underscore from "underscore";

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class ListingComponent implements OnInit {

  private categoryId: string;
  public categories: Array<any> = [];
  public inViewCategories: Array<any> = [];
  public inDropdownCategories: Array<any> = [];
  public showDropdown: boolean = false;
  public isCategoriesLoading: boolean = true;
  public isProductsLoading: boolean = false;
  public failedToGetProducts: boolean = false; //Need to discuss what UI to be showed to the user on failure (toastr vs image with text)
  public selectedCategory: ICategory;
  private getProductsRequest: Subscription;
  private categorySelectionSubscription: Subscription;
  public categoryBasedProducts: Array<{category: ICategory, products: Array<IProduct>, promise: Subscription, isLoading: boolean, setOfCards: Array<number>} | undefined> = []; 
  public categoryFilters: Array<ICategoryFilters> = []; //Need to ad interface type
  public isCategoryFiltersLoading: boolean = true;
  public selectedFilters: Array<Array<any>> = [];
  private page: number = 0;
  private perPage: number = 16;
  private hasMoreProducts: boolean = true;

  @ViewChild('dropdownRef') dropdownRef: ElementRef;
  @ViewChild('dropdownMenu') dropdownMenu: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private zrplService: ZrplService,
    public globalConstants: GlobalConstants,
    private router: Router
  ) {
    if(this.route.snapshot.params.categoryId) {
      this.categoryId = this.route.snapshot.params.categoryId;
    }
    this.categorySelectionSubscription = this.zrplService.selectedCategory.subscribe((category: ICategory) => {
      this.getProductsRequest?.unsubscribe();
      this.selectedCategory = category;
      this.categoryFilters = [];
      this.categoryBasedProducts = [];
      this.router.navigateByUrl(this.router.url.replace(this.categoryId, this.selectedCategory.id));
      this.categoryId = this.selectedCategory?.id;
      this.page = this.zrplService.getPageNumberByCategory(this.selectedCategory?.id);
      if(this.zrplService.getProductsBy(this.selectedCategory?.id).length > 0) {
        this.categoryBasedProducts = this.zrplService.getProductsBy(this.selectedCategory?.id);
        this.hasMoreProducts = this.categoryBasedProducts?.length < this.zrplService.getTotalProductsByCategory(this.selectedCategory?.id);
      } else {
        this.hasMoreProducts = true;
        this.getProducts();
      }
      this.getCategoryFilters();
    });
  }

  ngOnInit(): void {
    this.getCategories();
  }

  private onClick(event) {
    if (this.dropdownRef && this.dropdownRef.nativeElement && !this.dropdownRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }

  public onScrollCategories(event) {
    if (this.dropdownRef && !this.dropdownRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }

  public handleDropdownToggle() {
    this.showDropdown = !this.showDropdown;
    if(!this.showDropdown && this.dropdownMenu && this.dropdownMenu.nativeElement) {
      this.dropdownMenu.nativeElement.style.left = '';
      this.dropdownMenu.nativeElement.style.right = '';
      this.dropdownMenu.nativeElement.style.top = '';
    }
  }

  private segmentOutCategories() {
    if(this.selectedCategory) {
      this.categories = this.categories.filter((category) => category.id != this.selectedCategory.id)
      this.categories.unshift(this.selectedCategory);
    }
    this.inViewCategories = this.categories.length > 6 ? this.categories.slice(0,6) : this.categories;
    if(this.categories.length > 7) {
      this.inDropdownCategories = this.categories.slice(5, this.categories.length);
    }
  }

  public isFilterChecked(code, value): boolean {
    if(!this.selectedFilters[code]) this.selectedFilters[code] = [];
    return _underscore.contains(this.selectedFilters[code], value);
  }

  public toggleFilter(code, value) {
    if(this.isFilterChecked(code, value)) {
      _underscore.without(this.selectedFilters[code], value);
    } else {
      this.selectedFilters[code].push(value);
    }
    this.categoryBasedProducts = [];
    this.getProducts();
    //Get products with filters
  }

  public resetFilters() {
    this.selectedFilters = [];
    this.categoryBasedProducts = [];
    this.page = 0;
    this.getProducts();
  }

  public onScroll() {
    this.page = ++this.page;
    if(this.hasMoreProducts) {
      this.getProducts();
    }
  }

  private getCategoryFilters() {
    this.isCategoryFiltersLoading = true;
    this.zrplService.getCategoryFilters(this.selectedCategory?.id).subscribe({
      next: (response: Array<ICategoryFilters>) => {
        if(response?.length) this.categoryFilters = response;
        this.isCategoryFiltersLoading = false;
      },
      error: () => {
        this.isCategoryFiltersLoading = false;
        //Handle error
      }
    })
  }

  intitSelectedCategory() {
    if(!this.categoryId) { this.selectedCategory = this.categories[0]; return;}
    let tempSelectedCategoryArr = this.categories?.filter((category) => category.id === this.categoryId);
    this.selectedCategory = tempSelectedCategoryArr[0];
  }

  private getCategories() {
    if(this.zrplService.categories.length > 0) {
      this.categories = this.zrplService.categories;
      this.intitSelectedCategory();
      this.segmentOutCategories();
      this.getProducts();
      this.getCategoryFilters();
      this.isCategoriesLoading = false;
    } else {
      this.isCategoriesLoading =  true;
      this.zrplService.getCategories().subscribe({
        next: (response: ICategoryResponse) => {
          if(response?.data?.attributes?.children_data && Array.isArray(response?.data?.attributes?.children_data)) {
            this.categories = response?.data?.attributes?.children_data;
            if(this.categories.length > 0) {
              this.intitSelectedCategory();
            }
            this.segmentOutCategories();
          }
          this.isCategoriesLoading = false;
          this.getProducts();
          this.getCategoryFilters();
        },
        error: (error: any) => {
          this.isCategoriesLoading = false;
          //Need to handle failure case
        }
      })
    }
  }

  private buyOfferOffer(product) {
    this.zrplService.buyProduct(product);
  }

  public handleCategorySelect(category) {
    this.selectedCategory = category;
    this.zrplService.selectedCategory.emit(category);
  }

  public handleCategoryFromDropdown(category, i) {
    let lastInViewCategory = this.inViewCategories[this.inViewCategories.length - 1];
    this.inViewCategories[this.inViewCategories.length - 1] = category;
    this.inDropdownCategories[i] = lastInViewCategory;
    this.selectedCategory = category;
    this.zrplService.selectedCategory.emit(category);
  }

  ngOnDestroy() {
    if(this.getProductsRequest) {
      this.getProductsRequest.unsubscribe();
    }
    if(this.categorySelectionSubscription) {
      this.categorySelectionSubscription.unsubscribe();
    }
  }

  private getProducts() {
    console.log(this.selectedCategory, "this.selectedCategory");
    if(this.getProductsRequest && !this.getProductsRequest?.closed) {
      this.page = --this.page;
      this.getProductsRequest.unsubscribe();
    }
    this.failedToGetProducts = false;
    this.isProductsLoading = true;
    let filterParams = {};
    Object.keys(this.selectedFilters).map((filterKey) => {
      if( this.selectedFilters[filterKey].length > 0) {
        filterParams['filters['+filterKey+']'] = this.selectedFilters[filterKey].join(',');
      }
    });
    this.getProductsRequest = this.zrplService.getProductsByCategory(this.selectedCategory?.id, this.perPage, this.page, filterParams).subscribe((response: any) => {
      if(response.data) {
        this.categoryBasedProducts = [...this.categoryBasedProducts,...response.data];
        this.zrplService.updateProductsStore(this.selectedCategory?.id, this.categoryBasedProducts, this.page, response?.total_entries);
        this.hasMoreProducts = this.categoryBasedProducts?.length < response?.total_entries;
      }
      this.isProductsLoading = false;
    }, error => {
      this.isProductsLoading = false;
      this.failedToGetProducts = true;
    })
  }

  public backToHomePage() {
    this.router.navigate(['/electronics']);
  }

}
