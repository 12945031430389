<div class="d-flex flex-row position-sticky bottom-0 w-100 pad-10 navigation-items-container d-lg-none d-md-none row m-0" *ngIf="!hideNavMenu" [ngStyle]="{'background': globalConstants.isFounderCard ? '#0B0E12' : ''}">
    <div class="claimed-rewards d-flex flex-column navigation-item col" routerLink="/claimed-rewards">
        <div class="icon m-auto">
            <span class="icon-historyicon fnt-20" [ngStyle]="{'color': currentUrl == '/claimed-rewards' ? 'var(--blueTextColor)' : '#fff'}"></span>
        </div>
        <div class="fnt-12 f500 cfff mar-top-11 text-center" [ngClass]="{'blue-text': currentUrl.includes('/claimed-rewards')}">
            Claimed Rewards
        </div>
    </div>
    <div class="home d-flex flex-column navigation-item col" *ngIf="!globalConstants.isFounderCard" (click)="openBenefitsModal()">
        <img src="../../../../../assets/icons/explore_benefits.svg" class="icon m-auto" />
        <div class="fnt-12 f500 cfff mar-top-11 text-center" [ngClass]="{'blue-text': isInBenefitsPage}">
            Explore Benefits
        </div>
    </div>
    <!-- <div class="home d-flex flex-column navigation-item col" routerLink="/" *ngIf="!globalConstants.isFounderCard">
        <div class="icon m-auto">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.875 16.5H5.625V10.25H11.125V16.5H14.875V6.75L8.375 1.875L1.875 6.75V16.5ZM1.875 18C1.45833 18 1.10417 17.8542 0.8125 17.5625C0.520833 17.2708 0.375 16.9167 0.375 16.5V6.75C0.375 6.51667 0.429167 6.29167 0.5375 6.075C0.645833 5.85833 0.791667 5.68333 0.975 5.55L7.475 0.675C7.60833 0.575 7.75 0.5 7.9 0.45C8.05 0.4 8.20833 0.375 8.375 0.375C8.54167 0.375 8.7 0.4 8.85 0.45C9 0.5 9.14167 0.575 9.275 0.675L15.775 5.55C15.9583 5.68333 16.1042 5.85833 16.2125 6.075C16.3208 6.29167 16.375 6.51667 16.375 6.75V16.5C16.375 16.9167 16.2292 17.2708 15.9375 17.5625C15.6458 17.8542 15.2917 18 14.875 18H9.625V11.75H7.125V18H1.875Z" [attr.fill]="currentUrl == '/' ? 'var(--blueTextColor)' : 'white'"/>
            </svg>
        </div>
        <div class="fnt-12 f500 cfff mar-top-11 text-center" [ngClass]="{'blue-text': currentUrl=='/'}">
            Explore Rewards
        </div>
    </div> -->
    <!-- <div class="home d-flex flex-column navigation-item col" routerLink="/milestone-rewards" *ngIf="!globalConstants.isFounderCard">
        <div class="icon m-auto">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.375 0C4.08308 0 3.0637 0.179383 2.29829 0.4216C1.56229 0.654513 0.993053 0.967733 0.667893 1.29289C0.480357 1.48043 0.375 1.73478 0.375 2V21C0.375 21.5523 0.822715 22 1.375 22C1.92728 22 2.375 21.5523 2.375 21V14.5337C2.50881 14.469 2.68333 14.3975 2.90171 14.3284C3.45297 14.154 4.26692 14 5.375 14C6.65569 14 7.73551 14.4212 9.00361 14.9285L9.05932 14.9508C10.2681 15.4344 11.658 15.9906 13.3325 15.9999C14.0401 16.0523 14.9502 15.8465 15.688 15.6373C16.4891 15.4102 17.2707 15.1234 17.7542 14.9253C18.1297 14.7714 18.375 14.4058 18.375 14V2C18.375 1.61559 18.1547 1.26522 17.8082 1.0987C17.4617 0.932177 17.0505 0.978995 16.7503 1.21913C16.4403 1.46712 15.8821 1.67878 15.1789 1.81942C14.5036 1.95448 13.8204 2 13.375 2C12.0943 2 11.0145 1.57876 9.74639 1.07152L9.69068 1.04923C8.4717 0.561484 7.06845 0 5.375 0ZM2.375 12.3978V2.53374C2.50881 2.46899 2.68333 2.39751 2.90171 2.3284C3.45297 2.15395 4.26692 2 5.375 2C6.65569 2 7.73551 2.42124 9.00361 2.92848L9.05932 2.95077C10.2783 3.43852 11.6816 4 13.375 4C13.9296 4 14.7464 3.94552 15.5711 3.78058C15.8284 3.72912 16.1008 3.66421 16.375 3.58132V13.313C16.0076 13.4462 15.5764 13.5901 15.1425 13.7131C14.3794 13.9295 13.7702 14.0324 13.4676 14.0043C13.4368 14.0014 13.4059 14 13.375 14C12.0943 14 11.0145 13.5788 9.74639 13.0715L9.69068 13.0492C8.4717 12.5615 7.06845 12 5.375 12C4.12577 12 3.13136 12.1677 2.375 12.3978Z" [attr.fill]="currentUrl == '/milestone-rewards' ? 'var(--blueTextColor)' : 'white'" />
            </svg>
        </div>
        <div class="fnt-12 f500 cfff mar-top-11 text-center" [ngClass]="{'blue-text': currentUrl=='/milestone-rewards'}">
            Milestone Rewards
        </div>
    </div> -->
    <div class="profile d-flex flex-column navigation-item col" routerLink="/profile">
        <div class="icon m-auto">
            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.125 0C6.36358 0 4.125 2.23858 4.125 5C4.125 7.76142 6.36358 10 9.125 10C11.8864 10 14.125 7.76142 14.125 5C14.125 2.23858 11.8864 0 9.125 0ZM6.125 5C6.125 3.34315 7.46815 2 9.125 2C10.7819 2 12.125 3.34315 12.125 5C12.125 6.65685 10.7819 8 9.125 8C7.46815 8 6.125 6.65685 6.125 5Z" [attr.fill]="currentUrl.includes('/profile') ? 'var(--blueTextColor)' : 'white'"/>
                <path d="M5.125 12C2.36358 12 0.125 14.2386 0.125 17V19C0.125 19.5523 0.572715 20 1.125 20C1.67728 20 2.125 19.5523 2.125 19V17C2.125 15.3431 3.46815 14 5.125 14H13.125C14.7819 14 16.125 15.3431 16.125 17V19C16.125 19.5523 16.5727 20 17.125 20C17.6773 20 18.125 19.5523 18.125 19V17C18.125 14.2386 15.8864 12 13.125 12H5.125Z" [attr.fill]="currentUrl.includes('/profile') ? 'var(--blueTextColor)' : 'white'"/>
            </svg>
        </div>
        <div class="fnt-12 f500 cfff mar-top-11 text-center" [ngClass]="{'blue-text': currentUrl.includes('/profile')}">
            Profile
        </div>      
    </div>
</div>