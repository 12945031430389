import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { DeviceService } from 'src/app/common/services/device.service';
import { ApiService } from 'src/app/shared/services/commonapi/api.service';
import { RedemptionService } from 'src/app/shared/services/redemption/redemption.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  showProfileDropdown: boolean = false;
  isAuthenticated: boolean = false;
  isMobile: boolean = false;
  isEmailVerified: boolean = false;
  protected parsedJWT:any;
  protected environment:any;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private authService: AuthenticationService,
    private deviceService: DeviceService,
    public globalConstants: GlobalConstants,
    private redemptionService: RedemptionService,
    private apiService: ApiService
  ) {
    this.isMobile = this.deviceService.isMobile;
    this.deviceService.deviceObserver.subscribe((response) => {
      this.isMobile = response;
    });
    this.authService.isUserLoggedIn.subscribe((isLoggedIn) => {
      if(isLoggedIn) {
        this.checkIfUserAuthenticated();
      }
    });
    this.environment = environment;
  }

  handleBackToPortal() {
      window.location.href = this.globalConstants.data.portalLink;
  }

  public getLocationPath() {
    return window.location.pathname;
  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  private checkIfUserAuthenticated() {
    if(localStorage.getItem("userDetails") && Object.keys(JSON.parse(localStorage.getItem("userDetails"))).length > 0) {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      if (currentUser?.token){
        this.parsedJWT = this.parseJwt(currentUser?.token);
      }
      if(userDetails.mobile && userDetails.status == "activated") {
        this.checkIfEmailIsVerified();
        this.isAuthenticated = true;
      } else {
        this.isAuthenticated = false;
      }
    } else {
      this.isAuthenticated = false;
    }
  }

  checkIfEmailIsVerified() {
    this.apiService.getUserDetails().subscribe((userDetails) => {
      if(userDetails.email_verified) {
        this.isEmailVerified = true;
      } else {
        this.isEmailVerified = false;
      }
    });
  }

  handleProfileDropdown() {
    this.showProfileDropdown = !this.showProfileDropdown
  }

  redirectTo(event, targetRoute) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if(targetRoute) {
      this.showProfileDropdown = false;
      this.router.navigate([targetRoute]);
    }
  }

  ngOnInit(): void {
    this.checkIfUserAuthenticated();
  }

  private parseJwt (token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

}
