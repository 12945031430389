<div class="d-flex flex-column" [ngClass]="{'p-4': data.showCloseButton}" id="terms-and-conditions">
    <div class="d-flex justify-content-end" *ngIf="data.showCloseButton">
        <img src="../../../../assets/icons/close.svg" class="close-icon mt-auto mb-auto pointer" (click)="closeModal()" />
    </div>
    <div class="section">
        <h1>Terms & Conditions</h1>

        <ul>
            <li>RazorpayX Business Rewards Portal is a platform only for the display of offers extended by Merchants to RazorpayX Corporate Cardholders.</li>
            <li>The RazorpayX Business Rewards Portal is being hosted and managed by PoshVine on behalf of Razorpay and is purely for the convenience of RazorpayX Corporate Cardholders to avail offers with RazorpayX Corporate Cards.</li>
            <li>The Products/Services are being offered solely by the Merchants and Razorpayis not selling/rendering any of these Products/Services. The Cardholder is free to purchase/avail of any Products/Services from any other stores/online platforms and by using any other payment mechanism. Razorpay is merely facilitating the offers of these Merchants using RazorpayX Corporate Cards.</li>
            <li>By hosting the RazorpayX Business Rewards Portal, Razorpay is neither guaranteeing, nor giving any warranty, nor making any representation with respectto the offers made by Merchants. Razorpay is neither endorsing the Merchants or any Product/Service nor is responsible for the sale/quality/features of the Products/Services under the offer.</li>
            <li>Any Information on the RazorpayX Business Rewards Portal whether about the Merchant or the Products/Services being offered has not been verified by Razorpay. Razorpay shall not be liable or responsible for any incorrect information about the offers. , Razorpaywill not be responsible for any act or omission of the Merchant. AllProduct/Service-related queries/complaints will be addressed by the respective Merchants only.</li>
        </ul>

        <h4>General Terms of Service</h4>

        <p>The website <a class="blue-text" href="https://razorpayx.poshvine.com/">https://razorpayx.poshvine.com/</a> is powered and maintained by Viverati Interactive Marketing Pvt. Ltd. (PoshVine) on behalf of Razorpay. You agree to these Terms by accessing or using any of the Services. The following terms and conditions apply to all visitors or users of this website. Please read these terms carefully before using this website. By accessing or using this website you explicitly agree to comply with and be bound by these terms. When you access any of the sub-site (whether belonging to an ‘associate’ of Razorpay or otherwise) through this website, then the sub-site may have its own terms and conditions, which are specific to such sub-site.</p>
        
            <h5>Our Services</h5>
                <p>Our Services are provided for your personal information and non-commercial use. The content of our Services is provided for general information only. There will be occasions our Services may be interrupted due to scheduled maintenance or upgrades, emergency repairs, failure of telecommunications links and/or equipment, or similar occurrences.</p>
                <p>Our Services contain text, software, images, photographs, graphics, audio, video, applications, user interfaces, visual interfaces, logos, designs, product names, and technical specifications (“Materials”) which are derived in whole or in part from information and materials supplied by us and other sources and are protected by, copyright, trademark, patent, and other applicable laws. Trademarks, logos, and service marks displayed in our Services are registered and unregistered trademarks of Razorpay, its subsidiaries and affiliated companies, its licensors or content providers, or other third parties. These trademarks, logos, and service marks are the property of their respective owners. Nothing in our Services shall be constructed as granting any license or right to use any trademark, logo, or service mark displayed without the owner’s prior written permission.</p>
                <p>The use of any such Materials on any other website or networked computer or environment without our express written consent is prohibited. Materials may not be copied, reproduced, republished, modified, uploaded, posted, transmitted, or distributed in any way nor may they be decompiled, reverse engineered, or disassembled, except that you may download one copy of the Material on any single computer for your personal, non-commercial use, provided you keep intact all copyright, trademark and other proprietary notices and make no change in or deletion of author attribution. This limited authorization is not a transfer of title in the Materials, and, by your use of our Services, you acknowledge that you do not acquire any license, ownership, or other rights in or to the Materials.</p>

        <h4>Website & Its Contents</h4>

            <p>This site is only for your personal use. All materials provided on this website, including but not limited to all text, logos, designs, service marks, trademarks, graphics, images, sounds, information, software, documents, downloads of any kind, products and services, and the selection, arrangement, and display thereof, are the copyrighted works of PoshVine or any of its Partners. You shall not distribute exchange, modify, sell or transmit anything you copy from this site for any business, commercial or public purpose. Any unauthorized use of the Content may violate copyright laws, trademark laws, the laws of privacy and publicity, and civil and criminal statutes.</p>
            <p>In order to access some features of our Services, you may have to log in using the RazorpayX login feature. You are responsible for your account and Razorpay is only facilitating the offers. If you become aware of or suspect any breach of security or unauthorized use of your password or of your account, you should notify your Card Issuer immediately.</p>
            <p>You agree you have the legal right and capacity to enter into these terms and to comply with all laws, rules, and regulations applicable to your use of our Services. You also agree that you will not:</p>
            <ul>
                <li>Circumvent, disable or otherwise interfere with any security-related features of our Services or features that prevent or restrict use or copying of any Materials and User Submissions or enforce limitations on use of our Services and the Materials or User Submissions;</li>
                <li>Engage in any activity that interferes with or disrupts our Services (or the servers and networks which are connected to our Services), including by transmitting any worms, viruses, spyware, malware, or any other code of a destructive or disruptive nature;</li>
                <li>Inject content or code or otherwise alter or interfere with the way any part of our Services is rendered or displayed in a user’s browser or device;</li>
                <li>Change, alter or modify any part of our Services for any reason;</li>
                <li>Use or launch any type of automated system, including but not limited to, “robots,” “spiders,” or “offline readers,” etc., that accesses our Services in a manner that sends more request messages to our servers in a given period of time than a human can reasonably produce in the same period by using a conventional online web browser;</li>
                <li>Frame or deep-link into any part of this website;</li>
                <li>Access (or attempt to access) any of our Services, including Materials and User Submissions, by any means other than through the interfaces that are provided by us;</li>
                <li>Submit abusive, obscene, threatening, harassing, defamatory, libelous, offensive, violent, nude, partially nude, discriminatory, unlawful, infringing, hateful, pornographic or sexually suggestive User Submissions via our Services, or User Submissions that encourage conduct that would be considered a criminal offense, give rise to civil liability, violate any law, or are otherwise inappropriate.</li>
                <li>Use our Services for any illegal or unauthorized purpose.</li>
            </ul>

        <h4>General Provisions</h4>
            
            <p>Razorpay may add, modify or remove any part of these Terms and Conditions at any time as it may reasonably deem fit. Any changes to these Terms and Conditions or any terms posted on this site apply as soon as they are posted. By continuing to use this site after any changes are posted, you are indicating your acceptance of those changes.</p>
            <p>Razorpay may add, change, discontinue, remove or suspend any other content posted on this site, including features and specifications of products or services described or depicted on the site, temporarily or permanently, at any time, without notice and without liability.</p>
            <p>To ensure the security, safety, and integrity of RazorpayX Business Rewards Portal systems PoshVine may take various steps to verify and confirm the authenticity, enforceability, and validity of orders placed by you.</p>

        <h4>Limitation of Liability</h4>

            <p>You agree that you assume full responsibility for your use of our Services and that Razorpay/PoshVine’s liability to you and any party is limited as follows. Under no circumstances will we, our suppliers or other third parties mentioned or involved in creating, producing, or delivering our Services be liable for any direct, incidental, consequential, indirect, special or punitive damages whatsoever (including without limitation, costs and expenses of any type incurred, lost profits, lost data or programs, and business interruption) arising out of your access to, use of, inability to use or the results of use of our Services, or any Materials contained in any or all such Services (including without limitation, those caused by or resulting from a failure of performance; error; omission; linking to Linked Sites; interruption; deletion; defect; delay in operation or transmission; computer virus; communication line failure; or destruction of, unauthorized access to, alteration of, or use of any computer or system), whether based on warranty, contract, tort, negligence, strict liability, or any other legal theory and whether or not we were advised of the possibility of such damages. We shall have no liability or responsibility for any acts, omissions, or conduct of any user or third party, including User Submissions and Linked Sites. The maximum total liability of Razorpay and its subsidiaries, affiliates and our officers, directors, shareholders, predecessors, successors in interest, employees, and agents, to you for any claim under these Terms, whether in contract, tort, or otherwise, is one thousand rupees (Rs. 1,000). If you are dissatisfied with any of the Materials contained in our Services, or with any of these Terms, may discontinue accessing and using our Services.</p>
            
        <h4>Indemnity</h4>
            
            <p>You agree to indemnify, defend and hold us, our officers, directors, shareholders, predecessors, successors in interest, employees, agents, subsidiaries, and affiliates harmless from any demands, loss, liability, claims, and expenses (including attorneys’ fees) made against Razorpay/PoshVine by any third party due to, arising out of or in connection with: (i) your use of and access to our Services; (ii) your breach or alleged breach of these Terms; (iii) your violation or alleged violation of any third-party right, including without limitation, any intellectual property right, publicity, confidentiality, property or privacy right; (iv) your violation or alleged violation of any laws, rules, regulations, codes, statutes, ordinances or orders; (v) any claim that your User Submission caused damage to a third party; or (vi) any misrepresentation made by you. We reserve the right to assume the exclusive defence and control of any matter subject to indemnification and defence by you, and you will not, in any event, settle any claim without our prior written consent. You will cooperate as fully required by us in the defence of any claim. This defence and indemnification obligation will survive these Terms and your use of our Services.</p>
            
        <h4>Offers Terms</h4>

            <ul>
                <li>The offers list is subject to change and will be updated on RazorpayX Business Rewards Portal from time to time.</li>
                <li>Each offer has its own terms and conditions, which shall be set forth in the offers section on the website and in the post-purchase voucher communication issued to the user.</li>
                <li>Razorpay makes no warranties or representations either expressed or implied, with regard to the type, quality, or fitness of the products or services sold by merchants/brands whose offers are being provided through the RazorpayX Business Rewards Portal.</li>
                <li><b>Under the milestone rewards program - merchants who are enrolled for cashback will not be eligible for PG Credits.</b></li>
            </ul>

    </div>

</div>