import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-select-offer',
  templateUrl: './select-offer.component.html',
  styleUrls: ['./select-offer.component.scss']
})
export class SelectOfferComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SelectOfferComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  closeModal(offer = {}) {
    this.dialogRef.close(offer);
  }

}
