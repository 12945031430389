import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/constants/global-variables';

@Component({
  selector: 'app-token-expired',
  templateUrl: './token-expired.component.html',
  styleUrls: ['./token-expired.component.scss']
})
export class TokenExpiredComponent implements OnInit {

  constructor(public globalConstants: GlobalConstants) { }

  ngOnInit(): void {
  }

  handleBackToPortal() {
    window.location.href = this.globalConstants.data.portalLink;
  }

}
