import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './common/services/auth.guard';
import { HideForBusinessCardsGuard } from './common/services/hide-for-business-cards.guard';
import { HideForFoundersCardGuard } from './common/services/hide-for-founders-card.guard';
import { AnalyticsDrivenRoute } from './shared/utils/analytics-utils';


const routes: AnalyticsDrivenRoute[] = [
  {
    path: "",
    loadChildren: () => import("./pages/homepage/homepage.module").then(m => m.HomepageModule),
  },
  {
    path: "rewards",
    loadChildren: () => import("./pages/rewards/rewards.module").then((m) => m.RewardsModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: "memberships",
    loadChildren: () => import("./pages/memberships/memberships.module").then(m => m.MembershipsModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    canLoad: [HideForBusinessCardsGuard]
  },
  {
    path: "claimed-rewards",
    loadChildren: () => import("./pages/claimed-rewards/claimed-rewards.module").then((m) => m.ClaimedRewardsModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'sso_login',
    loadChildren: () => import("./pages/login-sso/login-sso.module").then(m => m.LoginSsoModule)
  },
  {
    path: 'confirm-email/:code',
    loadChildren: () => import("./pages/email-confirmation/email-confirmation.module").then(m => m.EmailConfirmationModule)
  },
  {
    path: 'profile',
    loadChildren: () => import("./pages/profile/profile.module").then(m => m.ProfileModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'milestone-rewards',
    loadChildren: () => import("./pages/milestone-rewards/milestone-rewards.module").then(m => m.MilestoneRewardsModule),
    canActivate: [AuthGuard, HideForFoundersCardGuard],
    canActivateChild: [AuthGuard, HideForFoundersCardGuard],
    canLoad: [HideForFoundersCardGuard],
  },
  {
    path: 'info',
    loadChildren: () => import("./pages/info/info.module").then(m => m.InfoModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
   {
    path: 'airport-transfer',
    loadChildren: () => import("./pages/airport-transfer/airport-transfer.module").then(m => m.AirportTransferModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    canLoad: [HideForBusinessCardsGuard]
   },
   {
    path: "giftcards",
    loadChildren: () => import("./pages/giftcards/giftcards.module").then(m => m.GiftcardsModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    canLoad: [HideForFoundersCardGuard]
   },
   {
    path: "electronics",
    loadChildren: () => import("./pages/electronics/electronics.module").then(m => m.ElectronicsModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    canLoad: [HideForFoundersCardGuard]
   }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRoutingModule { }
