// TODO - Move this file to a shared library
import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { AnalyticsPayload, getCommonProperties } from '../../utils/analytics-utils';
import { LumberjackPayload, createLumberjackPayload } from '../../utils/lumberjack-utils';

@Injectable({
  providedIn: 'root'
})
export class TelemetryService {

  private _routeListener: Subscription;
  constructor(private readonly globalConstants: GlobalConstants, private readonly router?: Router) {}

  public initialize() {
    this.setupPageTracking();
  }

  /**
   * Listen to route changes, on route change, consume information from data provided in route
   * Using the queryParams and the aforementioned data, call the track page method to push event
   */
  private setupPageTracking() {
    this._routeListener = this.router?.events
    .pipe(
      filter(
        (event): event is ActivationEnd => event instanceof ActivationEnd,
      ),
        delay(50)
        )
    .subscribe(event => {
      const routePath = event.snapshot.pathFromRoot
        .map((snapshot) => snapshot?.routeConfig?.path)
        .filter((path) => !path)
        .join('/');
      const { enabled: analyticsEnabled, validator, pageInformationOverrideFunction, pageInformation } = event.snapshot.data.analyticsConfig ?? {};
      const queryParams = event.snapshot.queryParams;
      const overridenPageInfo = pageInformationOverrideFunction ?  pageInformationOverrideFunction.call(null, queryParams) ?? null : null;
      if(analyticsEnabled) {
        if((validator && validator(queryParams)) || !validator ) {
            this.trackPage({pageInformation, ...overridenPageInfo, ...queryParams});
        }
      }
    })
  }

  /**
   * 
   * @param trackingDetails The tracking object properties to be sent to the analytics layer
   */
  public async trackPage(trackingDetails) {
    const payload: AnalyticsPayload = {
      project: this.globalConstants.ANALYTICS_PROJECT_NAME,
      eventName: "Page Viewed",
      eventProperties: trackingDetails,
      commonProperties: getCommonProperties()
    };

    const lumberjackPayload = createLumberjackPayload(payload, this.globalConstants.getLumberjackProjectKey());
    await this.sendData(lumberjackPayload);
  }

  /**
   * 
   * @param trackingDetails The tracking object properties to be sent to the analytics layer
   */
  public async trackClick(trackingDetails) {
    const payload: AnalyticsPayload = {
      project: this.globalConstants.ANALYTICS_PROJECT_NAME,
      eventName: "Click",
      eventProperties: trackingDetails,
      commonProperties: getCommonProperties()
    };

    const lumberJackPayload = createLumberjackPayload(payload, this.globalConstants.getLumberjackProjectKey());
    this.sendData(lumberJackPayload);
  }

  private async sendData(payload: LumberjackPayload) {
    const url = this.globalConstants.getLumberJackURL();
    try {
      await fetch(url, {
        body: JSON.stringify(payload),
        method: 'POST'
      })
    } catch(error) {
      console.error('Analytics not working ', error);
    }

  }

  ngOnDestroy() {
    this._routeListener.unsubscribe();
  }
}
