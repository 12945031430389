<div class="relative d-flex flex-column">
    <div class="d-flex flex-row pad-bot-16 pad-top-16 pad-left-24 pad-right-24">
        <div class="tag-icon-placeholder rounded-circle">
            <img src="../../../../../assets/icons/tag-outline.svg" class="tag-icon" />
        </div>
        <div class="d-flex flex-column mar-left-10">
            <div class="fnt-24 white-text f600">
                Offer on {{data.merchantName}}
            </div>
            <div class="merchant-sub-heading fnt-12 f400">Choose from multiple offers</div>
        </div>
        <img src="../../../../../assets/icons/close.svg" class="close-icon mt-auto mb-auto pointer" (click)="this.dialogRef.close()" />
    </div>
    <div class="custom-border bottom"></div>
    <div class="d-flex flex-column pad-bot-16 pad-top-16 pad-left-24 pad-right-24">
        <div class="d-flex flex-row max-width-100 mb-3 pointer select-card pad-12 rounded" (click)="this.closeModal(offer)" *ngFor="let offer of data.offers">
            <img [src]="data.merchantLogo" class="merchant-logo rounded-circle" />
            <div class="d-grid flex-column">
                <div class="truncate-2-lines fnt-13 f500 cfff mt-auto mb-auto">
                    {{offer.attributes.title}}
                </div>
            </div>
        </div>
    </div>
</div>