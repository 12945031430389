// TODO - Move this file to a shared library

import { ActivatedRouteSnapshot, Data, Route } from "@angular/router";
import { detect } from "detect-browser";
import { isEmpty } from "./common-utils";

const CATEGORY_KEYWORD = "category";

/**
 * 
 * @param merchant The merchant object received from backend
 * @returns a string, which contains all the categories, separated by comma
 */
export const extractCategoriesFromMerchantOffer = (merchant): string[] => {
  return (merchant?.attributes?.offers?.included?.filter((includedObj) => includedObj.type === CATEGORY_KEYWORD) ?? [])
    .map((category) => category?.attributes.name)
    .join(', ');
}

/**
 * 
 * @param merchant The merchant object received from backend
 * @returns string which contains the title of the offer
 */
export const extractOfferTitleFromMerchant = (merchant): string => {

  if(!merchant) {
    return "";
  }

  if(!isEmpty(merchant?.attributes.offers) && !isEmpty(merchant?.attributes.offers?.data["0"])) {
    return (merchant?.attributes?.offers.data[0]?.attributes.title);
  }

  return "";
}

/**
 * 
 * @param merchant The merchant object received from backend
 * @returns an object with properties required by the product team like `offerName`, `brand`, `categories`
 */
export const createQueryParamsForMerchant = (merchant)  => {
  return { 
    offerName: extractOfferTitleFromMerchant(merchant), 
    brand: merchant?.attributes?.name, 
    type: 'offer', 
    categories: extractCategoriesFromMerchantOffer(merchant) 
  };
}

/**
 * 
 * @param giftCard the giftCard object available from the backend response
 * @returns a string with `,` delimeter for all the categories associated with gift card
 */
export const extractCategoriesForGiftCard = (giftCard) => {

  if(!giftCard) {
    return "";
  }

  return giftCard?.attributes?.categories
    .map((category) => category.name)
    .join(", ");
}

/**
 * 
 * @param giftcard the giftCard object available from the backend response
 * @returns a string with `,` delimeter for all the denominations associated with gift card
 */
export const extractDenominationsForGiftCard = (giftcard) => {
  if(!giftcard) {
    return "";
  }

  return giftcard.attributes.denominations.join(", ");
}

/**
 * 
 * @param giftCard the giftCard object available from the backend response
 * @returns object with various properties required by product team for analytics like `offerName`, `brand`, `denominations` and `categories`
 */
export const createQueryParamsForGiftCard = (giftCard)  => {
  return { 
    offerName: giftCard.attributes.title,
    brand: giftCard.attributes.merchant.name,
    denominations: extractDenominationsForGiftCard(giftCard),
    type: 'giftcard', 
    categories: extractCategoriesForGiftCard(giftCard)
  };
}


export const getBrowserAndPlatformProperties = () => {
  const browserAndPlatformProperties = detect();
  if (isEmpty(browserAndPlatformProperties)) {
    return {
      name: undefined,
      version: undefined,
      os: undefined,
      type: undefined
    };
  }

  return browserAndPlatformProperties;
}

export const getCommonProperties = () => {
  const {name: browser, version: browserVersion, os, type} = getBrowserAndPlatformProperties();
  return ({
    referrer: document.referrer,
    user_agent: navigator.userAgent,
    network: {
      // @ts-ignore
      type: navigator?.connection?.effectiveType ?? undefined,
    },
    screen_height: window.screen.height,
    screen_width: window.screen.width,
    os,
    type,
    browser,
    browserVersion
  })
}

export interface AnalyticsPayload {
  project: string;
  eventName: "Click" | "Page Viewed";
  eventProperties: Record<string, any>;
  commonProperties: Record<string, any> | null | undefined;
}

export interface AnalyticsConfig {
  /** Whether analytics should be enabled or not for a particular route */
  enabled: boolean,
  /** The information about the page provided by the Product team to be sent for analytics, generally the page name */
  pageInformation: string,
  /** Function which takes query params as argument and decides whether analytics to be performed or not based on query param, this is for finer tuning */
  validator?: (queryParams: Record<string, string>, snapshot?: ActivatedRouteSnapshot) => boolean,
  /** For some use cases, depending upon value of query params, you would want to update the pageInformation dynamically (ex. modal popup for login) */
  pageInformationOverrideFunction? : (queryParams: Record<string, string>, snapshot?: ActivatedRouteSnapshot) => Record<string, string> | null
}

export interface AnalyticsDrivenRoute extends Route {
  data?: AnalyticsData
}

interface AnalyticsData extends Data {
  analyticsConfig?: AnalyticsConfig
}




