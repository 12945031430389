import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { GlobalConstants } from "src/app/common/constants/global-variables";
import { AuthenticationService } from "src/app/common/services/authentication.service";
import { DeviceService } from "src/app/common/services/device.service";
import { dateDiff } from "src/app/common/utils/util";
import { PrivacyPolicyComponent } from "src/app/pages/info/privacy-policy/privacy-policy.component";
import { TermsAndConditionsComponent } from "src/app/pages/info/terms-and-conditions/terms-and-conditions.component";
import { ApiService } from "src/app/shared/services/commonapi/api.service";

@Component({
  selector: "app-authentication",
  templateUrl: "./authentication.component.html",
  styleUrls: ["./authentication.component.scss"],
})
export class AuthenticationComponent implements OnInit {
  stepToShow: string = "mobile";
  emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,6})$/;
  createWithUserForm: any = this.formBuilder.group({
    mobile: ["", [Validators.required]],
    email: ["", [Validators.email, Validators.pattern(this.emailPattern)]],
    privacyPolicy_and_terms: [false, [Validators.required]]
  });
  userId: string = "";

  //For Step 1 (mobile search)
  selectedCountry: any;
  countrySearchTerm: string = "";
  filteredCountries: Array<{}> = [];
  searchMobileLoader: boolean = false;
  mobileInputBtnActive: boolean = false;
  isMobileNumberSearching: boolean = false;
  errorRequestingOtpMsg: string = "";
  errorRequestingOtp: boolean = false;
  mobNum: string = "";
  selectedCountryAvailable: boolean =  false;

  showMobileInvalidMessage: boolean = false;
  showEmailInvalidMessage: boolean = false;

  //For step 2 (otp search)
  otpInp: any;
  validOtp: boolean = false;
  validateUserOtpForm: any = this.formBuilder.group({
    otp: [""],
  });
  hideResend: boolean = false;
  invalidOtp: boolean = false;
  submittedOtpForm: boolean = false;
  otpVerifying: boolean = false;

  timer: any = "initial";
  viewTimer: any = 0;
  timerToShow: any;

  userCards: Array<any> = [];
  showAddCardComponent: boolean = false;
  loadingCards: boolean = false;

  countries: Array<any> = [];

  @ViewChild("firstFocusable") firstFocusable: ElementRef;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    public GlobalConstants: GlobalConstants,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<AuthenticationComponent>,
    private router: Router,
    private route: ActivatedRoute,
    public deviceService: DeviceService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.isUserLoggedIn.subscribe((isLoggedIn) => {
      if(isLoggedIn) {
        this.matDialogRef.close();
      }
    })
  }

  ngOnInit() {
    if (localStorage.getItem("countries")) {
      this.countries = JSON.parse(localStorage.getItem("countries"));
      this.filteredCountries = this.countries;
      let india;
      this.filteredCountries = this.filteredCountries.filter((country: any) => {
        if(country.attributes.name.toLowerCase() != "india") {
          return true;
        } else {
          india = country;
          return false;
        }
      });
      this.filteredCountries.sort((a: any,b: any) => {
        if(a.attributes.name.charAt(0).toLowerCase() < b.attributes.name.charAt(0).toLowerCase()) { return -1; }
        if(a.attributes.name.charAt(0).toLowerCase() > b.attributes.name.charAt(0).toLowerCase()) { return 1; }
        return 0;
      });
      this.filteredCountries.unshift(india);
      if (localStorage.getItem("selectedCountry")) {
        this.selectedCountry = JSON.parse(
          localStorage.getItem("selectedCountry")
        );
        this.selectedCountryAvailable = true;
      } else {
        this.getDefaultCountry(this.countries);
      }
    } else {
      this.getAllCountries();
    }
  }

  private getAllCountries() {
    if (
      !localStorage.getItem("selectedCountry") ||
      (localStorage.getItem("selectedCountry") &&
        Object.keys(JSON.parse(localStorage.getItem("selectedCountry")))
          .length == 0)
    ) {
      this.apiService.getAllCountries().subscribe((res: any) => {
        if (res.data && Array.isArray(res.data) && res.data.length > 0) {
          this.countries = res.data;
          this.getDefaultCountry(res.data);
          localStorage.setItem("countries", JSON.stringify(res.data));
        }
      });
    }
  }

  private getDefaultCountry(countries) {
    let filteredCountry = countries.filter((x) => {
      return x.attributes.name == "India";
    });
    if (filteredCountry.length > 0) {
      let selectedCountry = filteredCountry[0];
      let mobile_length = selectedCountry.attributes.mobile_length.split("...");
      selectedCountry.attributes.min_mobile_digit = parseInt(mobile_length[0]);
      selectedCountry.attributes.max_mobile_digit = parseInt(mobile_length[1]) - 1;
      localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry));
      this.selectedCountry = selectedCountry;
      this.selectedCountryAvailable = true;
    }
  }

  public filterItem(searchTerm) {
    if (searchTerm && searchTerm.length > 0) {
      this.filteredCountries = Object.assign([], this.countries).filter(
        (item) =>
          item.attributes.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >
          -1
      );
    }
  }

  public setCountry(country) {
    this.selectedCountry = country;
  }

  public acceptTermsAndPolicy($event) {
    this.createWithUserForm.controls.privacyPolicy_and_terms.setValue($event.target.checked);
    this.enableContinueButton();
  }

  public clearAll() {
    // this.cardNum = null;
    // this.fetchingCard = false;
    // this.showNoCards = false;
    this.otpVerifying = false;
    this.searchMobileLoader = false;
    // this.binError = false;

    // this.cardVerified = false;
    // this.binInvalid = false;
    // this.ifExistingUser = true;
    // this.showNoCardsMessage = '';
    this.errorRequestingOtpMsg = "";
    this.createWithUserForm.patchValue({ mobile: "", email: "" });
    this.mobNum = undefined;
    this.otpInp = undefined;
  }

  public removeLetter(str) {
    if (parseInt(str) < 1) {
      str = "";
      this.createWithUserForm.controls["mobile"].setValue("");
    }
    this.createWithUserForm.controls["mobile"].setValue(str.replace(/\D/g, ""));
  }

  public enableContinueButton() {
    if (
      this.createWithUserForm.value.mobile.length >=
        this.selectedCountry.attributes.min_mobile_digit &&
      this.createWithUserForm.value.mobile.length <=
        this.selectedCountry.attributes.max_mobile_digit &&
      this.createWithUserForm.value.privacyPolicy_and_terms
    ) {
      this.mobileInputBtnActive = true;
    } else {
      this.mobileInputBtnActive = false;
    }
  }

  public userSearchByMobile() {
    if (this.createWithUserForm.valid) {
      this.showMobileInvalidMessage =
        !this.createWithUserForm.controls.mobile.valid;
      this.showEmailInvalidMessage =
        !this.createWithUserForm.controls.email.valid;
      this.isMobileNumberSearching = true;
      const params = {
        mobile: this.createWithUserForm.value.mobile,
        email: this.createWithUserForm.value.email
      }
      this.apiService
        .patchUserDetails(params)
        .subscribe(
          (response: any) => {
            if (response && response.otp_success) {
              if(localStorage.getItem("userDetails")) {
                let userDetails = JSON.parse(localStorage.getItem("userDetails"));
                this.userId = userDetails.id;
                this.submittedOtpForm = false;
                this.countDown(60, () => {
                  this.timer = "complete";
                });
                this.stepToShow = "otp";
                setTimeout(() => {
                  document.getElementById("otp-field").focus();
                  this.otpInp = "";
                }, 500);
                this.isMobileNumberSearching = false;
              }
            }
          },
          (err) => {
            if (err.status && err.status == 422) {
              this.errorRequestingOtp = true;
              if (
                err.error &&
                err.error.errors &&
                err.error.errors[0] == "Locked due to max wrong OTP attempts"
              ) {
                this.errorRequestingOtpMsg = `<div class = "pad-top-3">Too many incorrect attempts. Please try again after ${this.checkTime(
                  err.error.user_unlock_at
                )} mins.</div>`;
              } else {
                this.errorRequestingOtpMsg = `<div class = "pad-top-3">Too many incorrect attempts. Please try again after ${this.checkTime(
                  err.error.next_otp_at
                )} mins.</div>`;
              }
            } else if (err.status && err.status == 429) {
              this.errorRequestingOtp = true;
              this.errorRequestingOtpMsg = "Please retry after some time.";
            }
            this.errorRequestingOtp = true;
            this.isMobileNumberSearching = false;
          }
        );
    } else {
      this.showMobileInvalidMessage =
        !this.createWithUserForm.controls.mobile.valid;
      this.showEmailInvalidMessage =
        !this.createWithUserForm.controls.email.valid;
    }
  }

  public resetLoginAgain() {
    this.clearAll();
    // this.fetchingCard = false;
    this.searchMobileLoader = false;
    this.stepToShow = "mobile";
    this.mobNum = undefined;
    this.mobileInputBtnActive = false;
    this.errorRequestingOtp = false;
    this.errorRequestingOtpMsg = "";

    if (this.createWithUserForm.controls) {
      if (this.createWithUserForm.controls["mobile"]) {
        this.createWithUserForm.controls["mobile"].setValue("");
      }
      if (this.validateUserOtpForm.controls["otp"]) {
        this.validateUserOtpForm.controls["otp"].setValue("");
      }
    }
    this.otpInp = "";
    this.validOtp = false;
    clearInterval(this.int), function () {};
  }

  int: any;
  private countDown(i, callback) {
    clearInterval(this.int);
    callback = callback || function () {};
    this.int = setInterval(() => {
      this.timer = "started";
      this.viewTimer = i;
      const minutes: number = Math.floor(i / 60);
      this.timerToShow =
        minutes + ":" + (i - minutes * 60).toString().padStart(2, "0");

      i-- || (clearInterval(this.int), callback());
    }, 1000);
  }

  public resetCountDown() {
    clearInterval(this.int);
  }

  public clearOtp() {
    document.getElementById("otp-field").focus();
    this.otpInp = "";
  }

  private checkTime(userUnlockSeconds) {
    return dateDiff(userUnlockSeconds, new Date(), "minutes")
  }

  public resendOtp() {
    this.errorRequestingOtpMsg = "";
    this.submittedOtpForm = true;
    this.requestPoshvineOtp(false);
  }

  private requestPoshvineOtp(goTonextStep = true) {
    this.apiService
      .requestUserOtp(
        this.createWithUserForm.value.mobile,
        this.userId,
        this.selectedCountry.attributes.calling_codes[0]
      )
      .subscribe(
        (response) => {
          if (response) {
            this.errorRequestingOtp = false;
            this.submittedOtpForm = false;
            this.countDown(60, () => {
              this.timer = "complete";
            });
            if (goTonextStep) {
              this.stepToShow = "otp";
            }
            setTimeout(() => {
              document.getElementById("otp-field").focus();
              this.otpInp = "";
            }, 500);
          } else {
            // this.binValidationInitiated = false;
            this.errorRequestingOtpMsg = `<div>Too many incorrect attempts. </div> <div class = "pad-top-5">Please try again after 30 mins</div>`;
            this.errorRequestingOtp = true;
            this.submittedOtpForm = false;
          }
          this.isMobileNumberSearching = false;
        },
        (error) => {
          this.isMobileNumberSearching = false;
          // this.binValidationInitiated = false;
          this.submittedOtpForm = false;
          if (error.status == 422) {
            if (
              error.error &&
              error.error.errors &&
              error.error.errors[0] == "Locked due to max wrong OTP attempts"
            ) {
              this.errorRequestingOtpMsg = `<div class = "pad-top-3">Too many incorrect attempts. Please try again after ${this.checkTime(
                error.error.user_unlock_at
              ) || 'few'} mins.</div>`;
            } else {
              this.errorRequestingOtpMsg = `<div class = "pad-top-3">Too many incorrect attempts. Please try again after ${this.checkTime(
                error.error.next_otp_at
              ) || 'few'} mins.</div>`;
            }
            this.errorRequestingOtp = true;
          } else {
            this.toastr.error("Something went wrong. Please try again!");
          }
        }
      );
  }

  private getUserCards() {
    this.loadingCards = true;
    this.apiService.getCards().subscribe((response: any) => {
      if (
        response.data &&
        Array.isArray(response.data) &&
        response.data.length > 0
      ) {
        this.userCards = response.data;
        this.showAddCardComponent = false;
      } else {
        localStorage.setItem("showAddCardComponent", "true");
        this.showAddCardComponent = true;
      }
      this.invalidOtp = true;

      //Need to keep user in loading state until we show bin screen
      this.validOtp = false;
      this.otpVerifying = false;
      this.loadingCards = false;
      this.stepToShow = "card";
      setTimeout(() => {
        this.firstFocusable.nativeElement.focus();
      }, 500);
    });
  }

  public openTermsAndConditions() {
    this.dialog.open(TermsAndConditionsComponent, {
      panelClass: ['custom-modal', 'footer-modals'],
      data: {
        showCloseButton: true
      }
    });
  }

  public openPrivacyPolicy() {
    this.dialog.open(PrivacyPolicyComponent, {
      panelClass: ['custom-modal', 'footer-modals'],
      data: {
        showCloseButton: true
      }
    });
  }

  public validateUserOtp() {
    if (this.otpInp && this.otpInp.length == 6) {
      this.otpVerifying = true;
      this.apiService.validateUserOtp(this.userId, this.otpInp).subscribe(
        (response) => {
          if (response && response.success && response.token) {
            let currentUser = {
              userId: this.userId,
              token: response.token,
            };
            let userDetails = JSON.parse(localStorage.getItem("userDetails"));
            if(this.createWithUserForm.controls.mobile.dirty && this.createWithUserForm.controls.mobile.value) {
              userDetails.mobile = this.createWithUserForm.controls.mobile.value;
            }
            if(this.createWithUserForm.controls.email.dirty && this.createWithUserForm.controls.email.value) {
              userDetails.email = this.createWithUserForm.controls.email.value;
            }
            userDetails.status = "activated"; //updating the localstorage after otp verified instead of making another request to update the status in localStorage
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            localStorage.setItem("currentUser", JSON.stringify(currentUser));
            localStorage.setItem("cardVerified", "false");
            this.matDialogRef.close(true);
            // this.getUserCards();
          } else {
            this.otpVerifying = false;
            this.errorRequestingOtp = true;
            if (response.wrong_attempts_left == 0) {
              this.hideResend = true;
              let resendWaitTime = this.checkTime(response.user_unlock_at) || 'few';
              this.errorRequestingOtpMsg = `<div class = "pad-top-3">Too many incorrect attempts. Please try again after ${resendWaitTime} mins.</div>`;
            } else {
              this.errorRequestingOtpMsg = `<div class = "pad-top-3">Incorrect otp. Please try again.</div> <div class = "pad-top-3">${
                response.wrong_attempts_left
              } attempts${
                response.wrong_attempts_left == 1 ? "" : "s"
              } left.</div>`;
            }
          }
        },
        (err) => {
          this.otpVerifying = false;
          this.errorRequestingOtp = true;
          if (err.status == 404) {
            this.errorRequestingOtpMsg = "Otp expired";
            this.stepToShow = "mobile";
            this.clearOtp();
            setTimeout(() => {
              this.errorRequestingOtp = false;
              this.errorRequestingOtpMsg = "";
            }, 3000);
          } else {
            this.errorRequestingOtpMsg =
              "Something went wrong! Please try again later";
          }
        }
      );
    }
  }
}
