import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { ZrplService } from 'src/app/shared/services/zrpl/zrpl.service';

@Component({
  selector: 'app-purchase-confirmation',
  templateUrl: './purchase-confirmation.component.html',
  styleUrls: ['./purchase-confirmation.component.scss']
})
export class PurchaseConfirmationComponent {

  public statusMessage: string = "";
  public statusHeader: string = "";
  public statusDescription: string = "";
  private bookingId: string = "";
  public isLoadingBookingDetails: boolean = true;
  public bookingItems: Array<{denomination: number, name: string, sku: string, qty: number, special_price: number, surcharge_fee: number, img_path: string}> = [];
  public userPack: {name: string, mobile: string, email: string};
  public icon: string = '';

  constructor(
    private zrplService: ZrplService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    public globalConstants: GlobalConstants
  ) {
    if(this.route.snapshot.queryParams.booking_id || this.route.snapshot.params.booking_id) {
      this.bookingId = this.route.snapshot.queryParams.booking_id || this.route.snapshot.params.booking_id;
      this.getBookingDetails();
    }
  }

  private initStatusVariables(success: boolean = false) {
    if(success) {
      this.statusHeader = "Thank You";
      this.statusDescription = "You have successfully made a purchase. ";
      this.statusMessage = "We'll update the status of your purchase via email.";
      this.icon = 'assets/icons/bell.svg'
    } else {
      this.statusHeader = "You booking has failed!";
      this.statusDescription = "Unfortunately! We were unable to process your booking . You can try booking again. ";
      this.statusMessage = "Booking Failed! ";
      this.icon = 'assets/icons/cross_failed.svg'
    }
  }

  private getBookingDetails() {
    this.zrplService.getPurchaseDetails(this.bookingId).subscribe({
      next: (response: any) => {
        this.bookingItems = response?.booking?.data?.attributes?.booking_items;
        this.userPack = response?.booking?.data?.attributes?.user_pack;
        this.isLoadingBookingDetails = false;
        this.initStatusVariables(response.status !== 'error');
      },
      error: (error) => {
        this.isLoadingBookingDetails = false;
        this.toastrService.error("Something went wrong, please try again later");
      }
    })
  }

}
