<div class="d-flex flex-wrap header-container darkBg">
    <div class="d-flex flex-column h100" [ngClass]="{'justify-content-end': !isMobile, 'justify-content-center': isMobile}">
        <div class="back-button d-flex flex-row pointer" (click)="handleBackToPortal()">
            <span class="icon-left-arrow mar-right-10"></span>
            <div class="fnt-14 back-text ml-2">Back to {{globalConstants?.isFounderCard ? 'Founder Rewards' : 'Card Portal'}}</div>
        </div>
        <div class="d-flex flex-row tab-styling mt-2 relative overflow-auto hide-scrollbar" *ngIf="!isMobile" [ngClass]="{'founderscard': globalConstants.isFounderCard}">
            <div class="tab-name pointer text-nowrap explore-rewards" [ngClass]="{'active': this.getLocationPath() == '/' || this.router.url.includes('offer-details')}" routerLink="/" *ngIf="!globalConstants.isFounderCard">
                Explore Rewards
            </div>
            <div class="tab-name pointer text-nowrap explore-gift-card" [ngClass]="{'active': this.router.url.includes('giftcards')}" routerLink="/giftcards" *ngIf="!globalConstants.isFounderCard">
                Explore Gift Cards
            </div>
            <ng-container *ngIf="environment?.production && parsedJWT?.card_id === '56b95502-95da-4feb-8e18-fa786f9a33cf' && parsedJWT?.user_id === 'd19b4f6d-b11c-4108-87d6-860e32c02a97'">
              <div class="tab-name pointer text-nowrap explore-gift-card relative" [ngClass]="{'active': this.router.url.split('?')[0].includes('electronics')}" routerLink="/electronics" *ngIf="!globalConstants.isFounderCard">
                Explore Electronics
                <div class="absolute new-flag flag-container">
                    <div class="text fnt-10 f700 text-uppercase">
                        New
                    </div>
                </div>
            </div>
            </ng-container>
            <ng-container *ngIf="!environment?.production">
              <div class="tab-name pointer text-nowrap explore-gift-card relative" [ngClass]="{'active': this.router.url.split('?')[0].includes('electronics')}" routerLink="/electronics" *ngIf="!globalConstants.isFounderCard">
                Explore Electronics
                <div class="absolute new-flag flag-container">
                    <div class="text fnt-10 f700 text-uppercase">
                        New
                    </div>
                </div>
            </div>
            </ng-container>

            <div class="tab-name pointer text-nowrap claimed-rewards" routerLink="/claimed-rewards" [ngClass]="{'active': this.router.url?.includes('/claimed-rewards')}">
                {{
                    globalConstants.isFounderCard ? 'Claimed Rewards' : 'Claim/Purchase History'
                }}
            </div>
            <div class="tab-name pointer text-nowrap milestone-rewards" routerLink="/milestone-rewards" [ngClass]="{'active': this.router.url?.includes('/milestone-rewards')}" *ngIf="!globalConstants.isFounderCard">
                Milestone Rewards
            </div>
            <div class="tab-name pointer text-nowrap profile" routerLink="/profile" [ngClass]="{'active': this.router.url?.includes('/profile')}">
                Profile
                <img src="../../../../../assets/icons/warning.svg" *ngIf="!isEmailVerified" class="warning-icon mar-left-5" />
            </div>
        </div>
    </div>
    <div class="card-container d-flex flex-row">
        <img src="../../../../../assets/icons/card.svg" class="card-img mar-right-10 bg-transparent border-0" />
        <div class="d-flex flex-column justify-content-center">
            <div class="fnt-25 f700 card-name">{{ globalConstants.isFounderCard ? 'Founder’s Edition' : 'Corporate Cards'}}</div>
            <img src="../../../../../assets/images/poweredBy.svg" class="powered-by-img mar-top-5 align-self-end" />
        </div>
    </div>
    <!-- <div class="btn-group tab-name fnt-16" dropdown #dropdown="bs-dropdown" [autoClose]="true" *ngIf="isAuthenticated && !isMobile">
        <div dropdownToggle class="d-flex text-nowrap fnt-16 flex-row m-auto dropdown-toggle relative h100 pad-top-10 pad-bot-10 pad-right-15 pad-left-15 cfff align-items-center pointer after-content-none" aria-controls="dropdown-basic">
            <div class="profile-icon">
            </div>
            <span class="icon-down-open-mini absolute"></span>
        </div>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
           role="menu" aria-labelledby="button-basic" (click)="preventClose($event)">
            <li role="menuitem">
                <a class="dropdown-item" (click)="redirectTo($event, '/profile'); dropdown.hide();">
                    Personal Info
                </a>
            </li>
            <li role="menuitem">
                <a class="dropdown-item" (click)="redirectTo($event, '/claimed-rewards'); dropdown.hide();">
                    Claimed Rewards
                </a>
            </li>
        </ul>
     </div> -->
</div>
