<div class="relative d-flex flex-column">
    <div class="modal-header" *ngIf="deviceService.isMobile">
        <img src="../../../../../../assets/icons/close.svg" class="close-icon mt-auto mb-auto pointer" (click)="matDialogRef.close()" />
    </div>
    <tabset #staticTabs class="gift-card-modal" *ngIf="!isLoadingGiftCardDetails && giftcardDetails">
        <tab id="select_value" [active]="data.activeTab == giftCardService.MODAL_TABS.BUY_GIFTCARDS" (selectTab)="onClickOfTab($event)">
            <ng-template tabHeading>
              <div class="custom-tab w100 d-flex">
                <div class="m-auto text-nowrap pad-top-0 pad-bot-0 pad-left-8 pad-right-8">
                    Select Value
                </div>
              </div>
            </ng-template>
            <div class="d-flex flex-column h-100">
                <div class="d-flex flex-column m-0 pad-bot-16 border-0 pad-top-16 pad-left-16 pad-right-16">
                    <div class="d-flex flex-row">
                        <img [src]="giftcardDetails.attributes?.merchant?.logo" class="merchant_logo mar-right-10" />
                        <div class="d-flex flex-column align-self-center">
                            <div class="fnt-16 f400">
                                {{giftcardDetails.attributes?.merchant?.name}}
                            </div>
                            <div class="d-flex flex-row valid_till fnt-12">
                                <div class="cBBBDC8 mar-right-5 f400">
                                    Valid Till: 
                                </div>
                                <div class="cFDFDFD f500">
                                    {{validTill()}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap mar-top-18 mar-bot-18">
                        <div class="fnt-14 f500 cFDFDFD">
                            Redeem - 
                        </div>
                        <div class="d-flex align-items-center mar-left-10" *ngFor="let redm_type of giftcardDetails.attributes?.redemption_type; let idx=index;">
                            <img src="../../../../../../assets/icons/blue-tick.svg" class="blue_tick_icon" />
                            <div class="text-capitalize fnt-12 f500 cBBBDC8 mar-left-5">
                                {{redm_type}}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column value-selection">
                        <div class="denomination-sec"
                            *ngIf="giftcardDetails?.attributes?.price_type == 'slab' && giftcardDetails.attributes.denominations && giftcardDetails.attributes.denominations.length > 0">
                            <div class="denomination-head flex f500 fnt-15 row pad-top-14 pad-bot-14 pad-left-15 pad-right-15 m-0">
                                <div class="cfff col-7 pad-left-0">Pricing</div>
                                <div class="cfff flex justify-center col-5">Quantity</div>
                            </div>
                            <div class="denomination-body pad-top-10 pad-bot-10 pad-left-16 pad-right-16">
                                <div class="row flex m-0 pad-top-10 pad-bot-10 custom-border top" *ngFor="let giftcardNum of giftcardDetails.attributes.denominations; let i = index">
                                    <div class="col-7 flex align-center fnt-13 pad-left-0">
                                        <span><span class="rupee-symbol">₹</span> {{giftcardNum | indianCurrency}}</span>
                                    </div>
                                    <div class="col-5 flex align-center justify-center">
                                        <div class="inc-dec-btn flex align-center">
                                            <div class="inc bg-fff pointer" (click)="decrement(i)" [ngClass]="{'active' : valueNum[i]}">
                                                -
                                            </div>
                                            <div class="item_quantity pad-left-10 pad-bot-4 pad-top-4 pad-right-10 flex align-center justify-center mar-left-5 mar-right-5">
                                                {{valueNum[i] ? valueNum[i] : 0}}</div>
                                            <div class="dec bg-fff flex align-center justify-center pointer active" (click)="increment(i)">+</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="denomination-sec"
                            *ngIf="giftcardDetails?.attributes?.price_type && giftcardDetails?.attributes.price_type == 'range'">
                            <div class="denomination-head flex f500 fnt-15 row pad-top-14 pad-bot-14 pad-left-15 pad-right-15 m-0">
                                <div class="cfff col-7 pad-left-0">Gift Card Value</div>
                                <div class="cfff flex justify-center col-5">Quantity</div>
                            </div>
                            <div class="denomination-body pad-top-10 pad-bot-10 pad-left-16 pad-right-16">
                                <div class="denomination-row slide-row pad-top-10 pad-bot-5">
                                    <div class="d-flex flex-row align-items-center pad-bot-10">
                                        <svg width="13" height="13" viewBox="0 0 23 23" fill="#F9B154" xmlns="http://www.w3.org/2000/svg" class="mar-right-3">
                                            <path d="M11.3469 16.7165C10.7946 16.7166 10.3468 16.269 10.3467 15.7167L10.3461 11.7167C10.346 11.1644 10.7936 10.7166 11.3459 10.7165C11.8982 10.7165 12.346 11.1641 12.3461 11.7164L12.3467 15.7164C12.3468 16.2687 11.8991 16.7165 11.3469 16.7165Z"/>
                                            <path d="M11.3453 6.71654C10.793 6.71663 10.3454 7.16441 10.3455 7.7167C10.3455 8.26898 10.7933 8.71663 11.3456 8.71654C11.8979 8.71646 12.3455 8.26867 12.3455 7.71639C12.3454 7.16411 11.8976 6.71646 11.3453 6.71654Z"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3461 11.7148C22.347 17.79 17.4229 22.7156 11.3478 22.7165C5.27265 22.7175 0.347019 17.7934 0.34608 11.7182C0.345142 5.64311 5.26925 0.717483 11.3444 0.716544C17.4195 0.715606 22.3451 5.63971 22.3461 11.7148ZM11.3475 20.7165C16.318 20.7158 20.3468 16.6857 20.3461 11.7152C20.3453 6.74459 16.3153 2.71578 11.3447 2.71654C6.37413 2.71731 2.34531 6.74737 2.34608 11.7179C2.34685 16.6885 6.37691 20.7173 11.3475 20.7165Z"/>
                                        </svg>
                                        <div class="c8F93A4 fnt-12 f400">
                                            Min: {{giftCardService.getMinValue(giftcardDetails)}} & Max: {{giftCardService.getMaxValue(giftcardDetails)}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex m-0 pad-top-10 pad-bot-10 custom-border top" *ngFor="let rangeItem of giftRange; let i = index">
                                    <div class="denomination-value col-7 flex align-center fnt-13 pad-left-0">
                                        <div class="input-box w100 focus-active-border dark-input rounded border border-transparent pad-left-10 pad-right-10 d-flex align-items-center">
                                            <span class="rupee-symbol">₹</span>
                                            <input 
                                                class="form-control border-0 bg-transparent fnt-13 pad-6 cfff" 
                                                type="text" 
                                                [maxlength]="formatNumberToCurrency(giftcardDetails.attributes.max_price).length" 
                                                appNumbersOnly
                                                [isCurrency]="true" 
                                                placeholder="Enter value"
                                                inputmode="numeric"
                                                [ngModel]="rangeItem.denomination | indianCurrency : true" onkeypress="return isNumberKey(event)"
                                                (ngModelChange)="rangeItem.denomination=currencyInputChanged($event)" 
                                                (input)="resetErrorMsg(); checkDenomination(rangeItem, i)" 
                                                (keydown.backspace)="checkDenomination(rangeItem, i)"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-5 flex align-center justify-content-around">
                                        <div class="inc-dec-btn flex align-center">
                                            <div class="inc bg-fff pointer" [ngClass]="{'active' : rangeItem.quantity > 0}" (click)="decrementRange(i)">
                                                -
                                            </div>
                                            <div 
                                                class="item_quantity flex align-center justify-center pad-left-10 pad-bot-4 pad-top-4 pad-right-10 mar-left-5 mar-right-5">
                                                {{rangeItem.quantity}}</div>
                                            <div class="dec bg-fff flex align-center justify-center pointer active" (click)="incrementRange(i)">+
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="denomination-row flex align-center pointer custom-border top pad-top-16 pad-bot-10" (click)="createGiftDenomination()">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="#5CA2F7" xmlns="http://www.w3.org/2000/svg" class="mar-right-5">
                                        <path d="M11.9498 2.05025C10.6276 0.728134 8.86974 0 7 0C5.13026 0 3.37236 0.728134 2.05025 2.05025C0.728134 3.37236 0 5.13026 0 7C0 8.86974 0.728134 10.6276 2.05025 11.9498C3.37236 13.2719 5.13026 14 7 14C8.86974 14 10.6276 13.2719 11.9498 11.9498C13.2719 10.6276 14 8.86974 14 7C14 5.13026 13.2719 3.37236 11.9498 2.05025ZM7 12.9062C3.74332 12.9062 1.09375 10.2567 1.09375 7C1.09375 3.74332 3.74332 1.09375 7 1.09375C10.2567 1.09375 12.9062 3.74332 12.9062 7C12.9062 10.2567 10.2567 12.9062 7 12.9062ZM7.54688 6.45259H10.0078V7.54634H7.54688V10.0073H6.45312V7.54634H3.99219V6.45259H6.45312V3.99165H7.54688V6.45259Z"/>
                                    </svg>
                                    <span class="fnt-12 f500 blue-text">Add one more value</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column cF9B154 fnt-12">
                        <div class="mar-top-10" *ngIf="addDenominationError">
                            {{addDenominationError}}
                        </div>
                        <div class="mar-top-10" *ngIf="rangeError?.type == 'amount'">
                            {{rangeError?.message+" "+rangeError?.amount}} 
                        </div>
                        <div class="mar-top-10" *ngIf="rangeError?.type == 'text'">
                            {{rangeError?.message}}
                        </div>
                        <div class="mar-top-10" *ngIf="slabError">
                            {{slabError}}
                        </div>
                        <div class="d-flex flex-row align-items-center mar-top-10" *ngIf="errorMessage">
                            <svg width="13" height="13" viewBox="0 0 23 23" fill="#FE6650" xmlns="http://www.w3.org/2000/svg" class="mar-right-3">
                                <path d="M11.3469 16.7165C10.7946 16.7166 10.3468 16.269 10.3467 15.7167L10.3461 11.7167C10.346 11.1644 10.7936 10.7166 11.3459 10.7165C11.8982 10.7165 12.346 11.1641 12.3461 11.7164L12.3467 15.7164C12.3468 16.2687 11.8991 16.7165 11.3469 16.7165Z"/>
                                <path d="M11.3453 6.71654C10.793 6.71663 10.3454 7.16441 10.3455 7.7167C10.3455 8.26898 10.7933 8.71663 11.3456 8.71654C11.8979 8.71646 12.3455 8.26867 12.3455 7.71639C12.3454 7.16411 11.8976 6.71646 11.3453 6.71654Z"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3461 11.7148C22.347 17.79 17.4229 22.7156 11.3478 22.7165C5.27265 22.7175 0.347019 17.7934 0.34608 11.7182C0.345142 5.64311 5.26925 0.717483 11.3444 0.716544C17.4195 0.715606 22.3451 5.63971 22.3461 11.7148ZM11.3475 20.7165C16.318 20.7158 20.3468 16.6857 20.3461 11.7152C20.3453 6.74459 16.3153 2.71578 11.3447 2.71654C6.37413 2.71731 2.34531 6.74737 2.34608 11.7179C2.34685 16.6885 6.37691 20.7173 11.3475 20.7165Z"/>
                            </svg>
                            <div class="fnt-12 f400 warning warning-text">
                                {{errorMessage}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="denomination-footer d-flex flex-row pad-top-13 pad-bot-13 pad-left-14 pad-right-14 align-items-center" style="margin-top: auto;">
                    <div class="d-flex flex-column">
                        <div class="fnt-12 c8F93A4 d-flex">{{totalQuantity || 0}} Gift {{totalQuantity > 1 ? 'cards' : 'card'}} :<div class="cfff mar-left-5">₹ {{(totalValue || 0) | indianCurrency}}</div></div>
                        <div class="d-flex flex-row mar-top-3 green-text" *ngIf="isDiscountedGc">Total Discount :<div class="mar-left-5">- ₹ {{(totalDiscount || 0) | indianCurrency}}</div></div>
                        <div class="d-flex flex-row c8F93A4 mar-top-3" *ngIf="isDiscountedGc">Total Payable Amount : <div class="cfff mar-left-5">₹ {{(totalAmount || 0) | indianCurrency}}</div></div>
                    </div>
                    <div appClickAnalytics analyticsLabel="rewards_dashboard.gift_card_interest" class="btn custom-button d-flex" [ngClass]="{'primary': totalAmount, 'disabled': !totalAmount}" style="margin-left: auto;" (click)="handleProceed()">
                        Proceed
                        <span class="page-loader loader" [ngClass]="{'disabled': buyingGiftcard}" *ngIf="buyingGiftcard"></span>
                    </div>
                </div>
            </div>
        </tab>
        <tab id="steps_to_redeem" [active]="data.activeTab == giftCardService.MODAL_TABS.STEPS_TO_REDEEM" (selectTab)="onClickOfTab($event)">
            <ng-template tabHeading>
              <div class="custom-tab w100 d-flex">
                <div class="m-auto text-nowrap pad-top-0 pad-bot-0 pad-left-8 pad-right-8">
                    Steps To Redeem
                </div>
              </div>
            </ng-template>
            <div class="d-flex flex-column m-0 pad-bot-16 border-0 pad-top-16 pad-left-16 pad-right-16">
                <div class="fnt-14 f400 cfff" [innerHTML]="giftcardDetails?.attributes?.redemption_steps">
                </div>
            </div>
        </tab>
        <tab id="terms" [active]="false" (selectTab)="onClickOfTab($event)">
            <ng-template tabHeading>
              <div class="custom-tab w100 d-flex">
                <div class="m-auto text-nowrap pad-top-0 pad-bot-0 pad-left-8 pad-right-8">
                    Terms & Conditions
                </div>
              </div>
            </ng-template>
            <div class="m-0 d-flex flex-column pad-bot-16 border-0 pad-top-16 pad-left-16 pad-right-16">
                <div [innerHTML]="giftcardDetails.attributes?.terms"></div>
            </div>
        </tab>
        <tab id="about" [active]="false" (selectTab)="onClickOfTab($event)">
            <ng-template tabHeading>
              <div class="custom-tab w100 d-flex">
                <div class="m-auto text-nowrap pad-top-0 pad-bot-0 pad-left-8 pad-right-8">
                    About The Brand
                </div>
              </div>
            </ng-template>
            <div class="d-flex flex-column m-0 pad-bot-16 border-0 pad-top-16 pad-left-16 pad-right-16 h-100">
                <div class="d-flex flex-row">
                    <img [src]="giftcardDetails.attributes?.merchant?.logo" class="merchant_logo mar-right-10" />
                    <div class="cFDFDFD fnt-16 f700 align-self-center">
                        {{giftcardDetails.attributes?.merchant?.name}}
                    </div>
                </div>
                <div class="fnt-14 f400 cfff mar-top-16 h-75" [innerHTML]="giftcardDetails?.attributes?.description">
                </div>
                <button class="btn custom-button primary mt-auto w-100">
                    <a [href]="giftcardDetails?.attributes?.merchant?.website" target="_blank" rel="noreferrer noopener">
                      Go to Website
                    </a>
                </button>
            </div>
        </tab>
    </tabset>
    <div class="justify-content-center d-flex" *ngIf="isLoadingGiftCardDetails">
        <span class="page-loader"></span>
    </div>
</div>