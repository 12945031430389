<div class="row m-0 custom-padding pad-xs-0 d-flex product-details-container min-height-100 pt-4" [ngClass]="{'h-100': isLoading && deviceService.isMobile, 'h-auto h-xs-auto': !(isLoading && deviceService.isMobile)}">
    <div class="col-md-3 col-xs-12 d-flex flex-column p-0 h-100" [ngStyle]="{background: globalConstants?.data?.colors?.detailsPageBg}" *ngIf="!isLoading">
        <div class="d-flex flex-row pad-20 pad-bot-10 pad-top-10">
            <span class="icon-left-arrow mt-auto mb-auto pad-top-2 mar-right-10 blue-text fnt-20 pointer" (click)="goBack()"></span>
            <div class="fnt-13 text-capitalize mt-auto mb-auto mar-left-10 text-truncate cfff">{{productDetails?.name}}</div>
        </div>
        <img [src]="productDetails?.image" class="merchant-banner" />
        <div class="fnt-12 pad-20 cfff pad-top-10 pad-bot-10 d-none d-md-block product-meta-title" [innerHtml]="productDetails?.meta_title" [ngClass]="{'showFull': metaDescriptionOpen}" id="productDesc"></div>
        <div class="blue-text flex flex-end cfff pad-20 pad-top-0" *ngIf="showExandCollapse">
              <span class="f500 fnt-12 pointer">
                <span class="show-more-less" (click)="metaDescriptionOpen = !metaDescriptionOpen">
                  <span *ngIf="!metaDescriptionOpen">Show More</span>
                  <span *ngIf="metaDescriptionOpen">Show Less</span>
                </span>
              </span>
        </div>
    </div>
    <div class="col-md-3 col-xs-12 d-flex flex-column p-0 h-100" *ngIf="isLoading">
        <ng-container *ngIf="!deviceService.isMobile">
            <div class="d-flex flex-row pad-20 pad-bot-10 pad-top-10" [ngStyle]="{'border': '1px solid #434F75'}">
                <span class="icon-left-arrow mt-auto mb-auto pad-top-2 blue-text fnt-20 pointer" (click)="goBack()"></span>
            </div>
            <ngx-skeleton-loader 
                count="1" 
                appearance="line"
                [theme]="{'height': '300px','width': '100%', background: globalConstants?.data?.colors?.detailsPageBg, 'border-radius': '0'}">
            </ngx-skeleton-loader>
        </ng-container>
        <div *ngIf="deviceService.isMobile" class="d-flex flex-column h-100">
            <div class="d-flex flex-row pad-20 pad-bot-10 pad-top-10">
                <span class="icon-left-arrow mt-auto mb-auto pad-top-2 blue-text fnt-20 pointer" (click)="goBack()"></span>
            </div>
            <div class="h-100 d-flex justify-content-center align-items-center">
                <div class="small-loader"></div>
            </div>
        </div>
    </div>
    <div class="col-md-9 col-xs-12 pad-xs-0 h-100" *ngIf="!isLoading">
        
        <div class="row m-0 offer-details pad-bot-16 border-0" [ngStyle]="{'background': globalConstants?.data?.colors?.detailsPageBg}">
            <div class="col-xs-12 col-md-9 d-flex flex-column pad-16">
                <div class="fnt-20 cfff f700 mar-bot-16">
                    {{productDetails?.name}}
                </div>
                <div class="fnt-14 f400 cfff text-break" *ngIf="productDetails?.shortDescription" [innerHtml]="productDetails?.shortDescription"></div>
                <div class="fnt-14 f400 cfff text-break" *ngIf="productDetails?.description" [innerHtml]="productDetails?.description"></div>
                <div class="d-flex mt-3 align-items-center cfff">
                    <div class="fnt-24 me-2" *ngIf="productDetails?.specialPrice">
                        ₹ {{productDetails?.specialPrice | indianCurrency}}
                    </div>
                    <div [ngClass]="{'c8F93A4 fnt-13': productDetails?.specialPrice, 'fnt-24': !productDetails?.specialPrice}" *ngIf="productDetails?.price">
                        <s *ngIf="productDetails?.specialPrice">₹ {{productDetails?.price | indianCurrency}}</s>
                        <ng-container *ngIf="!productDetails?.specialPrice">₹ {{productDetails?.price | indianCurrency}}</ng-container>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-3 pad-top-16 pad-right-16 d-none d-md-block">
                <div class="d-flex justify-content-end">
                    <button class="btn custom-button primary claim-button" (click)="buyProduct()">
                        Buy Now
                    </button>
                </div>
            </div>
        </div>       
    </div>
    <div class="col-md-9 col-xs-12 pad-xs-0 h-100" *ngIf="isLoading && !deviceService.isMobile">
        <ngx-skeleton-loader 
            count="1" 
            appearance="line"
            [theme]="{'height': '500px','width': '100%', background: globalConstants?.data?.colors?.detailsPageBg, 'border-radius': '0'}">
        </ngx-skeleton-loader>
    </div>
    <div class="claim-button-container position-sticky bottom-0 w-100 d-lg-none d-md-none d-block pad-10" *ngIf="!isLoading" [ngStyle]="{background: globalConstants?.data?.colors?.detailsPageBg}">
        <button class="btn custom-button primary w-100" (click)="buyProduct()">
            Buy Now
        </button>
    </div>
</div>