<div class="relative d-flex flex-column">
    <div class="d-flex flex-row">
        <div class="fnt-16 white-text f600">
            Enter delivery address
        </div>
        <img src="../../../../assets/icons/close.svg" class="close-icon mt-auto mb-auto pointer"
            (click)="closeModal()" alt=""/>
    </div>
    <form [formGroup]="AddressForm" (ngSubmit)="handleSubmit()" class="mt-4">
        <div class="address-form overflow-scroll hide-scrollbar pb-3">
            <div class="row">
                <div class="col-md-6 col-12 d-flex flex-column pad-bot-10">
                    <div class="fnt-12 cD1D2DA">
                        First Name
                    </div>
                    <div class="input-container mar-top-5">
                        <input formControlName="first_name"
                            class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent" type="text"
                            placeholder="First Name" required />
                    </div>
                    <div *ngIf="AddressForm?.controls?.first_name?.errors?.['required'] && clickedSubmit"
                        class="warning warning-text fnt-12">
                        First Name is required.
                    </div>
                </div>
                <div class="col-md-6 col-12 d-flex flex-column pad-bot-10">
                    <div class="fnt-12 cD1D2DA">
                        Last Name
                    </div>
                    <div class="input-container mar-top-5">
                        <input formControlName="last_name"
                            class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent" type="text"
                            placeholder="Last Name" required />
                    </div>
                    <div *ngIf="AddressForm?.controls?.last_name.errors?.['required'] && clickedSubmit"
                        class="warning warning-text fnt-12">
                        Last Name is required.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex flex-column pad-bot-10">
                    <div class="fnt-12 cD1D2DA">
                        Mobile Number
                    </div>
                    <div class="input-container mar-top-5">
                        <input formControlName="mobile"
                            class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent" type="number"
                            placeholder="Mobile Number" required />
                    </div>
                    <div *ngIf="AddressForm?.controls?.mobile.errors?.['required'] && clickedSubmit"
                        class="warning warning-text fnt-12">
                        Mobile is required.
                    </div>
                    <div *ngIf="(AddressForm?.controls?.mobile.errors?.['pattern'] && !AddressForm?.controls?.mobile?.valid) && clickedSubmit"
                        class="warning warning-text fnt-12">
                        Please enter valid mobile number
                    </div>
                </div>
                <div class="col-12 d-flex flex-column pad-bot-10">
                    <div class="fnt-12 cD1D2DA">
                        Email Address
                    </div>
                    <div class="input-container mar-top-5">
                        <input formControlName="email"
                            class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent" type="text"
                            placeholder="Email" required />
                    </div>
                    <div *ngIf="AddressForm?.controls?.email.errors?.['required'] && clickedSubmit"
                        class="warning warning-text fnt-12">
                        Email is required.
                    </div>
                    <div *ngIf="(AddressForm?.controls?.email.errors?.['pattern'] || !AddressForm?.controls?.email?.valid) && clickedSubmit && AddressForm?.value?.email"
                        class="warning warning-text fnt-12">
                        Please enter valid email
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12 d-flex flex-column pad-bot-10">
                    <div class="fnt-12 cD1D2DA d-flex align-items-center">
                        Pincode
                        <span *ngIf="loadingLocationDetails" class="ms-2 small-loader mini"></span>
                    </div>
                    <div class="input-container mar-top-5">
                        <input formControlName="pincode"
                            class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent" type="text"
                            placeholder="Pincode" (input)="debouncePincodeSearch($event)" required />
                    </div>
                    <div *ngIf="AddressForm?.controls?.pincode.errors?.['required'] && clickedSubmit"
                        class="warning warning-text fnt-12">
                        Pincode is required.
                    </div>
                    <div *ngIf="(AddressForm?.controls?.pincode.errors?.['pattern'] || !AddressForm?.controls?.pincode?.valid) && clickedSubmit && AddressForm?.value?.pincode"
                        class="warning warning-text fnt-12">
                        Please enter valid pincode
                    </div>
                    <div *ngIf="dataNotFound && AddressForm?.value?.pincode" class="warning warning-text fnt-12">
                        Data not found for entered pin
                    </div>
                </div>
                <div class="col-md-6 col-12 d-flex flex-column pad-bot-10">
                    <div class="fnt-12 cD1D2DA d-flex align-items-center">
                        Country
                        <span *ngIf="loadingLocationDetails" class="ms-2 small-loader mini"></span>
                    </div>
                    <div class="input-container mar-top-5">
                        <input formControlName="country"
                            class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent" type="text"
                            placeholder="Country" readonly required />
                    </div>
                    <div *ngIf="AddressForm?.controls?.country.errors?.['required'] && clickedSubmit"
                        class="warning warning-text fnt-12">
                        Country is required.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12 d-flex flex-column pad-bot-10">
                    <div class="fnt-12 cD1D2DA d-flex align-items-center">
                        City
                        <span *ngIf="loadingLocationDetails" class="ms-2 small-loader mini"></span>
                    </div>
                    <div class="input-container mar-top-5">
                        <input formControlName="city" class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent"
                            type="text" placeholder="City" readonly required />
                    </div>
                    <div *ngIf="AddressForm?.controls?.city.errors?.['required'] && clickedSubmit"
                        class="warning warning-text fnt-12">
                        City is required.
                    </div>
                </div>
                <div class="col-md-6 col-12 d-flex flex-column pad-bot-10">
                    <div class="fnt-12 cD1D2DA d-flex align-items-center">
                        Region
                        <span *ngIf="loadingLocationDetails" class="ms-2 small-loader mini"></span>
                    </div>
                    <div class="input-container mar-top-5">
                        <input formControlName="region"
                            class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent" type="text"
                            placeholder="Region" readonly required />
                    </div>
                    <div *ngIf="AddressForm?.controls?.region.errors?.['required'] && clickedSubmit"
                        class="warning warning-text fnt-12">
                        Region is required.
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex flex-column pad-bot-10">
                <div class="fnt-12 cD1D2DA d-flex">
                    Address Line 1
                </div>
                <div class="input-container mar-top-5">
                    <input formControlName="addressLine1"
                        class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent" type="text"
                        placeholder="House No, Building, Floor" required />
                </div>
                <div *ngIf="AddressForm?.controls?.addressLine1.errors?.['required'] && clickedSubmit"
                    class="warning warning-text fnt-12">
                    Address Line 1 is required.
                </div>
            </div>
            <div class="col-12 d-flex flex-column pad-bot-10">
                <div class="fnt-12 cD1D2DA d-flex">
                    Address Line 2
                </div>
                <div class="input-container mar-top-5">
                    <input formControlName="addressLine2"
                        class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent" type="text"
                        placeholder="street" required />
                </div>
                <div *ngIf="AddressForm?.controls?.addressLine2.errors?.['required'] && clickedSubmit"
                    class="warning warning-text fnt-12">
                    Address Line 2 is required.
                </div>
            </div>
        </div>
        <button class="btn btn-primary custom-button w-100 fnt-16 cfff" type="submit">
            Ship to this address
        </button>
    </form>
</div>