import * as dayjs from "dayjs";
import * as isBetween from 'dayjs/plugin/isBetween';
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import * as customParseFormatPlugin from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormatPlugin);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

export function isBefore(dateToBeCompared: string | Date, comparedWithDate: string | Date = null, format: string) {
    return dayjs(dateToBeCompared, format).isBefore(dayjs(comparedWithDate, format));
}


export function formatDate(date: string | Date, format: string) {
    return dayjs(date).format(format);
}

export function customParseFormat(date: string | Date, format: string) {
    console.log(dayjs(date, format), "asda");
    return dayjs(date, format).format();
}

export function dateDiff(date: string | Date, comparedWith: string | Date, measurement) {
    return dayjs(date).diff(comparedWith, measurement);
}
