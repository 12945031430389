<section class="footer-sec d-flex flex-column" [ngStyle]="{'background': globalConstants?.data.footer?.footer1Bg}">
    <div class="d-flex flex-wrap justify-content-between mar-top-20 mar-bot-20 custom-padding">
        <div class="d-flex flex-row w100-xs">
            <img src="../../../../../assets/logos/logo.svg" class="logo" />
            <div class="fnt-12 cfff f400 mar-left-17 align-self-center copyright-text d-block d-sm-none mar-top-1">© 2022</div>
            <div class="fnt-16 cfff f700 mar-left-17 align-self-center pci d-none d-md-block h-auto">{{ globalConstants.isFounderCard ? 'Founder’s Edition' : 'Corporate Cards'}}</div>
            <img src="../../../../../assets/icons/pci.svg" class="pci d-block d-sm-none" />
        </div>
        <div class="d-flex flex-row justify-content-between mar-top-xs-16 links">
            <div class="blue-text align-self-center pointer text-capitalize">
                <a href="/info/terms-and-conditions" target="_blank">
                    Terms & Conditions
                </a>
            </div>
            <div class="blue-text align-self-center pointer text-capitalize">
                <a href="/info/privacy-policy" target="_blank">
                    Privacy Policy
                </a>
            </div>
            <div class="blue-text align-self-center pointer text-capitalize">
                <a href="/info/customer-support" target="_blank">
                    Customer Support
                </a>
            </div>
            <img src="../../../../../assets/icons/pci.svg" class="pci" *ngIf="!isMobile" />
        </div>
    </div>
    <div class="footer-2 fnt-14 f400 custom-padding" *ngIf="!isMobile" [ngStyle]="{'background': globalConstants?.data.footer?.footer2Bg, 'background-color': globalConstants?.isFounderCard ? '#0C0C0C': ''}">
        <div class="fnt-10 mar-bot-5">
            Disclaimer :RazorpayX Business Rewards Portal is a platform for communication of offers extended by Merchants to the Customers of RazorpayX Corporate Card – powered by SBM’s. Razorpay is only communicating the offers extended by Merchants to its Customers and not selling/rendering any of these Products/Services. Razorpay is not responsible for Sale/Quality/Features of the Products/Services under the offers
        </div>
        © Razorpay 2022
    </div>
</section>