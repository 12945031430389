
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/common/services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(
        event => { },
        error => {
          if (error.status == 401) {
            localStorage.removeItem("userDetails");
            localStorage.removeItem("targetUrl");
            let currentUser = JSON.parse(localStorage.getItem("currentUser"));
            currentUser.token = '' ;
            currentUser.isTokenExpired = true;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.authenticationService.tokenExpired.emit(true);
            sessionStorage.clear();
          }
          if (error instanceof HttpErrorResponse) {
            return throwError(error);
          }
        }
      )
    );
  }
}
