import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElectronicsComponent } from './electronics.component';
import { RouterModule, Routes } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/components/shared/shared.module';
import { DetailsComponent } from './details/details.component';
import { ListingComponent } from './listing/listing.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CheckoutComponent } from './checkout/checkout.component';
import { AddressModalComponent } from './address-modal/address-modal.component';
import { PurchaseDetailsComponent } from './purchase-details/purchase-details.component';
import { PurchaseConfirmationComponent } from './purchase-confirmation/purchase-confirmation.component';

declare var Razorpay: any;
const routes: Routes = [
  {
    path: '',
    component: ElectronicsComponent,
  },
  {
    path: "details/:sku",
    component: DetailsComponent,
  },
  {
    path: 'listing/:categoryId',
    component: ListingComponent
  },
  {
    path: 'checkout',
    component: CheckoutComponent
  },
  {
    path: "checkout-message/:booking_id",
    component: PurchaseConfirmationComponent
  },
  {
    path: "booking-failed",
    component: PurchaseConfirmationComponent
  },
  {
    path: "electronics/order-details/:orderId",
    component: PurchaseDetailsComponent
  }
]

@NgModule({
  declarations: [
    ElectronicsComponent,
    DetailsComponent,
    ListingComponent,
    CheckoutComponent,
    AddressModalComponent,
    PurchaseDetailsComponent,
    PurchaseConfirmationComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    MatDialogModule,
    NgxSkeletonLoaderModule,
    SharedModule,
    InfiniteScrollModule
  ]
})
export class ElectronicsModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
        ngModule: ElectronicsModule,
        providers: [
            {
                provide: 'env', // you can also use InjectionToken
                useValue: environment
            },
            {
              provide: 'Razorpay',
              useValue: Razorpay
            }
        ]
    };
  }
}
