import { EventEmitter, HostListener, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  isMobile: boolean = false;
  deviceObserver: EventEmitter<boolean> = new EventEmitter<boolean>(this.isMobile);

  constructor() {
    this.onResize();
  }
  onResize() {
    if (window.innerWidth > 1024) {
      this.isMobile = false;
    }
    else if (window.innerWidth > 992) {
      this.isMobile = false;
    }
    else {
      this.isMobile = true;
    }
    this.deviceObserver.emit(this.isMobile)
  }

}
