<div class="min-230 position-relative">
    <div class="custom-padding d-flex flex-row hide-scrollbar justify-content-between pt-3 overflow-auto pb-0 tab-styling position-sticky top-0 secondary-dark" (scroll)="onScrollCategories($event)">
        <ng-container *ngIf="!isCategoriesLoading && inViewCategories?.length > 0">
            <div 
                *ngFor="let category of inViewCategories" 
                class="tab-name pointer f700 text-nowrap fnt-16 d-flex align-items-center"
                (click)="handleCategorySelect(category)"
                [ngClass]="{'active': this.selectedCategory?.id == category?.id}"
            >
                <!-- <img [src]="categoryIcons[category?.name]" class="category-icon mar-right-5" /> -->
                {{category?.attributes?.name}}
            </div>
            <div class="btn-group tab-name position-static fnt-16" *ngIf="inDropdownCategories.length > 0" dropdown>
                <div dropdownToggle type="button" class="d-flex text-nowrap fnt-16 flex-row m-auto relative h100" #dropdownRef (click)="handleDropdownToggle()">
                    <div class="fnt-16 text-center mar-bot-5 align-self-center">More Categories</div>
                    <span class="icon-angle-down fnt-16"></span>
                </div>
            </div>
        </ng-container>
        <div *ngIf="isCategoriesLoading" class="d-flex w-100">
            <ngx-skeleton-loader 
                count="5" 
                appearance="line"
                [theme]="{'height': '50px','width': '140px', background: globalConstants?.data?.colors?.detailsPageBg, 'border-radius': '0'}" 
                class="w-100 d-flex justify-content-between">
            </ngx-skeleton-loader>
        </div>
    </div>
    <ul class="dropdown-menu align-self-center" [ngClass]="{'show':showDropdown}" role="menu" #dropdownMenu>
        <li role="menuitem" *ngFor="let category of inDropdownCategories; let i = index">
            <a class="dropdown-item" (click)="handleCategoryFromDropdown(category, i)">
                {{category?.attributes?.name}}
            </a>
        </li>
    </ul>

    <div class="d-flex custom-padding darkBg pb-0 pt-4 align-items-center">
        <div class="d-inline-flex align-items-center" (click)="backToHomePage()">
            <span class="icon-left-arrow mt-auto mb-auto pad-top-6 me-2 blue-text fnt-20 pointer"></span>
            <div class="fnt-14 f700 blue-text pointer">Back to Homepage</div>
        </div>
    </div>

    <div class="darkBg product-list flex-wrap d-flex justify-content-start custom-padding pb-5 align-content-start pt-4 pad-bot-xs-20 row" infiniteScroll (scrolled)="onScroll()">
        <div class="col-2 d-flex flex-column filters">
            <div class="d-flex flex-row py-3 custom-border bottom">
                <div class="cFDFDFD fnt-18 text-capitalize">
                    Filters
                </div>
                <div class="ms-auto pointer blue-text fnt-16 f700" *ngIf="selectedFilters?.length" (click)="resetFilters()">
                    Reset
                </div>
            </div>
            <div *ngIf="isCategoryFiltersLoading">
                <ngx-skeleton-loader 
                    count="8" 
                    appearance="line"
                    [theme]="{'height': '50px','width': '100%', background: globalConstants?.data?.colors?.detailsPageBg, 'border-radius': '0'}" 
                    class="w-100 d-flex flex-column justify-content-between mt-3">
                </ngx-skeleton-loader>
            </div>
            <ng-container *ngIf="!isCategoryFiltersLoading">
                <div class="d-flex flex-column py-3 custom-border bottom" *ngFor="let categoryFilter of categoryFilters">
                    <label class="filter-display-name mb-2">
                        {{categoryFilter?.attr_label}}
                    </label>
                    <div class="d-flex flex-column">
                        <div *ngFor="let filterItem of categoryFilter?.values" class="d-flex justify-content-between my-1">
                            <label class="filter-label">{{filterItem?.display}}</label>
                            <input type="checkbox" (change)="toggleFilter(categoryFilter?.attr_code, filterItem?.value)" [checked]="isFilterChecked(categoryFilter?.attr_code, filterItem?.value)" class="filter-checkbox" />
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="mt-2 d-flex flex-wrap w-100 col row mx-0 p-0">
            <ng-container *ngIf="!isProductsLoading || categoryBasedProducts?.length > 0">
                <div *ngFor="let product of categoryBasedProducts" class="pointer mb-4 mar-left-xs-auto mar-right-xs-auto justify-content-center d-flex col col-sm-5 col-md-5 col-lg-5 col-xl-3">
                    <app-product-card class="w-100" [isListingPage]="true" [product]="product"></app-product-card>
                </div>
            </ng-container>
            <ng-container *ngIf="isCategoriesLoading || isProductsLoading">
                <app-product-card *ngFor="let i of [0,1]" [isLoading]="true" [noOfCards]="4" class="mb-3 w-100" [loaderClasses]="'justify-content-around d-flex flex-wrap'"></app-product-card>
            </ng-container>
        </div>
    </div>
</div>