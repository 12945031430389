<div [ngClass]="{'overflow-hidden hight-without-header': !isAuthenticated}" class="darkBg min-height-100">

    <section class="search-rewards-container custom-padding" *ngIf="!globalConstants.isFounderCard">
        <div class="d-flex flex-row height-xs-80px mar-top-xs-16">
            <div class="fnt-20 cfff search-rewards-heading align-self-center">
                Get the best deal across top brands- buy using your Razorpay corporate card to maximise your savings
            </div>
        </div>
        <div class="row mar-top-xs-16 pad-bot-xs-36">
            <div class="d-flex flex-wrap w-100">
                <div class="w-50 width-xs-100 d-flex">
                  <div class="btn-group tab-name d-block w-100" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                    <div dropdownToggle class="d-flex text-nowrap fnt-14 f500 flex-row m-auto dropdown-toggle relative h100 blue-text align-items-center pointer w-100 search-rewards" aria-controls="search-dropdown">
                      <div class="d-flex flex-row custom-border bottom focus-visible-border pad-10 secondary-dark cfff width-xs-100 shadow-none w-100 pad-top-0 pad-bot-0">
                        <span class="icon-search blue-text align-self-center custom-button disabled bg-transparent pad-top-3"></span>
                        <input type="text" placeholder="Search by product name here..." (input)="debouncedProductSearch($event.target.value); dropdown.show()" class="border-0 focus-visible-border pad-5 bg-transparent cfff width-xs-100 shadow-none w-100 pad-let-0 height-36" [(ngModel)]="searchInput"/>
                        <img src="../../../assets/icons/close.svg" *ngIf="searchInput.length" class="pointer w-10px" (click)="preventClose($event);clearSearchText();" />
                      </div>
                    </div>
                    <ul id="search-dropdown" *dropdownMenu class="dropdown-menu w-100 fnt-12 p-2"
                      role="menu" aria-labelledby="button-basic" (click)="preventClose($event)">
                      <li role="menuitem" *ngFor="let item of itemList; let i = index">
                          <a class="dropdown-item d-flex" (click)="onSearchItemClick(item); dropdown.hide()">
                            <img [src]="item?.image" class="search-image" alt="" />
                            <div class="fnt-13 f700 cfff w-75 text-truncate ms-2">{{item.name}}</div>
                          </a>
                      </li>
                      <li role="menuitem" *ngIf="!searching && itemList && itemList.length == 0 && searchInput.length > 2">
                        <a class="dropdown-item">No Such Product Found</a>
                      </li>
                      <li role="menuitem" *ngIf="searching">
                        <a class="dropdown-item">Loading...</a>
                      </li>
                      <li role="menuitem" *ngIf="searchInput.length < 3 && (!itemList || itemList.length === 0)">
                        <a class="dropdown-item">Type at least 3 characters</a>
                      </li>
                    </ul>
                  </div>
                  <button class="custom-button primary border-0 pad-left-10 pad-right-10 f700 search-btn-bg d-flex">
                    <span class="icon-search blue-text fnt-18 mt-auto"></span>
                  </button>
                </div>
            </div>
        </div>
    </section>

    <section class="secondary-dark custom-padding d-flex mt-3 py-0" *ngIf="showCheckoutOffer">
        <div class="checkout-discount-container row mx-0 w-100">
            <div class="col"></div>
            <div class="d-flex flex-column my-auto col-12 col-md-7 col-lg-9">
                <div class="fnt-24 cfff discount-heading align-self-left mar-bot-6">
                    Avail extra 10% discount on overall spend of ₹5,999.
                </div>
                <div class="fnt-16 cfff align-self-left mar-bot-12">
                    Spend ₹5,999 and you can avail discount while check out on paying by your razorpay card.
                </div>
                <button class="custom-button primary border-0 pad-left-10 pad-right-10 f500 explore-products-cta d-flex">
                    Explore Products
                    <span class="icon-right cfff fnt-13 my-auto ms-2 pad-top-4"></span>
                </button>
            </div>
        </div>
    </section>

    <section class="mt-3 pt-4 secondary-dark relative" id="category-based-rewards" *ngIf="!globalConstants.isFounderCard">
        <div class="custom-padding d-flex flex-row">
          <img src="../../../assets/icons/tag.svg" class="bag_icon" />
          <div class="fnt-18 cfff mar-left-10 text-capitalize">
            Explore Categories
          </div>
        </div>
        <div class="d-flex overflow-hidden m-0 flex-wrap categories-list-container" [ngClass]="{'showMore': showMore}" [ngStyle]="{'height': showMore ? getCategoryListheight()  : ''}" #categoryList>
          <ng-container *ngIf="isCategoriesLoading">
            <ngx-skeleton-loader
                class="d-flex w-100 justify-content-between"
                count="10"
                appearance="line"
                [theme]="{'height': '64px','width': '64px', background: globalConstants?.data?.colors?.detailsPageBg, 'border-radius': '50px'}">
            </ngx-skeleton-loader>
          </ng-container>
          <ng-container *ngIf="!isCategoriesLoading">
            <div *ngFor="let category of categories" class="category-item mb-4">
              <app-category  [category]="category"></app-category>
            </div>
          </ng-container>
        </div>
        <div class="view-more-less absolute text-center w-100" (click)="showMore = !showMore">
            <svg width="189" height="32" viewBox="0 0 189 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.8313 13.1973C16.4216 5.29162 9.01184 0 0 0L188.761 0C185.922 1.12287 172.956 4.05967 163.991 13.6246C155.026 23.1896 156.182 32 138.359 32L102.013 31.9998C78.6068 31.9997 56.2739 31.9999 46.0605 32C33.2187 32.0002 32.8433 22.8125 23.8313 13.1973Z" fill="url(#paint0_linear_51662_100953)"/>
                <defs>
                    <linearGradient id="paint0_linear_51662_100953" x1="137.357" y1="-4.8" x2="137.357" y2="32" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#111739"/>
                        <stop offset="1" stop-color="#242A4A"/>
                    </linearGradient>
                </defs>
            </svg>
            <div class="text blue-text pointer h-100 w-100 top-0 p-1 text-center absolute">
                {{showMore ? 'View less' : 'View more'}}
            </div>
        </div>
    </section>

    <section class="secondary-dark custom-padding d-flex mt-5 py-0">
      <div class="d-flex flex-column w-100">
        <ng-container *ngFor="let catByProducts of categoryBasedProducts; let i = index">
          <div *ngIf="catByProducts?.setOfCards?.length" class="d-flex flex-column mt-4 position-relative">
            <div class="d-flex align-items-center">
              <div class="cfff fnt-18 f700">
                {{catByProducts?.category?.attributes?.name}}
              </div>
              <div [routerLink]="['listing/'+catByProducts?.category?.id]" class="text-capitalize blue-text ms-auto pointer">
                See all
              </div>
            </div>
            <ng-container *ngIf="!isCategoriesLoading && !catByProducts?.isLoading">
              <div [attr.id]="'carouselExampleControlsNoTouching'+i" class="carousel slide w-auto electronic-categories mt-4 mx-4" data-bs-touch="false">
                <div class="carousel-inner w-auto mx-4">
                  <ng-container *ngFor="let i of catByProducts?.setOfCards">
                      <div class="carousel-item" [ngClass]="{'active': i == 0}">
                        <div class="d-flex flex-wrap row">
                          <ng-container *ngFor="let product of catByProducts.products; let idx = index;">
                            <div *ngIf="idx>=i*4&&idx<(i*4)+4" class="col-3 d-flex justify-content-center">
                              <app-product-card [product]="product" class="w-100 d-flex justify-content-center"></app-product-card>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                  </ng-container>
                </div>
                <button class="carousel-control-prev" type="button" [attr.data-bs-target]="'#carouselExampleControlsNoTouching'+i" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" [attr.data-bs-target]="'#carouselExampleControlsNoTouching'+i" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
                <div class="carousel-indicators">
                  <ng-container *ngFor="let index of catByProducts?.setOfCards">
                    <button type="button" [attr.data-bs-target]="'#carouselExampleControlsNoTouching'+i" [attr.data-bs-slide-to]="index" [attr.aria-current]="index == 0" [ngClass]="{'active': index == 0}" [attr.aria-current]="index==0"></button>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <div *ngIf="isCategoriesLoading || catByProducts?.isLoading" class="mt-4">
              <app-product-card [loaderClasses]="'justify-content-between w-100'" [isLoading]="true" [noOfCards]="4"></app-product-card>
            </div>
            <hr class="custom-border bottom border mt-5" />
        </div>
        </ng-container>

          <div *ngIf="isCategoriesLoading" class="mt-4 d-flex flex-column">
            <app-product-card *ngFor="let i of [0,1]" [isLoading]="true" [noOfCards]="4" class="mt-4" [loaderClasses]="'justify-content-between w-100'"></app-product-card>
          </div>
      </div>
    </section>
</div>
