<div class="min-height position-relative">
    <div class="d-flex custom-padding pb-0 pt-4 align-items-center">
        <div class="d-inline-flex align-items-center pointer" routerLink="/electronics">
            <span class="icon-left-arrow mt-auto mb-auto pad-top-6 me-2 blue-text fnt-20 pointer"></span>
            <div class="fnt-14 f700 blue-text pointer">Back to Homepage</div>
        </div>
    </div>
    <div class="d-flex justify-content-start py-4 checkout-details-container m-auto flex-column rounded mt-4">
        <div class="d-flex align-items-start px-4 pb-4">
            
            <ngx-skeleton-loader 
                *ngIf="isLoadingBookingDetails"
                class="d-flex justify-content-between"
                count="1" 
                appearance="line"
                [theme]="{'height': '45px','width': '45px', background: 'rgba(21,102,241,.18)', 'border-radius': '50px'}">
            </ngx-skeleton-loader>
            <img [src]="icon" alt="" class="success-icon" *ngIf="!isLoadingBookingDetails" />
            
            <div class="d-flex flex-column ms-2">
                
                <div class="cfff fnt-24 f700" *ngIf="!isLoadingBookingDetails">
                    {{statusHeader}}
                </div>
                <ngx-skeleton-loader 
                    *ngIf="isLoadingBookingDetails"
                    class="d-flex w-100 justify-content-between"
                    count="1" 
                    appearance="line"
                    [theme]="{'height': '35px','width': '350px', background: 'rgba(21,102,241,.18)'}">
                </ngx-skeleton-loader>
                
                <div class="cBBBDC8 mt-2" *ngIf="!isLoadingBookingDetails">
                    {{statusDescription}}
                </div>
                <ngx-skeleton-loader 
                    *ngIf="isLoadingBookingDetails"
                    class="d-flex w-100 justify-content-between"
                    count="1" 
                    appearance="line"
                    [theme]="{'height': '21px','width': '500px', background: 'rgba(21,102,241,.18)'}">
                </ngx-skeleton-loader>
            </div>
        </div>
        <div class="note-section d-flex px-4 py-2 align-items-center">
            <ng-container *ngIf="!isLoadingBookingDetails">
                <img src="assets/icons/bell.svg" alt="" class="notification-icon" />
                <div class="notification-text fnt-12 f400 ms-2">
                    {{statusMessage}}
                </div>
            </ng-container>
            <ngx-skeleton-loader 
                *ngIf="isLoadingBookingDetails"
                class="d-flex w-100 justify-content-between"
                count="1" 
                appearance="line"
                [theme]="{'height': '18px','width': '81px', background: globalConstants?.data?.colors?.detailsPageBg}">
            </ngx-skeleton-loader>
            <div class="ms-auto align-items-center d-flex cfff">
                <ng-container *ngIf="!isLoadingBookingDetails">
                    <div class="border-right fnt-11 f400 pe-2 border-end">
                        {{userPack?.email}}
                    </div>
                    <div class="fnt-11 f400 ms-2">
                        {{userPack?.mobile}}
                    </div>
                </ng-container>
                <ngx-skeleton-loader 
                    *ngIf="isLoadingBookingDetails"
                    class="d-flex w-100 justify-content-between"
                    count="1" 
                    appearance="line"
                    [theme]="{'height': '18px','width': '81px', background: globalConstants?.data?.colors?.detailsPageBg}">
                </ngx-skeleton-loader>
            </div>
        </div>
        <div class="row px-4 mx-0 mt-3">
            <div class="col-8 ps-0">
                <div class="d-flex flex-column">
                    <ngx-skeleton-loader 
                        *ngIf="isLoadingBookingDetails"
                        class="d-flex w-100 justify-content-between"
                        count="1" 
                        appearance="line"
                        [theme]="{'height': '125px','width': '500px', background: 'rgba(21,102,241,.18)'}">
                    </ngx-skeleton-loader>
                    <ng-container *ngIf="!isLoadingBookingDetails">
                        <div class="product-card d-flex p-3" *ngFor="let booking of bookingItems">
                            <img [src]="booking?.img_path || 'assets/images/productPlaceholder.png'" class="product-image rounded" alt="" />
                            <div class="d-flex flex-column ms-2 justify-content-center">
                                <div class="cBBBDC8 fnt-16 f700">
                                    {{booking?.name}}
                                </div>
                                <div class="d-flex mt-2">
                                    <div class="d-flex flex-column fnt-12 400 me-4">
                                        <label class="cBBBDC8">
                                            Quantity
                                        </label>
                                        <div class="value cfff">
                                            {{booking?.qty}}
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column fnt-12 400 ms-4">
                                        <label class="cBBBDC8">
                                            Price
                                        </label>
                                        <div class="value cfff">
                                            ₹ {{ (booking?.special_price || booking?.denomination) + booking?.surcharge_fee | indianCurrency}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>                
            </div>
            <div class="col px-3 d-flex flex-column align-items-start justify-content-start" *ngIf="!isLoadingBookingDetails">
                <div class="fnt-13 f600 status-message">
                    You can also check the status under Order History
                </div>
                <button class="btn custom-button fnt-14 f700 primary mt-3" routerLink="/claimed-rewards" [queryParams]="{category: 'electronics'}">
                    View Order History
                </button>
            </div>
        </div>
    </div>
</div>