// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  corePathV2: "https://sandbox-api.poshvine.com/cs/v1",
  offerPathV2: "https://sandbox-api.poshvine.com/ps/v1",
  apiUrlBsV1: "https://sandbox-api.poshvine.com/bs/v1/",
  apiUrlPaV1: "https://sandbox-api.poshvine.com/pa/v1/",
  ecommerceBooking: "https://sandbox-api.poshvine.com/bs/v1/ecommerce_bookings/",
  pg_freePgCredits: "c64c125d-f42b-481f-ab14-e9292978f7af",
  razorpayx_cc_annualFee: "89145c89-33cb-47e4-94c1-4cb93df8f92b",
  razorpayx_cc_dedicatedRmRazorpay: "34d3a329-d31d-49a3-aea3-49aca24f6ac8",
  razorpayx_current_acc_freePayrollMonths: "2b6af88f-3bd4-4a8f-ba0f-668a175a37be",
  razorpayx_current_acc_freePayouts: "894fdcbb-1189-4e54-bd0a-845d64098fb4",
  businessRewardsTrackingId: "",
  foundersCardTrackingId: ""
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
