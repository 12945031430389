import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TabHeadingDirective as tabHeading } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';
import { DeviceService } from 'src/app/common/services/device.service';
import { GiftcardService } from 'src/app/common/services/giftcard.service';
import { formatDate } from 'src/app/common/utils/util';

declare var Razorpay: any;

@Component({
  selector: 'app-gift-card-modal',
  templateUrl: './gift-card-modal.component.html',
  styleUrls: ['./gift-card-modal.component.scss']
})
export class GiftCardModalComponent implements OnInit {

  giftcardDetails: any;
  isLoadingGiftCardDetails: boolean = false;
  valueNum: any = [];
  totalQuantity: number;
  totalValue: number;
  totalDiscount: number;
  totalAmount: number = 0;
  giftRange = [{
    quantity: 0,
    denomination: null
  }];
  rangeError: any;
  addDenominationError: string = "";
  slabError: string = "";
  buyingGiftcard: boolean = false;
  errorMessage: string = "";
  paymentPayload: any;
  paymentModalClosed: boolean = false;
  isDiscountedGc: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<GiftCardModalComponent>,
    private changeDetector: ChangeDetectorRef,
    public giftCardService: GiftcardService,
    private toastr: ToastrService,
    public deviceService: DeviceService,
  ) {
    this.ondismissCallback = this.ondismissCallback.bind(this);
  }

  ngOnInit()  {
    this.changeDetector.detectChanges();
    this.getGiftCardDetails();
  }

  getGiftCardDetails() {
    this.isLoadingGiftCardDetails = true;
    this.giftCardService.getGiftCardDetails(this.data.giftcard.id).then((response: any) => {
      if(response.data) {
        this.giftcardDetails = response.data;
        if(this.giftcardDetails?.attributes?.discount?.value) {
          this.isDiscountedGc = true;
        }
      }
      this.isLoadingGiftCardDetails = false;
    }, error => {
      this.isLoadingGiftCardDetails = false;
    })
  }

  validTill() {
    return formatDate(this.giftcardDetails?.attributes?.end_date, "DD MMM, YYYY");
  }

  increment(i) {
    this.resetErrorMsg();
    if (this.valueNum[i]) {
      if (this.giftcardDetails.attributes.max_quantity_per_order) {
        if (this.valueNum[i] < this.giftcardDetails.attributes.max_quantity_per_order) {
          this.valueNum[i] = this.valueNum[i] + 1;
        } else {
          this.slabError = "No of quantity cannot be more than " + this.giftcardDetails.attributes.max_quantity_per_order + ".";
        }
      } else {
        this.valueNum[i] = this.valueNum[i] + 1;
      }
    }
    else {
      this.valueNum[i] = 1;
    }
    this.calcTotalQuantity(this.valueNum);
  }

  decrement(i) {
    this.resetErrorMsg();
    if (this.valueNum[i] && this.valueNum[i] > 0) {
      this.valueNum[i] = this.valueNum[i] - 1;
      if (this.valueNum[i] == 0) {
        this.valueNum[i] = null;
      }
      this.calcTotalQuantity(this.valueNum);
    }
  }

  incrementRange(i) {
    this.resetErrorMsg();
    if (this.giftRange[i].denomination < parseInt(this.giftcardDetails.attributes.min_price)) {
      this.rangeError = {
        type: 'amount',
        message: 'Minimum Gift Card value is ',
        amount: this.giftcardDetails.attributes.min_price
      }
    } else if (this.giftRange[i].denomination > parseInt(this.giftcardDetails.attributes.max_price)) {
      this.rangeError = {
        type: 'amount',
        message: 'Maximum Gift Card value is ',
        amount: this.giftcardDetails.attributes.max_price
      }
    } else {
      if (!this.checkDenominationExists(i)) {
        if (this.giftRange[i].quantity < this.giftcardDetails.attributes.max_quantity_per_order) {
          this.giftRange[i].quantity = this.giftRange[i].quantity + 1;
        } else {
          this.rangeError = {
            type: 'text',
            message: 'No of quantity cannot be more than 4 per denomination',
          }
        }
      }
    }
    this.calcTotalRangeQuantity();
  }

  calcTotalQuantity(valueNum) {
    this.totalQuantity = 0;
    this.totalValue = 0;
    this.totalDiscount = 0;
    valueNum.forEach((obj, key) => {
      obj = obj ? obj : 0;
      this.totalQuantity = this.totalQuantity + parseInt(obj);
      this.totalValue = this.totalValue + (parseInt(this.giftcardDetails.attributes?.denominations[key]) * obj);
      if(this.isDiscountedGc) {
        this.totalDiscount = this.totalDiscount + (((parseInt(this.giftcardDetails.attributes.denominations[key]) * obj) * this.giftcardDetails.attributes.discount.value)/100 );
        this.totalDiscount = Math.round(this.totalDiscount * 100) / 100;
      }
    });
    this.totalAmount = (this.totalValue - this.totalDiscount) > 0 ? this.totalValue - this.totalDiscount : 0;
  }

  checkDenominationExists(index) {
    for (var i = 0; i < this.giftRange.length; i++) {
      if (this.giftRange[i].denomination === this.giftRange[index].denomination && index != i) {
        this.rangeError = {
          type: 'text',
          message: 'Denomination already added. Increment quantity in the same denomination type.',
        }
        return true;
      }
    }
    return false;
  }

  calcTotalRangeQuantity() {
    this.totalQuantity = 0;
    this.totalValue = 0;
    this.totalDiscount = 0;
    this.giftRange.forEach((obj, key) => {
      this.totalQuantity = this.totalQuantity + obj.quantity;
      this.totalValue = this.totalValue + (obj.quantity * obj.denomination);
      if(this.isDiscountedGc) {
        this.totalDiscount = this.totalDiscount + (((obj.quantity * obj.denomination) * this.giftcardDetails.attributes.discount.value)/100)
        this.totalDiscount = Math.round(this.totalDiscount * 100) / 100;
      }
    });
    this.totalAmount = (this.totalValue - this.totalDiscount) > 0 ? this.totalValue - this.totalDiscount : 0;
  }

  deleteGiftDenomination(i) {
    this.giftRange.splice(i, 1);
    this.calcTotalRangeQuantity();
  }

  createGiftDenomination() {
    this.rangeError = '';
    this.giftRange.forEach((obj, key) => {
      if (obj.quantity == 0 || obj.denomination == null) {
        this.addDenominationError = "Please enter valid denomination";
      }
    });
    if (this.addDenominationError == '') {
      this.giftRange.push({
        quantity: 0,
        denomination: null
      });
    }
  }

  isNumberKey(evt){
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
  }

  currencyInputChanged(value) {
    value = value.split(/ /)[0].replace(/[^\d]/g, '')
    let num = value.replace(/[$,]/g, "");
    num = num.replace(/\D+/g, "");
    return Number(num);
  }

  formatNumberToCurrency(number) {
    return Intl.NumberFormat('en-IN').format(number);
  }
  
  decrementRange(i) {
    this.resetErrorMsg();
    this.giftRange[i].quantity = this.giftRange[i].quantity > 0 ? this.giftRange[i].quantity - 1 : this.giftRange[i].quantity;
    if (this.giftRange.length > 1 && (this.giftRange[i].denomination === 0 || this.giftRange[i].quantity == 0)) {
      this.deleteGiftDenomination(i);
    }
    this.calcTotalRangeQuantity();
  }

  checkDenomination(rangeItem, index) {
    if(Number(rangeItem.denomination) >= parseInt(this.giftcardDetails.attributes.min_price) && rangeItem.quantity <= 0) {
      this.incrementRange(index);
    } else if (Number(rangeItem.denomination) < parseInt(this.giftcardDetails.attributes.min_price)) {
      this.resetQuantity(index);
    } else {
      this.calcTotalRangeQuantity();
    }
  }

  resetQuantity(index) {
    this.resetErrorMsg();
    this.giftRange[index].quantity = 0;
    if (this.giftRange.length > 1 && this.giftRange[index].denomination === 0) {
      this.deleteGiftDenomination(index);
    }
    this.calcTotalRangeQuantity();
  }

  resetErrorMsg() {
    this.rangeError = '';
    this.addDenominationError = '';
    this.slabError = '';
  }

  ondismissCallback() {
    this.buyingGiftcard = false;
    this.paymentModalClosed = true;
    this.changeDetector.detectChanges();
  }

  makePayment() {
    this.giftCardService.createPayment(this.paymentPayload).then((response: any) => {
      if(response) {
        response["modal"] = {
          ondismiss: this.ondismissCallback,
          animation: true
        };
        response["theme"] = {
          color: "#2A86F3",
          backdrop_color: "#111218e6"
        }
        let rzp1 = new Razorpay(response);
        rzp1.open();
      } else {
        this.triggerDefaultErrorMessage();
        // this.errorMessage = "";
        this.buyingGiftcard = false;
      }
    }, error => {
      //show error screen
      this.triggerDefaultErrorMessage();
      // this.errorMessage = "";
      this.buyingGiftcard = false;
    })
  }

  reInitiatePayment() {
    this.buyingGiftcard = true;
    this.errorMessage = "";
    this.giftCardService.changePaymentStatus(this.paymentPayload.booking_id).then((response: any) => {
      if(response.status == "success") {
        this.makePayment();
      } else {
        this.triggerDefaultErrorMessage();
        this.buyingGiftcard = false;
      }
    }, error => {
      this.triggerDefaultErrorMessage();
      this.buyingGiftcard = false;
    })
  }

  handleProceed() {
    if(this.paymentModalClosed) {
      this.reInitiatePayment();
    } else if(!this.buyingGiftcard) {
      let items = [];
      if(this.giftcardDetails.attributes?.price_type == "slab") {
        this.giftcardDetails.attributes.denominations.map((denomination: number, index: number) => {
          if(this.valueNum[index])
          items.push({
            denomination: denomination,
            gift_card_id: this.giftcardDetails.id,
            name: this.giftcardDetails.attributes.title,
            quantity: this.valueNum[index],
            recipient_type: "self"
          })
        })
      } else if(this.giftcardDetails.attributes?.price_type == "range") {
        this.giftRange.map((range: any) => {
          if(range.denomination && range.quantity) {
            items.push({
              denomination: range.denomination,
              gift_card_id: this.giftcardDetails.id,
              name: this.giftcardDetails.attributes.title,
              quantity: range.quantity,
              recipient_type: "self"
            })
          }
        })
      }
      let payload = {
        category: "GiftCard",
        items: items
      }
      this.buyingGiftcard = true;
      this.giftCardService.clearCartItems().then((response: any) => {
        if(response.status == "success") {
          this.giftCardService.addToCart(payload).toPromise().then((response: any) => {
            if(response.status == "success") {
              this.giftCardService.cartCheckout().toPromise().then((response: any) => {
                if(response.status == "success") {
                  let userDetails = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails")) : {} 
                  let paymentPayload = {
                    amount: this.totalAmount,
                    booking_id: response.booking_id,
                    email: userDetails.email,
                    first_name: userDetails.full_name,
                    phone: userDetails.mobile,
                    product_info: "giftcards",
                    transactions_attributes: [
                      {
                        amount: this.totalAmount,
                        txn_type: "payment_gateway"
                      }
                    ],
                  }
                  this.paymentPayload = paymentPayload;
                  this.makePayment();
                } else {
                  this.triggerDefaultErrorMessage();
                  // this.errorMessage = "";
                  this.buyingGiftcard = false;
                }
              }, error => {
                this.triggerDefaultErrorMessage();
                // this.errorMessage = "";
                this.buyingGiftcard = false;
              })
            } else {
              this.triggerDefaultErrorMessage();
              // this.errorMessage = "";
              this.buyingGiftcard = false;
            }
            //error message unable to add to cart // what can we show to user ? 
          }, error => {
            this.triggerDefaultErrorMessage();
            // this.errorMessage = "";
            this.buyingGiftcard = false;
          })
        } else {
          this.triggerDefaultErrorMessage();
          // this.errorMessage = "";
          this.buyingGiftcard = false;
        }
        //unable to clear cart message 
      }, error => {
        this.triggerDefaultErrorMessage();
        // this.errorMessage = "";
        this.buyingGiftcard = false;
      })
    }
  }

  triggerDefaultErrorMessage() {
    this.errorMessage = "Something went wrong, please try again later.";
  }

  onClickOfTab(event) {
    let element = document.getElementById(event?.id+"-link");
    if(element) {
      element.scrollIntoView({behavior: "smooth", inline: "end"});
    }
  }

}
