import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryBasedRewardsComponent } from './category-based-rewards/category-based-rewards.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CouponModalComponent } from './coupon-modal/coupon-modal.component';
import { CouponModalComponent as FoundersCardCouponModalComponent } from '../shared/foundersCard/coupon-modal/coupon-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { SelectOfferComponent } from './select-offer/select-offer.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { DeviceService } from 'src/app/common/services/device.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FooterComponent } from './footer/footer.component';
import { TokenExpiredComponent } from './token-expired/token-expired.component';
import { ContactModalComponent } from 'src/app/pages/memberships/contact-modal/contact-modal.component';
import { OfferClaimedCardComponent } from './offer-claimed-card/offer-claimed-card.component';
import { MembershipClaimedCardComponent } from './membership-claimed-card/membership-claimed-card.component';
import { IndianCurrencyPipe } from 'src/app/common/pipes/indian-currency/indian-currency.pipe';
import { ServiceFormComponent } from './airport-transfer/service-form/service-form.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { PlusMinusComponent } from './airport-transfer/plus-minus/plus-minus.component';
import { CarTypeCardComponent } from './airport-transfer/car-type-card/car-type-card.component';
import { SearchAvailabilityComponent } from './airport-transfer/search-availability/search-availability.component';
import { ServiceConfirmationComponent } from './airport-transfer/service-confirmation/service-confirmation.component';
import { TransportServiceComponent } from './airport-transfer/transport-service/transport-service.component';
import { FilterPipe } from 'src/app/common/pipes/filter/filter.pipe';
import { BookingSummaryComponent } from './airport-transfer/booking-summary/booking-summary.component';
import { AirportTransferBookingComponent } from './airport-transfer/airport-transfer-booking/airport-transfer-booking.component';
import { BackConfirmationModalComponent } from './airport-transfer/back-confirmation-modal/back-confirmation-modal.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { GiftCardModalComponent } from './giftcards/gift-card-modal/gift-card-modal.component';
import { GiftCardComponent } from './giftcards/gift-card/gift-card.component'
import { NumbersOnlyDirective } from 'src/app/common/directives/numbers-only.directive';
import { BenefitsMenuComponent } from './benefits-menu/benefits-menu.component';
import { GiftcardClaimedCardComponent } from './giftcard-claimed-card/giftcard-claimed-card.component';
import { CategoryComponent } from './category/category.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { OrderLoaderComponent } from './order-loader/order-loader.component';
import { PurchasedProductCardComponent } from './purchased-product-card/purchased-product-card.component';
import { ClickAnalyticsDirective } from '../../directives/click-analytics.directive';


@NgModule({
  declarations: [
    CategoryBasedRewardsComponent,
    HeaderComponent,
    CouponModalComponent,
    SelectOfferComponent,
    AuthenticationComponent,
    MobileNavComponent,
    FooterComponent,
    TokenExpiredComponent,
    FoundersCardCouponModalComponent,
    ContactModalComponent,
    OfferClaimedCardComponent,
    MembershipClaimedCardComponent,
    IndianCurrencyPipe,
    SearchAvailabilityComponent,
    ServiceFormComponent,
    TransportServiceComponent,
    ServiceConfirmationComponent,
    PlusMinusComponent,
    CarTypeCardComponent,
    FilterPipe,
    BookingSummaryComponent,
    AirportTransferBookingComponent,
    BackConfirmationModalComponent,
    GiftCardModalComponent,
    GiftCardComponent,
    NumbersOnlyDirective,
    ClickAnalyticsDirective,
    BenefitsMenuComponent,
    GiftcardClaimedCardComponent,
    CategoryComponent,
    ProductCardComponent,
    OrderLoaderComponent,
    PurchasedProductCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ModalModule.forChild(),
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      preventDuplicates: true,
    }),
    FormsModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    TabsModule,
    BsDatepickerModule.forRoot(),
    MatTooltipModule,
  ],
  exports: [
    CategoryBasedRewardsComponent,
    HeaderComponent,
    FooterComponent,
    ModalModule,
    BsDropdownModule,
    AuthenticationComponent,
    FormsModule,
    ToastrModule,
    ReactiveFormsModule,
    MobileNavComponent,
    OfferClaimedCardComponent,
    MembershipClaimedCardComponent,
    IndianCurrencyPipe,
    NgxSkeletonLoaderModule,
    SearchAvailabilityComponent,
    ServiceFormComponent,
    TransportServiceComponent,
    ServiceConfirmationComponent,
    BsDatepickerModule,
    FilterPipe,
    BookingSummaryComponent,
    AirportTransferBookingComponent,
    MatTooltipModule,
    GiftCardModalComponent,
    GiftCardComponent,
    GiftcardClaimedCardComponent,
    NumbersOnlyDirective,
    GiftcardClaimedCardComponent,
    CategoryComponent,
    ProductCardComponent,
    OrderLoaderComponent,
    PurchasedProductCardComponent,
    ClickAnalyticsDirective,
  ],
  providers: [DeviceService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
