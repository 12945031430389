import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { DeviceService } from 'src/app/common/services/device.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isMobile: boolean = false;

  constructor(private deviceService: DeviceService, public globalConstants: GlobalConstants) {
    this.deviceService.deviceObserver.subscribe((isMobile) => {
      this.isMobile = isMobile;
    })
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile;
  }

}
