import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from './common/constants/global-variables';
import { AuthenticationService } from './common/services/authentication.service';
import { DeviceService } from './common/services/device.service';
import { TokenExpiredComponent } from './shared/components/shared/token-expired/token-expired.component';
import { ApiService } from './shared/services/commonapi/api.service';
import gtmBusiness from '../../gtm-business.js'
import { TelemetryOrchestrationService } from './shared/services/telemetry/telemetry-orchestration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  
  countries: Array<any> =[];
  selectedCountry: any = null;
  fullHeight: boolean = false;
  hideFor = ["offer-details"]
  fullHeightFor = ["offer-details"]
  hideNavMenu: boolean = false;
  isMobile: boolean = false;
  hideHeader: boolean = false;
  noHightSet: boolean = false;
  isTokenExpiredModalOpen: boolean = false;
  pageConfigs = [
      {
        path: "/rewards/offer-details",
        hideNavMenu: false,
        hideHeader: false,
        fullHeight: false,
      },
      {
        path: "/memberships/membership-details",
        hideNavMenu: false,
        hideHeader: false,
        fullHeight: false,
      },
      {
        path: "/",
        hideNavMenu: false,
        hideHeader: false,
        fullHeight: false,
      },
      {
        path: "/rewards",
        hideNavMenu: false,
        hideHeader: false,
        fullHeight: false,
        noHightSet: true
      },
      {
        path: "/claimed-rewards",
        hideNavMenu: false,
        hideHeader: true,
        fullHeight: false,
      },
      {
        path: "/profile",
        hideNavMenu: false,
        hideHeader: true,
        fullHeight: false,
      }
  ]

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.deviceService.onResize();
  }

  loadGoogleAnalytics(trackingID: string): void {
    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    if(!this.globalConstants.isFounderCard) {
      gaScript.setAttribute('src', gtmBusiness);
      document.documentElement.firstChild.appendChild(gaScript);

      
      let noScript = document.createElement('noscript');
      let iframeTag = document.createElement('iframe');
      iframeTag.setAttribute('src', "https://www.googletagmanager.com/ns.html?id=GTM-KG3BM62");
      iframeTag.setAttribute("height", "0");
      iframeTag.setAttribute("width", "0");
      iframeTag.setAttribute("style", "display:none;visibility:hidden");
      document.body.appendChild(noScript);

    } else {
      gaScript.setAttribute('src', `https://www.googletagmanager.com/gtm.js?id=${ trackingID }`);
      let gaScript2 = document.createElement('script');
      gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;

      document.documentElement.firstChild.appendChild(gaScript);
      document.documentElement.firstChild.appendChild(gaScript2);
    }
  }

  constructor(
    public globalConstants: GlobalConstants, 
    private router: Router,
    private apiService: ApiService,
    private deviceService: DeviceService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private telemetryOrchestrationService: TelemetryOrchestrationService
  ) {
    this.telemetryOrchestrationService.initialize();
    this.loadGoogleAnalytics(this.globalConstants.isFounderCard ? environment.foundersCardTrackingId : environment.businessRewardsTrackingId);
    this.isMobile = this.deviceService.isMobile;
    this.deviceService.deviceObserver.subscribe((response) => {
      this.isMobile = response;
      this.setPageConfig();
    })
    this.setPageConfig();
    this.initializeSelectedCountry();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let currentPath = event.url.split('/').slice(0,3).join('/');
        currentPath.includes("confirm-email") ? '' : this.handleTokenExpiry();
      }
    });
  }

  handleTokenExpiry() {
    this.authenticationService.tokenExpired.subscribe((isExpired) => {
      if(isExpired && !this.isTokenExpiredModalOpen) {
        this.isTokenExpiredModalOpen = true;
        this.dialog.open(TokenExpiredComponent, {panelClass: this.globalConstants?.isFounderCard ? ["custom-modal", "token-expired", "founders-modal-bg"] : ["custom-modal", "token-expired"], disableClose: true});
      }
    })
  }

  setPageConfig() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(this.isMobile) {
          for(let i  = 0; i < this.pageConfigs.length; i++) {
            let config = this.pageConfigs[i];
            let currentPath = event.url.split('/').slice(0,3).join('/');
            if(currentPath == "/" && (currentPath == config.path)) {
              this.fullHeight = config.fullHeight;
              this.hideNavMenu = config.hideNavMenu;
              this.hideHeader = config.hideHeader;
              this.noHightSet = config.noHightSet;
              break;
            } else if(currentPath.includes(config.path) && config.path != "/" && config.path.includes(currentPath)) {
              this.fullHeight = config.fullHeight;
              this.hideNavMenu = config.hideNavMenu;
              this.hideHeader = config.hideHeader;
              break;
            }
          }
        } else {
          this.fullHeight = true;
          this.hideNavMenu = true;
        }
      }
    })
  }

  ngAfterViewInit() {
    document.getElementById("scrollTotop").scroll(0,0)
  }

  initializeSelectedCountry() {
    if (localStorage.getItem("countries")) {
      this.countries = JSON.parse(localStorage.getItem("countries"));
      if (localStorage.getItem("selectedCountry")) {
        this.selectedCountry = JSON.parse(
          localStorage.getItem("selectedCountry")
        );
      } else {
        this.getDefaultCountry(this.countries);
      }
    } else {
      this.getAllCountries();
    }
  }

  getDefaultCountry(countries) {
    let filteredCountry = countries.filter((x) => {
      return x.attributes.name == "India";
    });
    if (filteredCountry.length > 0) {
      let selectedCountry = filteredCountry[0];
      let mobile_length = selectedCountry.attributes.mobile_length.split("...");
      selectedCountry.attributes.min_mobile_digit = parseInt(mobile_length[0]);
      selectedCountry.attributes.max_mobile_digit = parseInt(mobile_length[1]) - 1;
      localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry));
      this.selectedCountry = selectedCountry;
    }
  }

  getAllCountries() {
    if (
      !localStorage.getItem("selectedCountry") ||
      (localStorage.getItem("selectedCountry") &&
        Object.keys(JSON.parse(localStorage.getItem("selectedCountry")))
          .length == 0)
    ) {
      this.apiService.getAllCountries().subscribe((res: any) => {
        if (res.data && Array.isArray(res.data) && res.data.length > 0) {
          this.countries = res.data;
          this.getDefaultCountry(res.data);
          localStorage.setItem("countries", JSON.stringify(res.data));
        }
      });
    }
  }

}
