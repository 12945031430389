import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceService } from 'src/app/common/services/device.service';
import { IProductCart } from 'src/app/interfaces/zrpl';
import { ZrplService } from 'src/app/shared/services/zrpl/zrpl.service';
import { AddressModalComponent } from '../address-modal/address-modal.component';
import { FormBuilder, Validators } from '@angular/forms';
import _underscore from "underscore";
import { RazorpayPgService } from 'razorpay-pg';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  public productsInCart: Array<IProductCart> = this.zrplService.productCart;
  public deliveryAddress: string = '';
  private emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  AddressForm: any = this.formBuilder.group({
    first_name: ["", Validators.required],
    last_name: ["", Validators.required],
    email: ["", [Validators.email, Validators.required,Validators.pattern(this.emailPattern)]],
    mobile: ["", [Validators.required, Validators.pattern(/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/)]],
    pincode: ["", [Validators.required, Validators.pattern(/^[1-9][0-9]{5}$/)]],
    country: ["", Validators.required],
    country_id: ["", Validators.required],
    city: ["", Validators.required],
    region: ["", Validators.required],
    region_code: ["", Validators.required],
    addressLine1: ["", Validators.required],
    addressLine2: ["", Validators.required],
  });
  public total: number = 0;
  paymentClicked: boolean = false;

  constructor(
    private zrplService: ZrplService,
    public deviceService: DeviceService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private pgService: RazorpayPgService,
    private changeDetector: ChangeDetectorRef,
    private toastr: ToastrService
  ) {
    this.pgService.paymentModalClosedEvent.subscribe((isClosed: any) => {
      this.paymentClicked = !isClosed;
      this.changeDetector.detectChanges();
    });
    this.pgService.paymentClickedEvent.subscribe((isLoading: boolean) => {
      this.paymentClicked = isLoading;
    })
  }

  ngOnInit(): void {
    this.updateTotalCartValue();
  }

  ngOnDestroy() {
    this.zrplService.productCart = [];
  }

  public handleAddAdress(event) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.dialog.open(AddressModalComponent, {
      panelClass: ['address-modal'],
      data: this.AddressForm
    }).afterClosed().subscribe((address: any) => {
      this.deliveryAddress = address;
    })
  }

  private updateTotalCartValue() {
    let tempTotal: number = 0;
    this.productsInCart.map((product: IProductCart) => {
      tempTotal = tempTotal + product.quantity * (product.specialPrice || product.price);
    });
    this.total = tempTotal;
  }

  public updateQuantity(inc_dec: boolean, product: IProductCart) {
    if(this.paymentClicked) return;
    if(inc_dec) {
      this.zrplService.buyProduct(product);
    } else {
      this.zrplService.decrement(product);
    }
    this.updateTotalCartValue();
  }

  private setBillingDetails() {
    return {
      address_type: "billing_address",
      city: this.AddressForm?.value?.city,
      country_id: this.AddressForm?.value?.country_id,
      email: this.AddressForm?.value?.email,
      firstname: this.AddressForm?.value?.first_name,
      lastname: this.AddressForm?.value?.last_name,
      postcode: this.AddressForm?.value?.pincode,
      region: this.AddressForm?.value?.region,
      region_code: this.AddressForm?.value?.region_code,
      telephone: this.AddressForm?.value?.mobile,
      street: [this.AddressForm?.value?.addressLine1, this.AddressForm?.value?.addressLine2]
    }
  }

  private setUserPack() {
    let userPack = {
      name: "",
      email: "",
      mobile: "",
    }
    let userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    if(!_underscore.isEmpty(userDetails)) {
      userPack.name = userDetails?.full_name;
      userPack.email = userDetails?.email;
      userPack.mobile = userDetails?.mobile;
    }
    return userPack;
  }

  private setBookingDetails(): Array<{qty: number, discount: number, sku: string}> {
    return this.productsInCart.map((product: IProductCart) => {
      return {
        qty: product?.quantity,
        discount: 0,
        sku: product?.sku,
      }
    })
  }

  private initatePayment(bookingId: string) {
    let userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    let paymentPayload = {
      amount: this.total,
      booking_id: bookingId,
      email: userDetails.email,
      first_name: userDetails.full_name,
      phone: userDetails.mobile,
      product_info: "",
      transactions_attributes: [
        {
          amount: this.total,
          txn_type: "payment_gateway"
        }
      ],
    }
    try {
      this.pgService.makePayment(paymentPayload);
    } catch (error) {
      if(error && (typeof(error) == 'string')) {
        this.toastr.error(error);
      }
      this.paymentClicked = false;
    }
  }

  public initiatePayment() {
    this.paymentClicked = true;
    let payload = {
      billing_address: this.setBillingDetails(),
      user_pack: this.setUserPack(),
      fare_breakup: {
        grand_total: this.total //Need to update key for discounted price
      },
      booking_details: this.setBookingDetails(),
    }
    this.zrplService.initiatePurchase(payload).subscribe({
      next: (response: any) => {
        if(response?.data?.status == "booking_created" && response?.data?.booking_id) {
          this.initatePayment(response?.data?.booking_id);
        } else {
          throw new Error("Failed to initate booking");
        }
      },
      error: () => {
        this.paymentClicked = false;
        this.toastr.error("Something went wrong, please try again later.")
      }
    })
  }

}
