import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router, UrlSerializer } from '@angular/router';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { ICategory, IProduct, IProductBookingPayload, IProductCart, IProductDetails } from 'src/app/interfaces/zrpl';
import { environment } from 'src/environments/environment';
import _underscore from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class ZrplService {

  private zrplStore: any = {};
  public categories: Array<any> = [];
  public productCart: Array<IProductCart> = JSON.parse(localStorage.getItem("productCart") || "[]");
  public selectedCategory: EventEmitter<any> = new EventEmitter<any>();
  public defaultImage: string;
  private categoryIconMappings = [
    {
      names: ["laptop"],
      image: 'assets/icons/monitor.webp',
    },
    {
      names: ["mobile"],
      image: 'assets/icons/smartphone.webp',
    },
    {
      names: ["headphones", "ear", "audio"],
      image: 'assets/icons/headphones.webp',
    },
    {
      names: ["television"],
      image: 'assets/icons/tv.webp',
    },
    {
      names: ["camer"],
      image: 'assets/icons/camera.webp',
    },
    {
      names: ["gaming", "game"],
      image: 'assets/icons/game.webp',
    },
    {
      names: ["speaker"],
      image: 'assets/icons/speaker.webp',
    },
    {
      names: ["fan"],
      image: 'assets/icons/fan.webp',
    },
    {
      names: ["cable"],
      image: 'assets/icons/dataCabel.webp',
    },
    {
      names: ["watch"],
      image: 'assets/icons/watch.webp',
    },
    {
      names: ["powerbank"],
      image: 'assets/icons/powerbank.webp',
    },
    {
      names: ["drone"],
      image: 'assets/icons/drone.webp',
    },
    {
      names: ["air purifier"],
      image: 'assets/icons/airpurifier.webp',
    },
    {
      names: ["ipad", "tablet"],
      image: 'assets/icons/ipad.webp',
    },
    {
      names: ["charger"],
      image: 'assets/icons/wirelesscharger.webp',
    },
    {
      names: ["adapter"],
      image: 'assets/icons/adapter.webp',
    },
  ];
  private featuredCategoriesNames: Array<string> = [
    "laptop",
    "mobile",
    "headphone",
    "television",
    "camera",
    "gaming",
    "game",
    "speaker",
    "fan",
    "data cable",
    "smartwatch",
    "powerbank",
    "drone",
    "air purifier",
    "wireless charger",
    "mobile adapter",
    "ipad"
  ];
  public featuredCategories: Array<ICategory> = [];
  public isLoadingZrplOrders: boolean = false;
  public zrplPurchases: Array<any> = [];
  public loadingZrplOrders: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthenticationService,
    private globalConstants: GlobalConstants
  ) {
    this.defaultImage = 'assets/images/productPlaceholder.png'
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let currentPath = event.url.split('/').slice(0,3).join('/');
        if(!currentPath.includes('checkout')) {
          this.productCart = [];
          localStorage.removeItem('productCart');
        }
      }
    });
    this.loadingZrplOrders.subscribe((loading: boolean) => {
      this.isLoadingZrplOrders = loading;
    });
    if(this.authService.isUserAuthenticated() && !this.globalConstants.isFounderCard) {
      this.getProductPurchaseHistory();
    }
  }

  private getProductPurchaseHistory() {
    this.loadingZrplOrders.emit(true);
    this.fetchProductPurchaseHistory().subscribe({
      next: (response: any) => {
        this.zrplPurchases = response?.data?.map((order) => this.flatternPurchaseOrders(order));
        this.loadingZrplOrders.emit(false);
      },
      error: () => {
        this.loadingZrplOrders.emit(false);
        console.log("error");
      }
    });
  }

  private flatternPurchaseOrders(order: any) {
    return {
      status: order?.attributes?.status,
      purchase_date: order?.attributes?.created_at,
      items: order?.attributes?.items?.map((item) => {
        return {
          item_id: item?.item_id,
          name: item?.name,
          order_id: item?.order_id,
          qty: item?.qty_ordered,
          price: item?.price
        }
      })
    }
  }

  public updateProductsStore(key: string, products: Array<any>, page: number, totalProducts: number) {
    this.zrplStore[key] = {
      products: products,
      page: page,
      totalProducts: totalProducts
    };
  }

  public getPageNumberByCategory(key: string) {
    return (this.zrplStore[key] && this.zrplStore[key].products) ? this.zrplStore[key].page : 0;
  }

  public getTotalProductsByCategory(key: string) {
    return (this.zrplStore[key] && this.zrplStore[key].products) ? this.zrplStore[key].totalProducts : 0;
  }

  public getProductsBy(key: string) {
    if(this.zrplStore[key] && this.zrplStore[key].products) {
      return JSON.parse(JSON.stringify(this.zrplStore[key].products));
    } else {
      return [];
    }
  }

  public getCategories() {
		return this.http.get(environment.ecommerceBooking + "/categories").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

  public getPromoCodes() {
    return this.http.get(environment.apiUrlBsV1 + '/promo_codes').pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }

  public getCategoryFilters(categoryId: string) {
    return this.http.get(environment.ecommerceBooking + "/category_filters", {params: {category_id: categoryId}}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
  }

  public getProductsByCategory(categoryId: string, perPage: number = null, page: number = null, filters = null) {
    let params = {};
    params = {
      'filters[category_id]': categoryId,
      ...filters
    };

    if(perPage && !_underscore.isNull(page)) {
      params['page'] = page;
      params['per_page'] = perPage;
    }
    return this.http.get(environment.ecommerceBooking + '/products', {params: params});
  }

  public getProductDetails(sku: string) {
    return this.http.get(environment.ecommerceBooking + '/product_details/' + sku).pipe(map(res => {
      return res;
    }),
    catchError((err: HttpErrorResponse) => {
      return throwError(err);
    }))
  }

  public searchProducts(name: string) {
    return this.http.get(environment.ecommerceBooking + '/products', {params: {name: name}}).pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }

  private getMainImage(productDetailsResponse): string {
    const path: string = "https://dgolwewdmdr5p.cloudfront.net/catalog/product";
    const imagesFromCustomAttr = productDetailsResponse?.attributes?.custom_attributes?.filter((a) => a.attribute_code == 'image');
    if(productDetailsResponse?.attributes?.media_gallery_entries?.length > 0) {
      return path + productDetailsResponse?.attributes?.media_gallery_entries[0].file;
    } else if(imagesFromCustomAttr?.length > 0) {
      return path + imagesFromCustomAttr[0].value;
    } else {
      return this.defaultImage;
    }
  }

  private getCustomAttrValueByKey(productDetailsResponse, key: string = ''): string {
    const attrValue = productDetailsResponse?.attributes?.custom_attributes?.filter((a) => a.attribute_code == key);
    return attrValue.length > 0 ? attrValue[0].value : '';
  }

  private getSpecialPrice(productDetailsResponse): number {
    let special_price = productDetailsResponse?.attributes?.custom_attributes?.filter((a) => a.attribute_code == 'special_price');
    let surcharge_fee = productDetailsResponse?.attributes?.custom_attributes?.filter((a) => a.attribute_code == 'productsurcharge_fee');
    return (special_price.length > 0 ? Number.parseFloat(special_price[0].value) : 0) + (surcharge_fee.length > 0 ? Number.parseFloat(surcharge_fee[0].value) : 0);
  }

  private getPrice(productDetailsResponse): number {
    let price = productDetailsResponse?.attributes?.price;
    let surcharge_fee = productDetailsResponse?.attributes?.custom_attributes?.filter((a) => a.attribute_code == 'productsurcharge_fee');
    return Number.parseFloat(price) + (surcharge_fee.length > 0 ? Number.parseFloat(surcharge_fee[0].value) : 0);
  }

  public setProductDetails(productDetailsResponse) {
    return {
      sku: productDetailsResponse?.attributes?.sku,
      id: productDetailsResponse?.id,
      name: productDetailsResponse?.attributes?.name,
      status: productDetailsResponse?.attributes?.status,
      image: this.getMainImage(productDetailsResponse),
      meta_title: this.getCustomAttrValueByKey(productDetailsResponse, 'meta_title'),
      description: this.getCustomAttrValueByKey(productDetailsResponse, 'description'),
      shortDescription: this.getCustomAttrValueByKey(productDetailsResponse, 'short_description'),
      price: this.getPrice(productDetailsResponse),
      specialPrice: this.getSpecialPrice(productDetailsResponse),
    }
  }

  private updateProductCartStorage () {
    localStorage.setItem("productCart", JSON.stringify(this.productCart));
  }

  public buyProduct(product: IProductDetails | IProductCart) {
    let existingProduct = _underscore.findWhere(this.productCart, {id: product.id});
    if(existingProduct) {
      this.productCart = this.productCart.map((existingProd: IProductCart) => {
        if(existingProd.id !== product.id) return existingProd;
         existingProd.quantity = ++existingProd.quantity;
         return existingProd;
      });
      this.updateProductCartStorage();
    } else {
      this.productCart.push({...product, ...{quantity: 1}});
      this.updateProductCartStorage();
      this.router.navigate(['/electronics/checkout']);
    }
  }

  public decrement(product: IProductCart) {
    this.productCart = this.productCart.map((existingProd: IProductCart) => {
      if(existingProd.id !== product.id) return existingProd;
       existingProd.quantity = existingProd.quantity == 0 ? existingProd.quantity : --existingProd.quantity;
       return existingProd;
    });
    this.updateProductCartStorage();
  }

  public getCountryByName(name: string) {
		return this.http.get(environment.corePathV2 + "/countries", {params: {name__match: name}}).pipe(
			map(res => res || []),
			catchError(error => throwError(() => new Error(error)))
		);
	}

  public getDetailsByPin(code:any){
    return this.http.get(environment.apiUrlBsV1 +"/country_details/get_details_by_pin?pin_code=" + code);
  }

  public fetchProductPurchaseHistory() {
    return this.http.get(environment.ecommerceBooking + "/order_history"); //mock_req needs to be removed when we start getting order placed responses from zrpl.
  }

  public getCategoryImageByName(category_name: string) : string {
    let categoryObj = _underscore.find(this.categoryIconMappings, function(categoryObj) {
      return _underscore.find(categoryObj.names, function(name) {return category_name.toLocaleLowerCase().includes(name)})
    });
    return categoryObj?.image || 'defaultimage'
  }

  public sortByFeaturedCategories(categories: Array<ICategory>) {
    this.featuredCategoriesNames.map((featuredCat) => {
      categories = categories.map((category) => {
        if(category?.attributes?.name?.toLocaleLowerCase().includes(featuredCat?.toLocaleLowerCase())) {
          this.featuredCategories.push(category);
        } else {
          return category;
        }
      })
    });
    this.featuredCategories = [...this.featuredCategories, ...categories.filter(c => c)];
    return this.featuredCategories;
  }

  public initiatePurchase(payload: IProductBookingPayload) {
    return this.http.post(environment.ecommerceBooking, payload).pipe(
			map(res => {
				return res;
			}),
			catchError((err: HttpErrorResponse) => {
				return throwError(err);
			})
		);
  }

  public getPurchaseDetails(bookingId: string) {
    return this.http.get(environment.ecommerceBooking+`/order_details/${bookingId}`);
  }

  public getOrderDetails(orderId: string) {
    return this.http.get(environment.ecommerceBooking+`/order_details/${orderId}?is_order_id=${true}`);
  }

}
