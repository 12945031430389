import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ICountryDetails } from 'src/app/interfaces/clientService';
import { ZrplService } from 'src/app/shared/services/zrpl/zrpl.service';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent implements OnInit {

  public clickedSubmit: boolean = false;
  private subject: Subject<string> = new Subject();
  public loadingLocationDetails: boolean = false;
  public dataNotFound: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddressModalComponent>,
    @Inject(MAT_DIALOG_DATA) public AddressForm: any,
    private zrplService: ZrplService,
  ) {
    this.subject.pipe(
      debounceTime(800)
    ).subscribe(inp => {
      this.handlePinCodeInput(inp);
    });
  }

  public debouncePincodeSearch($event) {
    if(!$event?.target.value) return;
    this.subject.next($event?.target.value);
  }

  ngOnInit(): void {}

  private getAddress() {
    let address: string = this.AddressForm?.valid ? this.AddressForm.value.addressLine1+" "+this.AddressForm.value.addressLine1+", "+this.AddressForm.value.city+", "+this.AddressForm.value.pincode : '';
    return address;
  } 

  public closeModal() {
    if(!this.AddressForm.valid) {this.AddressForm.reset();}
    this.dialogRef.close(this.getAddress());
  }

  public handleSubmit() {
    this.clickedSubmit = true;
    if(this.AddressForm.valid) {
      this.dialogRef.close(this.getAddress());
    }
  }

  public handleCountryInput(countryName) {
    if(!countryName) return;
    this.zrplService.getCountryByName(countryName).subscribe({
      next: (response: any) => {
        let countryDetails: ICountryDetails = (response?.data && Array.isArray(response?.data) && response?.data?.length > 0) ? response.data[0] : null;
        if (!countryDetails) {
          this.dataNotFound = true;
          this.resetlocationDetails(); return;
        } else {
          this.AddressForm.controls.country_id.setValue(countryDetails?.attributes?.id);
          this.AddressForm.controls.region.setValue(countryDetails?.attributes?.region);
          this.AddressForm.controls.region_code.setValue(countryDetails?.attributes?.code);
          this.dataNotFound = false;
        }  
        this.loadingLocationDetails = false;
      }, error: () => {
        this.dataNotFound = true;
        this.loadingLocationDetails = false;
      }
    })
  }

  private resetlocationDetails() {
    this.loadingLocationDetails = false;
    this.AddressForm.controls.country.reset();
    this.AddressForm.controls.country_id.reset();
    this.AddressForm.controls.region.reset();
    this.AddressForm.controls.city.reset();
    this.AddressForm.controls.region_code.reset();
  }

  public handlePinCodeInput(pincode) {
    this.loadingLocationDetails = true;
    this.dataNotFound = false;
    this.zrplService.getDetailsByPin(pincode).subscribe({
      next: (response: any) => {
        if(response?.msg) { this.dataNotFound = true; this.resetlocationDetails(); return;} 
        this.AddressForm.controls.country.setValue(response?.country);
        this.AddressForm.controls.city.setValue(response?.district_name);
        this.handleCountryInput(response?.country);
      }, error: () => {
        this.dataNotFound = true;
        this.resetlocationDetails();
      }
    });
  }

}
