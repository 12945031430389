<div class="card-bg" [ngClass]="{'listing-page': isListingPage}" [routerLink]="['/electronics/details/'+productDetails?.sku]" *ngIf="!isLoading">
    <div class="d-flex flex-column card-container">
        <img [src]="productDetails?.image" [ngClass]="{'listing-page': isListingPage}" class="product-img" alt=""/>

        <div class="details d-flex flex-column">
            <div class="title truncate-2-lines fnt-13">
                {{productDetails?.name}}
            </div>

            <div class="d-flex mt-auto align-items-center">
                <div class="fnt-16 me-2" *ngIf="productDetails?.specialPrice">
                    ₹ {{productDetails?.specialPrice | indianCurrency}}
                </div>
                <div [ngClass]="{'c8F93A4 fnt-11': productDetails?.specialPrice, 'fnt-16': !productDetails?.specialPrice}" *ngIf="productDetails?.price">
                    <s *ngIf="productDetails?.specialPrice">₹ {{productDetails?.price | indianCurrency}}</s>
                    <ng-container *ngIf="!productDetails?.specialPrice">₹ {{productDetails?.price | indianCurrency}}</ng-container>
                </div>
            </div>

            <div class="d-flex mt-auto">
                <button class="btn btn-primary custom-button fnt-14" (click)="buyProduct($event)">
                    Buy Now
                </button>
                <div class="text-decoration-underline know-more m-auto pointer">
                    Know More
                </div>
            </div>

        </div>
    </div>
</div>

<ngx-skeleton-loader 
    *ngIf="isLoading"
    [count]="noOfCards" 
    [ngClass]="['d-flex', loaderClasses , 'w-100']"
    appearance="line"
    [theme]="{'height': '329px','width': '23%', background: globalConstants?.data?.colors?.detailsPageBg, 'border-radius': '8px'}">
</ngx-skeleton-loader>