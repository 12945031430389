// TODO - Move this file to a shared library
import { Directive, ElementRef, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { TelemetryService } from '../services/telemetry/telemetry.service';

@Directive({
  selector: '[appClickAnalytics]'
})
export class ClickAnalyticsDirective {
  @Input('analyticsLabel')
  public label?: string;

  private activeSubscriptions: Subscription = new Subscription();

  public constructor(
    private route: ActivatedRoute, 
    private readonly host: ElementRef,
    private readonly telemetryService: TelemetryService
  ) { }

  public ngOnInit(): void {
    this.setupListeners();
  }

  public ngOnDestroy(): void {
    this.clearListeners();
  }


  private setupListeners(): void {
    this.clearListeners();
    this.activeSubscriptions = new Subscription();

    // listen to any click event happening on the element with this directive, gather more information from query params and call the telemetry service with this info
    this.activeSubscriptions.add(
      fromEvent<MouseEvent>(this.host.nativeElement, 'click').subscribe((eventObj) => {
        const label = (this.label ?? (eventObj.target as HTMLElement).textContent ?? '').trim();
        this.telemetryService.trackClick({ label, ...this.route.snapshot.queryParams })
      })
    );
  }

  private clearListeners(): void {
    this.activeSubscriptions.unsubscribe();
  }

}
