import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { BenefitsMenuComponent } from '../benefits-menu/benefits-menu.component';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent implements OnInit {

  currentUrl: string = "";
  @Input() hideNavMenu: boolean;
  isInBenefitsPage: boolean = false;
  dialogRef: any;

  constructor(
    public router: Router, 
    private route: ActivatedRoute, 
    public globalConstants: GlobalConstants,
    private dialog: MatDialog
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    })
  }

  ngOnInit(): void {
  }

  openBenefitsModal() {
    if(!this.dialogRef) {
      this.dialogRef = this.dialog.open(BenefitsMenuComponent, {
        panelClass: ['animate__animated', 'animate__fadeInUp', 'benefits-menu-modal']
      }).afterClosed().subscribe(() => {
        this.dialogRef = null;
      })
    }
  }

}
