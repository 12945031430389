<div class="custom-padding pad-xs-0 d-flex flex-column checkout-container min-height-100 pt-4"
    [ngClass]="{'h-100': deviceService.isMobile, 'h-auto h-xs-auto': !deviceService.isMobile}">
    <h3 class="cfff f700 fnt-24 mb-5">Booking Summary</h3>
    <div class="row m-0 d-flex">
        <div class="col col-xs-12 d-flex flex-column ps-0">
            <div class="d-flex delivery-container p-3 align-items-center" *ngIf="deliveryAddress">
                <span class="icon icon-group cfff me-2"></span>
                <div class="d-flex flex-wrap fnt-14 cfff">
                    <span class="f600 me-2">Deliver to: </span><span class="f400">{{deliveryAddress}}</span>
                </div>
                <button class="btn custom-button primary outline ms-auto" [ngClass]="{'disabled': paymentClicked}" (click)="handleAddAdress($event)">
                    Change
                </button>
            </div>
            <button class="align-items-center d-flex btn btn-primary custom-button fnt-14 py-2 px-3 mar-bot-10"
                *ngIf="!deliveryAddress" (click)="handleAddAdress($event)">
                <img src="../../../../assets/icons/add.svg" class="add-icon" alt="add">
                <div class="cfff fnt-14 f700 text-capitalize ms-2">
                    Enter delivery address
                </div>
            </button>
            <ng-container *ngIf="productsInCart.length > 0">
                <div *ngFor="let product of productsInCart" class="product-card p-3 d-flex mar-top-10">
                    <img class="product-image rounded border border-light" [src]="product?.image" alt="product_image" />
                    <div class="d-flex flex-column mx-2 justify-content-center">
                        <div class="truncate-2-lines fnt-14 f600 cfff">
                            {{product?.name}}
                        </div>
                        <div class="d-flex mt-2 align-items-center fnt-20">
                            <div class="me-2 cfff" *ngIf="product?.specialPrice">
                                ₹ {{product?.specialPrice || 0 | indianCurrency}}
                            </div>
                            <div [ngClass]="{'c8F93A4': product?.specialPrice, 'cfff': !product?.specialPrice}"
                                *ngIf="product?.price">
                                <s *ngIf="product?.specialPrice">₹ {{product?.price || 0 | indianCurrency}}</s>
                                <ng-container *ngIf="!product?.specialPrice">₹ {{product?.price || 0 |
                                    indianCurrency}}</ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="inc-dec-btn d-flex align-items-center ms-auto overflow-visible">
                        <div class="change-quantity-btn rounded-circle" [ngClass]="{'disabled': paymentClicked}" (click)="updateQuantity(false, product)">
                            -
                        </div>
                        <div
                            class="quantity-placeholder fnt-13 f400 d-flex align-items-center justify-content-center mx-2">
                            {{product?.quantity}}
                        </div>
                        <div class="change-quantity-btn rounded-circle" [ngClass]="{'disabled': paymentClicked}" (click)="updateQuantity(true, product)">
                            +
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col col-xs-12 pe-0">
            <div class="order-details d-flex flex-column p-3">
                <div class="fnt-14 f500 cfff mb-3">
                    Order Details
                </div>
                <div class="d-flex">
                    <div class="order-label">
                        Base Fare
                    </div>
                    <div class="order-value ms-auto">
                        ₹ {{total || 0 | indianCurrency}}
                    </div>
                </div>
                <hr class="border-light" />
                <div class="d-flex cFDFDFD fnt-14 f700">
                    <div class="">
                        Total
                    </div>
                    <div class="ms-auto">
                        ₹ {{total || 0 | indianCurrency}}
                    </div>
                </div>
            </div>
            <button class="btn btn-primary custom-button fnt-16 f700 w-100 text-center mt-3 d-flex text-center align-items-center justify-content-center" [ngClass]="{'disabled': !deliveryAddress || !total || paymentClicked}" (click)="initiatePayment()">
                Proceed to Pay
                <span *ngIf="paymentClicked" class="small-loader ms-2"></span>
            </button>
        </div>
    </div>
</div>