<div class="d-flex flex-column" [ngClass]="{'p-4': data.showCloseButton}">
    <div class="d-flex justify-content-end" *ngIf="data.showCloseButton">
        <img src="../../../../assets/icons/close.svg" class="close-icon mt-auto mb-auto pointer" (click)="closeModal()" />
    </div>
    <div class="section">
        <h1>Data & Privacy</h1>
        <p>Razorpay and its partner Viverati Interactive Marketing Pvt ltd. (“PoshVine” or “we”) respect your privacy. This Privacy Policy describes how we process personal data, the types of personal data we collect, for what purposes we use that personal data, with whom we share it, and the choices you can make about our use of the personal data. We also describe the measures we take to  protect the security of the personal data and how you can contact us about our privacy practices.</p>
        <p>PoshVine is the entity responsible for the processing of your personal data in the context of RazorpayX Business Rewards Program (“Program”). Your visit to RazorpayX Business Rewards Portal (the “Site”) and participation in the Program is subject to this Privacy Policy and to our Terms of Use.</p>
        <p>This Privacy Policy applies to the personal data collected via the Program and Site only. It does not cover the collection and use of your personal data on any Razorpay branded websites or anyother information or communications that may reference to Razorpay outside the program. For more information about Raazorpay’s privacy practices outside the scope of the Program, please visit our Privacy Policy at https://razorpay.com/privacy/.</p>
        <p>Children and minors (under the age of 18) are not eligible to register in the Program.</p>

            <h4>1. Personal Data We Collect and How We Use it</h4>
                <p>We collect personal data in the context of the Program. By personal data we mean “any information relating to an identified or identifiable natural person.” The personal data we process is either provided directly by you or collected automatically.</p>

                <h5>1.1. Personal Data You Provide to Us Directly</h5>
                <p>The personal data you provide to us directly are processed and used only for the purposes described in this Privacy Policy. The personal data that you provide in the context of the Program are never obligatory, unless marked otherwise for the purpose of providing you with our services. If you choose not to give some data, this may affect the way you navigate on the Site or the services that we can offer you. Please find below information on how you may provide this data, the types of data you may submit, and how we may use the data.</p>
                <p><u>Sign In / Authentication:</u> You may wish to submit a request to claim an offer from a participating merchant by signing in to the Site. Signing in requires you to submit your mobile number and email address. Based on this information entered by you, we determine if you are eligible for the offer. We also send you’re the voucher or promo code as applicable for the offer, once you have claimed the offer on the Site, through email and/or SMS. Where requested by you, we will be collecting and subsequently transferring your personal data such as your email address and mobile number to the merchant which you have requested an offer from, and such merchant will facilitate fulfilment of your request.</p>
                <p><u>Customer Service:</u> If you contact us through our customer helpline number or our email address, you may be required to provide personal information (such as name, email address, contact number, the first eight digits of your payment card and the offer which you are interested in) to allow us to contact you to resolve your enquiries and to allow us to assist you with enquiries and provide customer service. Your call to us may also be recorded for monitoring, quality and training purposes.</p>
            
                <h5>1.2 Personal Data We Collect Automatically</h5>
                <p>We may collect certain information by automated means (e.g., cookies, log-file, web beacons) when you participate in the Program. The data collected automatically are processed in order to improve the Program, perform data analyses to assess the use of the Program and any interaction with other sites (e.g. merchant sites), and to provide you with the best information and services. The information we collect in this way tells us, for example, how many users visited our website and the pages accessed, whether you have visited us before or if you are a new visitor, and helps us to identify features in which you may have the greatest interest. This information also allows us to make improvements and to enhance your experience.</p>
                <p><u>Log files and IP addresses:</u> Log files are web server files (containing information such as the domain name or IP address, URL, the http response code, the website from where you visit us or the date and duration of your visit) that are automatically created when an Internet user visits a site. An IP address is a unique identifier that certain electronic devices use to identify and communicate with each other on the Internet. When you visit the Site, we may collect the IP address of the device you use to connect to the Internet. For example, when you request a page from the Site, our servers log your IP address. In combination with other data, this information helps us to learn what pages are most attractive to our visitors, which of our products most interest our customers, and what types of offers our customers like to see. In addition, log files help us to detect disruptions to telecommunication equipment and to detect abuse of our services.</p>
                <p>Cookies, web beacons and tracking links: To improve your experience and the Program, we may also use cookies, web beacons and tracking links in the context of the Program.</p>

            <h4>2. Your Rights and Choices</h4>
                <p>You have the right to receive information about the data stored about you and to access your personal data by using the contact information provided in the “How to Contact Us” section below. You may also request at any time that your personal data be rectified, blocked or deleted if it is incorrect, inaccurate or outdated. Following any data request for access, rectification or deletion, we will accommodate your enquiry as required by applicable law. At any time, you may object on reasonable grounds relating to your particular situation to the processing of your personal data by Razorpay, except when otherwise provided by the applicable law.</p>

            <h4>3. Information We Share</h4>

                <p>We do not disclose personal data collected in the context of Program, except as described here. We may share your personal data provided with service providers we have retained to perform services on our behalf and in relation to the purposes described in this Privacy Policy. Examples include merchants you have requested an offer with, and email or SMS service provider. When we use third party service providers, we take all necessary steps to ensure a high level of data protection. We require them to only process the data in accordance with our instructions and to ensure the security of the data. These service providers are not authorized by us to use or disclose the information, except as necessary to perform services on our behalf or to comply with legal requirements.</p>
                <p>In addition, we may disclose personal data about you (i) if we are required to do so by law or pursuant to legal process, (ii) in response to a request from a court, law enforcement authorities or government officials, or (iii) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation of suspected or actual criminal activity.</p>
                <p>We reserve the right to transfer any data we have about you in the event we sell or transfer all or a portion of our business or assets. Should such a sale or transfer occur, we will endeavor to use reasonable efforts to seek that the transferee uses the personal data you have provided through the Program in a manner consistent with this Privacy Policy.</p>

            <h4>4. Data Transfer</h4>

                <p>The transfer of personal data outside of your country may be subject to restrictions. If we transfer personal data outside of your country, we take all the necessary steps to ensure compliance with applicable data protection law, in particular, the legal requirements on adequate protection for data transfers to countries outside of your country.</p>
                <p>In the context of this program, PoshVine may transfer and process your personal data in a database outside of the country you are residing in.</p>

            <h4>5. Links to Other Websites</h4>

                <p>On the Site, we provide links to other websites for your convenience. In particular, the Program will contain links to merchants whose offers you may be interested in. These sites operate independently from the Program and are not under our control. These sites may have their own privacy policies in place, which we strongly suggest you review when you visit them. We are not responsible for the content of these sites, any products or services that may be offered through these sites, the data processing activities that are conducted via those sites, or any other use of these sites.</p>

            <h4>6. Data Security</h4>

                <p>We maintain appropriate administrative, technical and physical safeguards to protect personal data against accidental or unlawful destruction, accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse, and any other unlawful form of processing of the personal data in our possession.</p>
                <p>When you provide personal data online, we use the industry standard for encryption on the Internet – Secure Socket Layer (SSL) technology – to help protect the information that you provide. This Internet encryption standard scrambles data as it travels from your device to our server. You will know that you are in a secure area of the Site when a lock icon appears on your screen that the "http" prefix of our URL address changes to "https." The "s" represents "secure".</p>
                <p>Please note however that no collection or data transmission can be guaranteed to be 100% secure. Therefore, while we strive to protect your personal data, we cannot guarantee or warrant the security of any data transmission.</p>
                <p>We will never ask you for your one-time password (OTP) in any unsolicited communication (including unsolicited correspondence, such as letters, phone calls or e-mail messages). If you believe your email address or mobile number and OTP have been compromised, please contact us by following the instructions provided in the section "How to Contact Us" below.</p>

            <h4>7. Update to this Privacy Policy</h4>

                <p>This Privacy Policy may be updated periodically and without prior notice to you to reflect changes in our personal information practices. We will post a prominent notice on our websites to notify you of any significant changes to our Privacy Policy and indicate at the top of the policy when it was most recently updated.</p>

            <h4>8. How to Contact Us</h4>

                <p>If you would like to (i) obtain more information on how we process your personal data or to access your personal data; (ii) rectify, block or delete any personal data relating to you; (iii) withdraw your consent given for certain types of data processing; (iv) remove your e-mail address from our mailing lists; or (v) if you have any questions, complaints, comments or concerns about our privacy practices, please contact us by email at support@poshvine.com.</p>
                <p>We will look into your enquiry, request or complaint, and respond to you in accordance with local law requirements. For all other enquiries about your Razorpay card and your purchase, you must contact your Razorpay or the participating merchant. More information about how to contact them can be found on their respective websites.</p>



    </div>

</div>