// TODO - Move this file to a shared library
/**
 * 
 * @param value Any type of value which is to be evaluated if it is empty or not
 * @returns a boolean indicating if the value passed is empty or not
 * 
 * For object, empty object or `null` evaluates to `true`
 * 
 * For arrays, empty array will evaluate to `true`
 * 
 * For strings, empty string will evaluate to `true`
 * 
 * For number, `0` will evaluate to `true`
 * 
 * More edge cases can be added for scenarious required
 */
export const isEmpty = (value: any): boolean => {
  const type = typeof value;

  switch (type) {
    case 'undefined':
      return true;
    case 'object':
      if (Array.isArray(value)) {
        return value.length === 0 ? true : false;
      } else if (value === null) {
        return true;
      } else {
        // TODO | more edge cases to be covered here, will add later, this is sufficient as of now
        return Object.keys(value).length === 0 ? true : false;
      }
    case 'boolean':
      return value;
    case 'number':
      return value === 0;
    case 'string':
      return value.length === 0;
    case 'bigint':
      return value == 0;
    default:
      return false;
  }

}