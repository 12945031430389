import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from 'src/app/common/utils/util';
import { IOrderDetails } from 'src/app/interfaces/zrpl';
import { ZrplService } from 'src/app/shared/services/zrpl/zrpl.service';

@Component({
  selector: 'app-purchase-details',
  templateUrl: './purchase-details.component.html',
  styleUrls: ['./purchase-details.component.scss']
})
export class PurchaseDetailsComponent implements OnInit {

  public orderInfo: IOrderDetails;
  public isOrderDetailsLoading: boolean = false;

  constructor(
    private zrplService: ZrplService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.getOrderDetails();
  }

  ngOnInit(): void {
  }

  private generateProductDetails(response: any) {
    return response?.data?.attributes?.items?.map((item) => {
      return {
        img: this.zrplService.defaultImage,
        title: item?.name,
        quantity: item?.qty_ordered,
      }
    })
  }

  private generateAddress(billing_address: any) {
    let street = billing_address?.table?.street.join(', ');
    return `${street}, ${billing_address?.table?.city}, ${billing_address?.table?.region}, ${billing_address?.table?.postcode}`
  }

  private getOrderDetails() {
    this.isOrderDetailsLoading = true;
    this.zrplService.getOrderDetails(this.route.snapshot.params.orderId).subscribe({
      next: (response: any) => {
        if(Object.keys(response?.data)) {
          this.orderInfo = {
            product_details: response?.data?.attributes?.items?.length ? this.generateProductDetails(response) : [],
            order_details: {
              purchase_date: response?.data?.attributes?.created_at,
              order_id: response?.data?.id,
            },
            payment_details: {
              total_price: response?.data?.attributes?.grand_total,
              total_item_count: response?.data?.attributes?.total_item_count,
            },
            delivery_details: {
              name: response?.data?.attributes?.billing_address?.table?.firstname + response?.data?.attributes?.billing_address?.table?.lastname,
              address: this.generateAddress(response?.data?.attributes?.billing_address),
              mobile: response?.data?.attributes?.billing_address?.table?.telephone,
            }
          };
        }
        this.isOrderDetailsLoading = false;
      },
      error: (error) => {
        this.isOrderDetailsLoading = false;
        this.toastr.warning("Something went wrong, please try again later.");
      }
    })
  }

  public back() {
    history.back();
  }

  public formatDate(date) {
    return formatDate(date, "MMM, DD YYYY");
  }

}
