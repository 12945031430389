<div class="d-flex flex-column custom-padding pb-5">

    <div class="d-flex custom-padding align-items-center py-4">
        <div class="d-inline-flex align-items-center" (click)="back()">
            <span class="icon-left-arrow mt-auto mb-auto pad-top-6 me-2 blue-text fnt-20 pointer"></span>
            <div class="fnt-14 f700 blue-text pointer">Back</div>
        </div>
    </div>

    <div class="dark_background rounded p-4 card-width mx-auto d-flex flex-column">
        <ng-container *ngIf="!isOrderDetailsLoading">
            <div class="d-flex product-details rounded p-3" *ngFor="let orderItem of orderInfo?.product_details">
                <img [src]="orderItem?.img" alt="" class="product-image rounded" />
                <div class="d-flex flex-column ms-3">
                    <div class="truncate-2-lines cfff fnt-16 f700">
                        {{orderItem?.title}}
                    </div>
                    <span class="mt-2 white-variant-light fnt-14 f500">
                        Quantity - <span>{{orderItem?.quantity}}</span>
                    </span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isOrderDetailsLoading">
            <app-order-loader [count]="1"></app-order-loader>
        </ng-container>
    </div>

    <div class="dark_background rounded p-4 card-width mx-auto order-details mt-3">
        <div class="fnt-20 cfff f700 mb-3">
            Order Details
        </div>
        <ng-container *ngIf="!isOrderDetailsLoading">
            <div class="d-flex cBBBDC8 fnt-14 f700">
                <div>Purchase Date</div>
                <div class="ms-auto">
                    {{formatDate(orderInfo?.order_details?.purchase_date)}}
                </div>
            </div>
            <hr class="border-bottom my-2">
            <div class="d-flex cBBBDC8 fnt-14 f700">
                <div>Order Number</div>
                <div class="ms-auto">
                    {{orderInfo?.order_details?.order_id}}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isOrderDetailsLoading">
            <app-order-loader [count]="1"></app-order-loader>
        </ng-container>
    </div>

    <div class="dark_background rounded p-4 card-width mx-auto mt-3">
        <div class="fnt-20 cfff f700 mb-3">
            Payment Details
        </div>
        <ng-container *ngIf="!isOrderDetailsLoading">
            <div class="d-flex cBBBDC8 fnt-14 f700">
                <div>Items</div>
                <div class="ms-auto">
                    {{orderInfo?.payment_details?.total_item_count}}
                </div>
            </div>
            <hr class="border-bottom my-2">
            <div class="d-flex cfff fnt-16 f700">
                <div>Total</div>
                <div class="ms-auto">
                    ₹ {{orderInfo?.payment_details?.total_price | indianCurrency}}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isOrderDetailsLoading">
            <app-order-loader [count]="1"></app-order-loader>
        </ng-container>
    </div>

    <div class="dark_background rounded p-4 card-width mx-auto mt-3">
        <div class="fnt-20 cfff f700 mb-3">
            Delivery Details
        </div>
        <ng-container *ngIf="!isOrderDetailsLoading">
            <div class="cBBBDC8 fnt-16 d-flex flex-column">
                <span class="mb-1 text-capitalize">{{orderInfo?.delivery_details?.name}}</span>
                <span class="address text-wrap">
                    {{orderInfo?.delivery_details?.address}}
                </span>
                <span class="cFDFDFD mt-1">
                    Mobile: {{orderInfo?.delivery_details?.mobile}}
                </span>
            </div>
        </ng-container>
        <ng-container *ngIf="isOrderDetailsLoading">
            <app-order-loader [count]="1"></app-order-loader>
        </ng-container>

    </div>
</div>