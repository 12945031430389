import { EventEmitter, Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { GiftcardService } from 'src/app/common/services/giftcard.service';
import { ApiService } from '../commonapi/api.service';
import { ZrplService } from '../zrpl/zrpl.service';
import { formatDate, isBefore } from 'src/app/common/utils/util';

@Injectable({
  providedIn: 'root'
})
export class RedemptionService {

  isLoading: EventEmitter<boolean> = new EventEmitter();
  isLoadingValue: boolean = true;
  userCards: any;
  memberships: Array<any> = [];
  redemptions: any = [];
  offers = [];
  giftcards: Array<any> = [];
  loadingGiftCards: EventEmitter<boolean> = new EventEmitter();
  loadingGiftcardsValue: boolean = true;

  constructor(
    private _apiService: ApiService, 
    private authService: AuthenticationService, 
    public globalConstants: GlobalConstants, 
    private giftcardService: GiftcardService,
    private zrplService: ZrplService
  ) {
    this.authService.isUserLoggedIn.subscribe(() => {
      this.checkIfUserAuthenticated();
    });
    this.checkIfUserAuthenticated();
    this.isLoading.subscribe((loading: boolean) => {
      this.isLoadingValue = loading;
    })
    this.loadingGiftCards.subscribe((loading: boolean) => {
      this.loadingGiftcardsValue = loading;
    });
  }

  private checkIfUserAuthenticated() {
    let currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : null;
    if(currentUser && !currentUser?.isTokenExpired) {
      if(localStorage.getItem("userDetails") && Object.keys(JSON.parse(localStorage.getItem("userDetails"))).length > 0) {
        let userDetails = JSON.parse(localStorage.getItem("userDetails"));
        if(userDetails.mobile && userDetails.status == "activated") {
          this.getPerksData();
        }
      }
    }
  }

  getPerksData() {
    this.isLoading.emit(true);
    if(!this.globalConstants.isFounderCard) {
      this.getGiftcardRedemptions()
    }
    forkJoin([this._apiService.getRedemptions(!this.globalConstants.isFounderCard ? {offer: true} : null), this._apiService.getCards()]).subscribe((response: any) => {
      this.offers = [];
      this.memberships = [];
      this.redemptions = response[0].data;
      this.userCards = response[1].data;
      this.redemptions.forEach(obj => {
        var cardData = this.getCard(obj.attributes.user_card_id);
        if(obj.attributes.membership && Object.keys(obj.attributes.membership)?.length > 0 && this.globalConstants.isFounderCard) {
          let dataItem = {
            'id': obj.attributes.membership.id,
            'created_at': obj.attributes.created_at,
            'status': obj.attributes.status,
            'type': 'membership',
            'merchant_name': obj.attributes.merchant.name,
            'membership_name': obj.attributes.offer.title,
            'membership_img': obj.attributes.merchant.logo,
            'voucher': obj.attributes.membership_voucher.code ? obj.attributes.membership_voucher.code : null,
            'website': obj.attributes.membership.redemption_link && obj.attributes.membership.redemption_link != null && obj.attributes.membership.redemption_link != '' ? obj.attributes.membership.redemption_link : obj.attributes.merchant.website,
            'validity': obj.attributes?.membership?.end_date ? formatDate(obj.attributes.membership.end_date, "MMM DD YYYY") : 'Unavailable',
            'cardName': cardData ? cardData.attributes.display_name : '',
            'cardNum': cardData ? cardData.attributes.bin : '',
            'cardStatus': cardData ? cardData.attributes.status : '',
            'merchant_logo': obj.attributes.merchant.logo,
            'merchant_id': obj.attributes.merchant.id,
            'is_featured': obj.attributes.bundle.context == 'featured',
            'isExpired': isBefore(obj.attributes.membership.end_date, new Date(), "DD/MM/YYYY h:mm a")
          }
          this.memberships.push(dataItem);
        } else if(obj.attributes.offer && Object.keys(obj.attributes.offer)?.length > 0) {
          let dataItem = {
            'id': obj.attributes.offer.id,
            'created_at': obj.attributes.created_at,
            'status': obj.attributes.status,
            'type': 'offer',
            'merchant_name': obj.attributes.merchant.name,
            'offer_name': obj.attributes.offer.title,
            'offer_img': obj.attributes.merchant.logo,
            'voucher': obj.attributes.voucher.code ? obj.attributes.voucher.code : null,
            'website': obj.attributes.offer.redemption_link && obj.attributes.offer.redemption_link != null && obj.attributes.offer.redemption_link != '' ? obj.attributes.offer.redemption_link : obj.attributes.merchant.website,
            'validity': formatDate(obj.attributes.offer.end_date, "MMM DD YYYY"),
            'cardName': cardData ? cardData.attributes.display_name : '',
            'cardNum': cardData ? cardData.attributes.bin : '',
            'cardStatus': cardData ? cardData.attributes.status : '',
            'merchant_logo': obj.attributes.merchant.logo,
            'merchant_id': obj.attributes.merchant.id,
            'is_featured': obj.attributes.bundle.context == 'featured',
            'isExpired': isBefore(obj?.attributes?.offer?.end_date, new Date(), "DD/MM/YYYY h:mm a")
          }
          this.offers.push(dataItem);
        }
        
        });
      this.offers.sort(function (a, b) {
        return Date.parse((b.created_at)) - Date.parse((a.created_at));
      });
      this.memberships.sort(function (a, b) {
        return Date.parse((b.created_at)) - Date.parse((a.created_at));
      });
      this.isLoading.emit(false);
    },(err) => { this.isLoading.emit(false); });
  }

  getGiftcardRedemptions() {
    this.loadingGiftCards.emit(true);
    this.giftcardService.getBookings().subscribe((response: any) => {
      if(response.data && Array.isArray(response.data) && response.data.length > 0) {
        response.data.map((obj: any) => {
          let merchant_giftcard_details = {
            image_url: obj.attributes?.details?.attributes?.gift_card_orders[0]?.attributes?.gift_card_data.image_url,
            merchant_url: obj.attributes?.details?.attributes?.gift_card_orders[0]?.attributes?.gift_card_data.merchant_url,
            redemption_url: obj.attributes?.details?.attributes?.gift_card_orders[0]?.attributes?.gift_card_data.redemption_url,
            giftcard_title: obj.attributes?.details?.attributes?.gift_card_orders[0]?.attributes?.gift_card_data.title,
            merchant_name: obj.attributes?.details?.attributes?.merchant_and_offer_details.merchant,
          };
          let vouchers = [];
          if(obj.attributes?.details?.attributes?.gift_card_orders && Array.isArray(obj.attributes?.details?.attributes?.gift_card_orders) && obj.attributes?.details?.attributes?.gift_card_orders.length > 0) {
            obj.attributes?.details?.attributes?.gift_card_orders.map((order: any) => {
              if(order?.attributes?.vouchers && Array.isArray(order?.attributes?.vouchers) && order?.attributes?.vouchers.length > 0) {
                let tempVouchers = [];
                order?.attributes?.vouchers.map((voucher: any) => {
                  tempVouchers.push({...voucher, ...{quantity: order.attributes.quantity}})
                })
                vouchers = [...vouchers, ...tempVouchers];
              }
            })
          }
          let dataItem = {
            'id': obj.id,
            'total_amount': obj.attributes?.amount,
            'booking_id': obj.attributes?.unique_id,
            'created_at': obj.attributes?.details?.attributes?.created_at,
            'status': obj.attributes?.details?.attributes?.status,
            'type': 'gift_card',
            'merchant_name': merchant_giftcard_details?.merchant_name,
            'offer_name': merchant_giftcard_details?.giftcard_title,
            'offer_img': merchant_giftcard_details.image_url,
            'vouchers': vouchers,
            'website': merchant_giftcard_details?.redemption_url || merchant_giftcard_details?.merchant_url,
            'validity': vouchers.length > 0 ? formatDate(vouchers[0].validity, "MMM DD YYYY") : null,
            'merchant_logo': merchant_giftcard_details.image_url,
            'isExpired': vouchers.length > 0 ? isBefore(vouchers[0].validity, new Date(), "DD/MM/YYYY h:mm a") : false,
            'discountedAmount': obj.attributes?.details?.attributes?.fare_breakup?.discounted_price ? (obj.attributes?.details?.attributes?.fare_breakup?.price - obj.attributes?.details?.attributes?.fare_breakup?.discounted_price) : null
          }
          this.giftcards.push(dataItem);
        });
        this.giftcards.sort(function (a, b) {
          return Date.parse((b.created_at)) - Date.parse((a.created_at));
        });
        this.loadingGiftCards.emit(false);
      } else {
        this.loadingGiftCards.emit(false);
      }
    }, error => {
      this.loadingGiftCards.emit(false);
    })
  }

  getCard(id) {
    if (this.userCards && this.userCards.length > 0) {
      var filteredCard = this.userCards.filter(x => x.id == id);
      return filteredCard[0];
    }
    else {
      this._apiService.getCards().subscribe(
        (res: any) => {
          this.userCards = res.data;
          var filteredCard = this.userCards.filter(x => x.id == id);
          return filteredCard[0];
        },
        (err) => {
        }
      );
    }
  }
}
